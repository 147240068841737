import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Specialty } from '@app/core/models/input/specialty.model';
import { EnvironmentService } from '@app/environment.service';


@Injectable({
  providedIn: 'root'
})
export class SpecialtiesService {

  constructor( private readonly http: HttpClient,
              private readonly env: EnvironmentService,) { }


  getSpecialties() {
    return this.http.get<Specialty[]>(`${this.env.apiUrl}/consents/specialties`);
  }
}
