import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DESC } from '@app/core/constants/Constants';
import { COMPANY_ROLES, DOCTOR_ROLES, GENERATE_CIS } from '@app/core/constants/Permissions';
import ObjectTypeEnum from '@app/core/enums/objectType.enum';
import OutputTypeEnum from '@app/core/enums/output-type.enum';
import StatusEnum from '@app/core/enums/status.enum';
import { Filter } from '@app/core/models/front/filters';
import { Pagination } from '@app/core/models/front/pagination';
import { Sorters } from '@app/core/models/front/sorters';
import { Status } from '@app/core/models/input/common/status.model';
import { Consent } from '@app/core/models/input/consent/consent.model';
import { Page } from '@app/core/models/input/page/pages.model';
import { User } from '@app/core/models/input/user.model';
import { ConsentService } from '@app/core/services/consent/consent.service';
import { FileService } from '@app/core/services/file/file.service';
import { LoginService } from '@app/core/services/login/login.service';
import { RoleService } from '@app/core/services/role/role.service';
import { StatusService } from '@app/core/services/status/status.service';
import DownloadUtils from '@app/core/utils/download.utils';
import { FilterUtils } from '@app/core/utils/filter.utils';
import {
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faCircleExclamation,
  faCircleXmark,
  faFileArrowDown,
  faFileCircleCheck,
  faFileSignature,
  faFolderOpen,
  faMagnifyingGlassChart,
  faPrint,
  faRotate,
  faShareFromSquare,
  faTabletScreenButton,
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-documents-doctor',
  templateUrl: './documents-doctor.component.html',
  styleUrls: ['./documents-doctor.component.scss'],
})
export class DocumentsDoctorComponent implements OnInit {
  // FontAwesome
  faFileSignature = faFileSignature;
  faShareFromSquare = faShareFromSquare;
  faMagnifyingGlassChart = faMagnifyingGlassChart;
  faCaretUp = faCaretUp;
  faCaretDown = faCaretDown;
  faCaretLeft = faCaretLeft;
  faCaretRight = faCaretRight;
  faFileArrowDown = faFileArrowDown;
  faTabletScreenButton = faTabletScreenButton;
  faPrint = faPrint;
  faCircleExclamation = faCircleExclamation;
  faCircleXmark = faCircleXmark;
  faFileCircleCheck = faFileCircleCheck;
  faFolderOpen = faFolderOpen;
  faRotate = faRotate;

  // Utils
  filterUtils: FilterUtils;

  // Enums
  OutputType = OutputTypeEnum;
  ObjectTypeEnum = ObjectTypeEnum;
  Status = StatusEnum;

  // Properties
  consents?: Consent[];
  statuses?: Status[];
  page = new Page();
  pagination = new Pagination();
  filters = new Array<Filter>();
  sorters = new Sorters('creationDate', DESC);
  selectedStatus = 0;

  user = new User;
  canDownloadFile = false;
  isDoctor = false;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly consentService: ConsentService,
    private readonly statusService: StatusService,
    private readonly fileService: FileService,
    private readonly loginService: LoginService,
    private readonly roleService: RoleService
  ) {
    this.filterUtils = new FilterUtils(this.filters);
  }

  ngOnInit() {
    this.addFilter(
      'doctorUuid',
      this.route.snapshot.paramMap.get('uuid') as string
    );
    this.getConsents();
    this.getStatuses();
    this.user = this.loginService.userValue ?? new User();

    if (this.roleService.hasRole(COMPANY_ROLES)
     || this.roleService.hasRole(GENERATE_CIS) ){
      this.canDownloadFile = true;

    }
    if(this.roleService.hasRole(DOCTOR_ROLES)){
      this.isDoctor = true
    }
  }

  refreshData(): void {
    this.getConsents();
  }

  addStatusFilter(statusId: string) {
    // 0 means all statuses
    if (statusId != '0') {
      this.addFilter('statusId', statusId);
    } else {
      this.removeFilter('statusId');
    }
  }

  addFilter(name: string, filter: string) {
    // If we filter the requested page will be 0
    this.pagination.page = 0;
    this.filterUtils.addFilter(name, filter);
    this.getConsents();
  }

  removeFilter(name: string) {
    // If we filter the requested page will be 0
    this.pagination.page = 0;
    this.filterUtils.removeFilter(name);
    this.getConsents();
  }

  paginar(amount: number) {
    this.pagination.page += amount;
    this.getConsents();
  }

  getStatuses() {
    this.statusService.getStatuses().subscribe({
      next: statuses => {
        this.statuses = statuses;
      },
    });
  }

  goToConsentDetail(consent: Consent) {
    this.consentService.setConsent(consent);
    this.router.navigate(['/portal/consents/' + consent.uuid], {
      relativeTo: this.route,
    });
  }

  getConsents() {
    this.consentService
      .getConsents(this.pagination, this.filters, this.sorters)
      .subscribe({
        next: consentsPage => {
          this.page = consentsPage;
          this.consents = this.page.content;
        },
      });
  }

  downloadFile(uuid: string): void {
    this.fileService.getConsentPdf(uuid).subscribe({
      next: dataDocument => {
        if (dataDocument.body?.name) {
          const newName = dataDocument.body?.name.replace(/\.pdf$/, '');
          DownloadUtils.download(
            dataDocument.body?.content ?? '',
            newName,
            dataDocument.body?.mimeType ?? ''
          );
        }
      },
    });
  }
}
