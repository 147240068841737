import { Component, OnInit } from '@angular/core';
import { DESC } from '@app/core/constants/Constants';
import { Filter } from '@app/core/models/front/filters';
import { Pagination } from '@app/core/models/front/pagination';
import { Sorters } from '@app/core/models/front/sorters';
import { Status } from '@app/core/models/input/common/status.model';
import { Companies } from '@app/core/models/input/company/company.model';
import { Page } from '@app/core/models/input/page/pages.model';
import { CompanyService } from '@app/core/services/company/company.service';
import { UserService } from '@app/core/services/user/user.service';
import { FilterUtils } from '@app/core/utils/filter.utils';
import { SorterUtils } from '@app/core/utils/sorter.utils';
import { GREEN_STATUS, ORANGE_STATUS, RED_STATUS } from '@app/core/constants/StatusUsers';
import { faCaretDown, faCaretUp, faHouseMedical, faRightLong, faSquarePlus } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-list-companies',
  templateUrl: './list-companies.component.html',
  styleUrls: ['./list-companies.component.scss']
})
export class ListCompaniesComponent implements OnInit{
  faHouseMedical = faHouseMedical;
  faSquarePlus = faSquarePlus;
  faRightLong = faRightLong;
  faCaretDown = faCaretDown;
  faCaretUp = faCaretUp;

  companies? : Companies[];
  page?: Page;
  pagination = new Pagination();
  filters = new Array<Filter>();
  sorters = new Sorters('creationDate', DESC);
  selectedState = '0';


  // Utils
  filterUtils: FilterUtils;
  sorterUtils: SorterUtils;

  nameFilter = new Filter();
  nameFilterStr?: string;
  isMotherCompany = false;
  statuses?: Status[];
  selectedStatus = 0;

  // status users
  GREEN_STATUS = GREEN_STATUS;
  ORANGE_STATUS = ORANGE_STATUS;
  RED_STATUS = RED_STATUS;

  constructor(
    private readonly companyService: CompanyService,
    private readonly userService: UserService
   ) {
    this.filterUtils = new FilterUtils(this.filters);
    this.sorterUtils = new SorterUtils(this.sorters);
  }

  ngOnInit(): void {
    this.getCompanies();
    this.getUserStatus();
  }

  paginar(amount: number) {
    this.pagination.page += amount;
    this.getCompanies();
  }



  addFilter(name: string, filter: string) {
    // If we filter the requested page will be 0
    this.pagination.page = 0;
    this.filterUtils.addFilter(name, filter);
    this.getCompanies();
  }

  removeFilter(name: string) {
    // If we filter the requested page will be 0
    this.pagination.page = 0;
    this.filterUtils.removeFilter(name);
    this.getCompanies();
  }

  getCompanies() {
    this.companyService
      .getCompanies(this.pagination, this.filters, this.sorters)
      .subscribe({
        next: data => {
          this.page = data;
          this.companies = data.content;
        },
      })
  }

  getUserStatus() {
    this.userService.getUserStatus().subscribe({
      next: statuses => {
        this.statuses = statuses;
      },
    });
  }

  addStatusFilter(statusId: string) {  
    // 0 means all statuses
    if (statusId != '0') {
      this.addFilter('userStatusId', statusId);
    } else {
      this.removeFilter('userStatusId');
    }
  }

  sortCompanies(property: string, direction: string) {
    this.sorterUtils.sortData(property, direction);
    this.getCompanies();
}
}
