import { Directive, ElementRef, Input, Output } from '@angular/core';
import { fromEvent, timer } from 'rxjs';
import { debounce, distinctUntilChanged, map } from 'rxjs/operators';

@Directive({
    selector: '[appDebounceInput]'
  })
  export class DebounceInputDirective {
    @Input() appDebounceInput = 0;

    constructor(private readonly el: ElementRef<HTMLInputElement>) {}

    @Output()
    readonly debounceInput = fromEvent(this.el.nativeElement, 'input').pipe(
      debounce(() => timer(this.appDebounceInput)),
      map(() => this.el.nativeElement.value),
      distinctUntilChanged()
    );
  }