import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ClientInput, 
  Company,
  CompanyAgreement,
  CompanyDataAdmin,
  CompanyNameAndUuidDTO,
} from '@app/core/models/input/company/company.model';
import { Logo } from '@app/core/models/input/logos/logo.model';
import {
  LogoOutput,
  UrlCompany,
} from '@app/core/models/output/logo-output.model';
import { EnvironmentService } from '@app/environment.service';
import { CacheImplService } from '@app/shared/services/cache/cache-impl.service';
import { Observable, catchError, of, tap, throwError } from 'rxjs';
import { UtilsService } from '../utils/utils.service';
import { Pagination } from '@app/core/models/front/pagination';
import { Filter } from '@app/core/models/front/filters';
import { Sorters } from '@app/core/models/front/sorters';
import { Page } from '@app/core/models/input/page/pages.model';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(
    private readonly http: HttpClient,
    private readonly env: EnvironmentService,
    private readonly uSrv: UtilsService,
    private readonly cacheService: CacheImplService
  ) {}

  getCompany(uuid: string) {
    return this.http.get<Company>(`${this.env.apiUrl}/companies/${uuid}`);
  }

  getCompanyLikeBusinessName(searchData: string) {
    return this.http.get<CompanyNameAndUuidDTO[]>(`${this.env.apiUrl}/companies/search/${searchData}`);
  }

  getCompanies(pagination: Pagination, filters: Filter[], sorters: Sorters) {
    return this.http.get<Page>(
      `${this.env.apiUrl}/companies?`+
      this.uSrv.genPaginationInUri(pagination)+
      this.uSrv.getFiltersInUri(filters) +
      this.uSrv.genSortersInUri(sorters)
    );
  }

  editCompany(dataCompany: Company) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.put<Company>(
      `${this.env.apiUrl}/companies/${dataCompany.uuid}`,
      JSON.stringify(dataCompany),
      { headers: headers }
    );
  }

  getCompanyLogoApi(companyUuid: string, type: string): Observable<Logo> {
    const logoFromStorage = this.getLogoFromStorage(type);
    if (logoFromStorage != null) {
      return of(logoFromStorage);
    } else {
      return this.http
        .get<Logo>(
          `${this.env.apiUrl}/companies/${companyUuid}/logo?name=${type}`
        )
        .pipe(
          tap(data => this.setCompanyLogo(data, type)),
          catchError(error => {
            // Handle or log the error
            console.error('Error fetching company logo:', error);
            return throwError(() => new Error('Error fetching company logo'));
          })
        );
    }
  }
  editCompanyLogo(companyUuid: string, companyLogo: LogoOutput) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.put<LogoOutput>(
      `${this.env.apiUrl}/companies/${companyUuid}/logo`,
      JSON.stringify(companyLogo),
      { headers: headers }
    );
  }

  deleteCompanyLogo(companyUuid: string, type: string) {
    return this.http.delete(
      `${this.env.apiUrl}/companies/${companyUuid}/logo?name=${type}`
    );
  }

  editUrlCompany(companyUuid: string, urlCompany: UrlCompany) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.patch<UrlCompany>(
      `${this.env.apiUrl}/companies/${companyUuid}/website`,
      JSON.stringify(urlCompany),
      { headers: headers }
    );
  }

  getParametersToCreate() {
    return this.http.get<Company>(
      `${this.env.apiUrl}/companies/parameters-to-create`
    );
  }

  newCompany(dataClient: ClientInput) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post<ClientInput>(
      `${this.env.apiUrl}/companies`,
      JSON.stringify(dataClient),
      { headers: headers }
    );
  }

  // Get the logo by type; directly from session storage
  private getLogoFromStorage(logoType = 'MAIN'): Logo | null {
    // Attempt to get the logo from session storage
    const storedLogo = this.cacheService.getItemNotEncrypted(
      `company-logo-${logoType}`
    );
    if (storedLogo) {
      // If found, parse and return it
      return JSON.parse(storedLogo);
    } else {
      // If not found, return a new Logo instance
      return null;
    }
  }

  // Save the logo in session storage
  public setCompanyLogo(userSignature: Logo, logoType = 'MAIN'): void {
    if (userSignature) {
      this.cacheService.setItemNotEncrypted(
        `company-logo-${logoType}`,
        JSON.stringify(userSignature)
      );
    }
  }

  getCompanyParameters(uuid: string) {
    return this.http.get<CompanyDataAdmin>(`${this.env.apiUrl}/companies/parameters/${uuid}`);
  }

  editCompanyAgreement(companyUuid: string, dataCompany: CompanyAgreement) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.put<CompanyAgreement>(
      `${this.env.apiUrl}/companies/agreement/${companyUuid}`,
      JSON.stringify(dataCompany),
      { headers: headers }
    );
  }
}
