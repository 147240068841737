import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DESC } from '@app/core/constants/Constants';
import { GREEN_STATUS, ORANGE_STATUS, RED_STATUS } from '@app/core/constants/StatusUsers';
import RoleEnum from '@app/core/enums/role.enum';
import UserStatusEnum from '@app/core/enums/user-status.enum';
import { Filter } from '@app/core/models/front/filters';
import { Pagination } from '@app/core/models/front/pagination';
import { Sorters } from '@app/core/models/front/sorters';
import { Status } from '@app/core/models/input/common/status.model';
import { Page } from '@app/core/models/input/page/pages.model';
import { Role } from '@app/core/models/input/role.model';
import { User } from '@app/core/models/input/user.model';
import { UserService } from '@app/core/services/user/user.service';
import { FilterUtils } from '@app/core/utils/filter.utils';
import { SorterUtils } from '@app/core/utils/sorter.utils';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-list-professionals',
  templateUrl: './list-professionals.component.html',
  styleUrls: ['./list-professionals.component.scss'],
})
export class ListProfessionalsComponent implements OnInit {
  faCaretDown = faCaretDown;
  faCaretUp = faCaretUp;

  pagination = new Pagination();
  filters = new Array<Filter>();
  sorters = new Sorters('creationDate', DESC);
  doctors?: User[];

  // Utils
  filterUtils: FilterUtils;
  sorterUtils: SorterUtils;
  // enum
  userStatusEnum = UserStatusEnum;
  roleEnum = RoleEnum;

  // status users
  GREEN_STATUS = GREEN_STATUS;
  ORANGE_STATUS = ORANGE_STATUS;
  RED_STATUS = RED_STATUS;

  nameFilter = new Filter();
  nameFilterStr?: string;
  page?: Page;
  roles?: Role[] = [];
  statuses?: Status[];
  selectedStatus = 0;
  companyUuid?: string;

  constructor(private readonly userService: UserService, private readonly activatedRoute: ActivatedRoute) {
    this.filterUtils = new FilterUtils(this.filters);
    this.sorterUtils = new SorterUtils(this.sorters);
    this.activatedRoute.params.subscribe(param => {
      this.companyUuid = param['companyUuid'];
    });
  }

  ngOnInit() {
    this.getUserStatus();
    this.filterUtils.addFilter(
      'rolesId',
      RoleEnum.PARTICULAR.toString() +
        ',' +
        RoleEnum.COMPANY_DOCTOR.toString() +
        ',' +
        RoleEnum.FREEMIUM.toString() +
        ',' +
        RoleEnum.PREMIUM_DOCTOR.toString()
    );

    if (this.companyUuid !== undefined) {
      this.filterUtils.addFilter('companyUuid', this.companyUuid);
    }
    this.getDoctorsByCompany();
  }

  addFilter(name: string, filter: string) {
    // If we filter the requested page will be 0
    this.pagination.page = 0;
    this.filterUtils.addFilter(name, filter);
    this.getDoctorsByCompany();
  }

  removeFilter(name: string) {
    // If we filter the requested page will be 0
    this.pagination.page = 0;
    this.filterUtils.removeFilter(name);
    this.getDoctorsByCompany();
  }

  addStatusFilter(statusId: string) {
    // 0 means all statuses
    if (statusId != '0') {
      this.addFilter('userStatusId', statusId);
    } else {
      this.removeFilter('userStatusId');
    }
  }

  paginar(amount: number) {
    this.pagination.page += amount;
    this.getDoctorsByCompany();
  }

  getDoctorsByCompany() {
    this.userService.getUsers(this.pagination, this.filters, this.sorters).subscribe({
      next: usersPage => {
        this.page = usersPage;
        this.doctors = this.page.content;
      },
    });
  }

  sortProfessionals(property: string, direction: string) {
    this.sorterUtils.sortData(property, direction);
    this.getDoctorsByCompany();
  }

  getUserStatus() {
    this.userService.getUserStatus().subscribe({
      next: statuses => {
        this.statuses = statuses;
      },
    });
  }
}
