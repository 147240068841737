import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import PaymentMethodTypeEnum from '@app/core/enums/payment-method-type.enum';
import RegistryTypeEnum from '@app/core/enums/registry-type.enum';
import {
  BillingType,
  CompanyParameters,
  DoctorsAmounts,
  PaymentMethodTypes,
  RegistryTypes,
  SignaturesAmounts,
  SubscriptionTypes,
} from '@app/core/models/input/company/company-parameters.model';
import {
  ClientInput,
  RepresentativeCompany,
} from '@app/core/models/input/company/company.model';
import { Language } from '@app/core/models/input/language.model';
import { Specialty } from '@app/core/models/input/specialty.model';
import { State } from '@app/core/models/input/state.model';
import { AlertService } from '@app/core/services/alert/alert.service';
import { CompanyService } from '@app/core/services/company/company.service';
import { DataClientService } from '@app/core/services/data-client/data-client.service';
import { LanguageService } from '@app/core/services/language/language.service';
import { StateService } from '@app/core/services/state/state.service';
import {
  faCircleArrowLeft,
  faCircleArrowRight,
  faClipboard,
  faCreditCard,
  faEarthEurope,
  faEnvelope,
  faEye,
  faLocationDot,
  faMap,
  faMapPin,
  faMobile,
  faPhone,
  faUser,
  faUserDoctor,
  faUserSecret
} from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-page6-data-confirm',
  templateUrl: './page6-data-confirm.component.html',
  styleUrls: ['./page6-data-confirm.component.scss'],
})
export class Page6DataConfirmComponent implements OnInit {
  faCircleArrowRight = faCircleArrowRight;
  faCircleArrowLeft = faCircleArrowLeft;
  faUserDoctor = faUserDoctor;
  faCreditCard = faCreditCard;
  faEye = faEye;
  faClipboard = faClipboard;
  faEnvelope = faEnvelope;
  faPhone = faPhone;
  faLocationDot = faLocationDot;
  faMap = faMap;
  faMapPin = faMapPin;
  faMobile = faMobile;
  faUserSecret = faUserSecret;
  faEarthEurope = faEarthEurope;
  faUser = faUser;

  parameters?: CompanyParameters[];
  billingTypes?: BillingType[];
  doctorsAmounts?: DoctorsAmounts[];
  paymentMethodTypes?: PaymentMethodTypes[];
  registryTypes?: RegistryTypes[];
  signaturesAmounts?: SignaturesAmounts[];
  subscriptionTypes?: SubscriptionTypes[];
  specialty?: Specialty[] = [];
  specialties?: Specialty[];
  isSepa = false;
  isBillingSigns = false;

  billingTypesName?: BillingType;
  doctorsAmountsName?: DoctorsAmounts;
  paymentMethodTypesName?: PaymentMethodTypes;
  registryTypesName?: RegistryTypes;
  signaturesAmountsName?: SignaturesAmounts;
  subscriptionTypesNames?: string[] = [];
  specialtiesNames?: Specialty[];
  languages?: Language[];
  languageName?: Language;
  states?: State[];
  stateName?: State;
  stateFisName?: State;
  isAccepted = false;

  @Output() continue5 = new EventEmitter();

  constructor(
    public dataClientService: DataClientService,
    private readonly companyService: CompanyService,
    private readonly alertService: AlertService,
    private readonly translate: TranslateService,
    private readonly languageService: LanguageService,
    private readonly stateService: StateService
  ) {}

  togglePagesFiveSix() {
    this.continue5.emit();
  }

  ngOnInit(): void {
    this.companyService.getParametersToCreate().subscribe({
      next: data => {
        this.billingTypes = data['billingTypes'];
        this.doctorsAmounts = data['doctorsAmounts'];
        this.paymentMethodTypes = data['paymentMethodTypes'];
        this.registryTypes = data['registryTypes'];
        this.signaturesAmounts = data['signaturesAmounts'];
        this.subscriptionTypes = data['subscriptionTypes'];

        if (
          this.dataClientService.dataClient?.value.dataHiring.registryTypeId == RegistryTypeEnum.HIS
        ) {
          this.isBillingSigns = true;
        }

        if (
          this.dataClientService.dataClient?.value.dataHiring.paymentMethodTypeId == PaymentMethodTypeEnum.SEPA
        ) {
          this.isSepa = true;
        }

        const subscriptionTypesIds =
          this.dataClientService.dataClient?.value.dataHiring
            .subscriptionsTypeId;
        subscriptionTypesIds.forEach((id: number | undefined) => {
          const subscriptionTypesName = this.subscriptionTypes?.find(
            b => b.id == id
          );
          if (subscriptionTypesName && subscriptionTypesName.name) {
            this.subscriptionTypesNames?.push(subscriptionTypesName.name);
          }
        });

        this.billingTypesName = this.billingTypes?.find(
          b =>
            b.id ==
            this.dataClientService.dataClient?.get('dataHiring.billingTypeId')
              ?.value
        );
        this.doctorsAmountsName = this.doctorsAmounts?.find(
          b =>
            b.id ==
            this.dataClientService.dataClient?.get('dataHiring.doctorsAmountId')
              ?.value
        );
        this.paymentMethodTypesName = this.paymentMethodTypes?.find(
          b =>
            b.id ==
            this.dataClientService.dataClient?.get(
              'dataHiring.paymentMethodTypeId'
            )?.value );
        this.registryTypesName = this.registryTypes?.find(
          b =>
            b.id ==
            this.dataClientService.dataClient?.get('dataHiring.registryTypeId')
              ?.value );
        this.signaturesAmountsName = this.signaturesAmounts?.find(
          b =>
            b.id == this.dataClientService.dataClient?.get('dataHiring.signaturesAmountId')?.value);

      },
    });

    this.stateService
    .getStateByCountryId(this.dataClientService.dataClient?.get('dataAccess.languageId')?.value.id)
    .subscribe({
      next: states => {
        if(states) {
          this.states = states;
        }
         this.stateName = this.states?.find(b => b.id == this.dataClientService.dataClient?.get('dataContact.stateId')?.value);
         this.stateFisName = this.states?.find(b => b.id ==this.dataClientService.dataClient?.get('dataRgpd.stateIdFis')?.value);
        },
    });



      this.languageService.getLanguagesApi().subscribe({
        next: languages => {
          if (languages) {
            this.languageService.setLanguages(languages);
            this.languages = languages;
          }
          this.languageName = this.languages?.find(b => b.id == this.dataClientService.dataClient?.get('dataAccess.languageId')?.value.id);
        }
      })


  }
  checkedAccepted(){
    this.isAccepted = !this.isAccepted;
  }

  onSubmit() {
    const representativeCompany: RepresentativeCompany = {
      name: this.dataClientService.dataClient?.get('dataCompany.name')?.value,
      dniCif:
        this.dataClientService.dataClient?.get('dataCompany.dniCif')?.value,
      mobile:
        this.dataClientService.dataClient?.get('dataCompany.mobile')?.value,
      email: this.dataClientService.dataClient?.get('dataCompany.email')?.value,
    };

    const dataCompany: ClientInput = {
      doctorsAmountId: this.dataClientService.dataClient?.get(
        'dataHiring.doctorsAmountId'
      )?.value,
      price: this.dataClientService.dataClient?.get('dataHiring.price')?.value,
      billingTypeId: this.dataClientService.dataClient?.get(
        'dataHiring.billingTypeId'
      )?.value,
      subscriptionsTypeId: this.dataClientService.dataClient?.get(
        'dataHiring.subscriptionsTypeId'
      )?.value,
      paymentMethodTypeId: this.dataClientService.dataClient?.get(
        'dataHiring.paymentMethodTypeId'
      )?.value,
      bankAccount: this.dataClientService.dataClient?.get(
        'dataHiring.bankAccount'
      )?.value,
      registryTypeId: this.dataClientService.dataClient?.get(
        'dataHiring.registryTypeId'
      )?.value,
      signaturesAmountId: this.dataClientService.dataClient?.get(
        'dataHiring.signaturesAmountId'
      )?.value,
      his: this.dataClientService.dataClient?.get('dataHiring.his')?.value,
      specialtiesId: this.dataClientService.dataClient?.get(
        'dataHiring.specialtiesId'
      )?.value,
      businessName: this.dataClientService.dataClient?.get(
        'dataCompany.businessName'
      )?.value,
      cif: this.dataClientService.dataClient?.get('dataCompany.cif')?.value,
      website: this.dataClientService.dataClient?.get('dataCompany.website')?.value == ''
      ? 'https://elci.com'
      : this.dataClientService.dataClient?.get('dataCompany.website')?.value,
      representativeCompany: representativeCompany,
      username: this.dataClientService.dataClient?.get('dataAccess.username')
        ?.value,
      password: this.dataClientService.dataClient?.get('dataAccess.password')
        ?.value,
      languageId: this.dataClientService.dataClient?.get('dataAccess.languageId')
        ?.value.id,
      address: this.dataClientService.dataClient?.get('dataContact.address')
        ?.value,
      locality: this.dataClientService.dataClient?.get('dataContact.locality')
        ?.value,
      postCode: this.dataClientService.dataClient?.get('dataContact.postCode')
        ?.value,
      stateId: this.dataClientService.dataClient?.get('dataContact.stateId')
        ?.value,
      email: this.dataClientService.dataClient?.get('dataContact.email')?.value,
      mobile:
        this.dataClientService.dataClient?.get('dataContact.mobile')?.value,
      phone: this.dataClientService.dataClient?.get('dataContact.phone')?.value,
      businessNameFis: this.dataClientService.dataClient?.get(
        'dataRgpd.businessNameFis'
      )?.value,
      cifFis: this.dataClientService.dataClient?.get('dataRgpd.cifFis')?.value,
      addressFis: this.dataClientService.dataClient?.get('dataRgpd.addressFis')
        ?.value,
      localityFis: this.dataClientService.dataClient?.get(
        'dataRgpd.localityFis'
      )?.value,
      postCodeFis: this.dataClientService.dataClient?.get(
        'dataRgpd.postCodeFis'
      )?.value,
      stateIdFis: this.dataClientService.dataClient?.get('dataRgpd.stateIdFis')
        ?.value,
      emailLOPD:
        this.dataClientService.dataClient?.get('dataRgpd.emailLOPD')?.value,
    };


    if(this.isAccepted){
      this.companyService.newCompany(dataCompany).subscribe({
        next: () =>{
          this.alertService.success(
            this.translate.instant(
              'SUPER-ADMIN.CLIENT.DATA-CONFIRN.MESSAGE-NEWCLIENT-OK'
            )
          );
          setTimeout(() => {
            window.location.reload();
          }, 3000);         
        },
        error: () => {
          this.alertService.error(
            this.translate.instant(
              'PRIVATE.DOCTOR.DATA-DOCTOR.MESSAGE-ERROR'
            )
          );
        },
      });
    }else {
      this.alertService.error(
        this.translate.instant(
          'SUPER-ADMIN.CLIENT.DATA-CONFIRN.MESSAGE-ACCEPT-ERROR'
        )
      );
    }

  }
}
