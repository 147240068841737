import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { DEFAULT_ELCI_LANGUAGE } from '@app/core/constants/Constants';
import { ClientInput } from '@app/core/models/input/company/company.model';
import { Language } from '@app/core/models/input/language.model';
import { AlertService } from '@app/core/services/alert/alert.service';
import { DataClientService } from '@app/core/services/data-client/data-client.service';
import { LanguageService } from '@app/core/services/language/language.service';
import { TranslateCustomService } from '@app/shared/services/translate/translate.service';
import { faCircleArrowLeft, faCircleArrowRight } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-page3-data-access',
  templateUrl: './page3-data-access.component.html',
  styleUrls: ['./page3-data-access.component.scss']
})
export class Page3DataAccessComponent implements OnInit {
  faCircleArrowRight = faCircleArrowRight;
  faCircleArrowLeft = faCircleArrowLeft;



  @Output() continue = new EventEmitter();
  @Output() continue2 = new EventEmitter();

  dataAccess!: FormGroup;
  classInput = '';
  isSubmit = false;

  languages?: Language[];
  selectedLanguage?: Language;
  

  constructor(
    private readonly dataClientService: DataClientService,
    private readonly alertService: AlertService,
    private readonly translate: TranslateService,
    private readonly languageService: LanguageService,
    private readonly translateCustom: TranslateCustomService

  ) {}

  togglePagesTwoThree(){
    this.continue.emit();
  }

  togglePagesThreeFour(){
    this.continue2.emit();
  }

  getLanguages(): void {
    this.languageService.getLanguagesApi().subscribe({
      next: languages => {
        if (languages) {
          this.languageService.setLanguages(languages);
          this.languages = languages;
          // establecemos el español de España por defecto
          this.selectLanguage();
        }
      }
    })
  }

  selectLanguage() { 
    if(this.selectedLanguage)  {
      const languageLocale = this.selectedLanguage.locale?.replace("_", "-") ?? DEFAULT_ELCI_LANGUAGE;    
      this.translateCustom.changeLang(languageLocale); 
    }
       
  }

  // función que valida cada dato del formulario
  shouldShowError(inputControlName: string): boolean {
    const control = this.dataAccess.get(inputControlName);
    return !!(control && control.touched && control.invalid);
  }

  ngOnInit(): void {
    this.dataAccess = new FormGroup({
      username: new FormControl({ value: '', disabled: false }, [
        Validators.required,
      ]),
      password: new FormControl({ value: '', disabled: false }, [
        Validators.required,
      ]),
      repeatPass: new FormControl({ value: '', disabled: false }, [
        Validators.required,
      ]),
      languageId: new FormControl({ value: '', disabled: false }, [
        Validators.required,
      ]),     

    });   

    this.getLanguages();
  }

  onSubmit() {

    this.isSubmit = true;

    Object.keys(this.dataAccess.controls).forEach(controlName => {
      this.dataAccess.get(controlName)?.markAsTouched();
    });
   

    if (this.dataAccess.valid) {

      const clientObject: ClientInput = {
        username: this.dataAccess.get('username')?.value,
        password: this.dataAccess.get('password')?.value,
        repeatPass: this.dataAccess.get('repeatPass')?.value,
        languageId: this.selectedLanguage?.id
      }
     
      if (clientObject.password === clientObject.repeatPass){
        this.dataClientService.addFormGroup('dataAccess', this.dataAccess);
        this.togglePagesThreeFour();
      }
      else{
        this.alertService.error(
          this.translate.instant(
            'SUPER-ADMIN.CLIENT.DATA-ACCESS.MESSAGE-ERROR-PASS-DOUBLE'
          )
        );
      }


    } else {
      this.classInput = 'wrong';
      this.alertService.error(
        this.translate.instant(
          'PRIVATE.PATIENT.DATA-PATIENT.MESSAGE-PATIENT-NEW-ERROR'
        )
      );
    }
  }
}


