import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DEFAULT_ELCI_SPECIALTY_LANG } from '@app/core/constants/Constants';
import { CompanyNameAndUuidDTO } from '@app/core/models/input/company/company.model';
import { Consent } from '@app/core/models/input/consent/consent.model';
import { ConvertInputModel } from '@app/core/models/input/convert-input.model';
import { Language } from '@app/core/models/input/language.model';
import { Page } from '@app/core/models/input/page/pages.model';
import { Procedure } from '@app/core/models/input/procedure/procedure.model';
import { CiTemplate } from '@app/core/models/input/template/ci-template.model';
import { TemplateLiteInputDTO } from '@app/core/models/input/template/template-lite.model';
import { User } from '@app/core/models/input/user.model';
import { CompanyService } from '@app/core/services/company/company.service';
import { ConsentService } from '@app/core/services/consent/consent.service';
import { LanguageService } from '@app/core/services/language/language.service';
import { ProcedureService } from '@app/core/services/procedure/procedure.service';
import { TemplateService } from '@app/core/services/template/template.service';
import StringUtils from '@app/core/utils/string.utils';
import { CiTemplateComponent } from '@app/private/consents/components/ci-template/ci-template.component';
import { faAngleDown, faCircleXmark, faDownload, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { saveAs } from 'file-saver';
import * as JSZip from 'jszip';
import { HtmlToPdfService } from '../../../../core/services/html-to-pdf/html-to-pdf.service';
import { ConsentsShareService } from '../../../../private/consents/services/consents-share.service';
import { CacheImplService } from '../../../../shared/services/cache/cache-impl.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-gen-consents-per-specialty',
  templateUrl: './gen-consents-per-specialty.component.html',
  styleUrls: ['./gen-consents-per-specialty.component.scss'],
})
export class GenConsentsPerSpecialtyComponent implements OnInit {
  @ViewChild(CiTemplateComponent) ciTemplateComponent!: CiTemplateComponent;
  @ViewChild('logContainer') private readonly logContainer!: ElementRef;

  @Input() specialtyId?: number;
  @Input() procedureId?: number;

  procedures: Procedure[] = [];
  templates: TemplateLiteInputDTO[] = [];
  languages: Language[] = [];
  selectedLanguage = DEFAULT_ELCI_SPECIALTY_LANG;

  decoyUser!: User;
  selectedTemplate?: CiTemplate;
  loadCiTemplate = false;

  consent: Consent;
  form: FormGroup;

  // Companies
  companies?: CompanyNameAndUuidDTO[];
  selectedCompany?: CompanyNameAndUuidDTO;
  page?: Page;
  lastSearchTerm = '';

  // Log info
  logMessages = [""]

  faSpinner = faSpinner;
  faDownload = faDownload;
  faCircleXmark = faCircleXmark;
  faAngleDown = faAngleDown;

  // Zip
  zip = new JSZip();
  content!: Blob;

  // Stats
  zipProgressPercentage = 0;
  isProcessInitiated = false;
  processFinished = false;

  isCompanySelected = false;
  isLanguageSelected = false;
  isTemplateSelected = false;
  showDropdown = false;
  searchPatient = false;

  constructor(
    private readonly procedureService: ProcedureService,
    private readonly templateService: TemplateService,
    private readonly languageService: LanguageService,
    private readonly companyService: CompanyService,
    private readonly consentsShareService: ConsentsShareService,
    private readonly htmlToPdfService: HtmlToPdfService,
    private readonly consentService: ConsentService,
    private readonly cacheImplService: CacheImplService,
    private readonly translate: TranslateService,
  ) {
    this.translate.get("SUPER-ADMIN.GEN-CONSENTS-PER-SPECIALITY.FILL-ALL-REQUIRED-FIELDS").subscribe((res: string) => {
      this.logMessages = [res];
    });
    this.consent = new Consent();
    this.form = new FormGroup({
      language: new FormControl('0'),
      template: new FormControl('0'),
      document: new FormControl(''),
      companyName: new FormControl(''),
    });
    this.initFormSubscriptions();
  }

  ngOnInit() {
    this.loadLanguages();
  }

  toggleDropdown(forceShow = false, valueIfForce = true) {
    if (forceShow) {
      this.showDropdown = valueIfForce;
    } else this.showDropdown = !this.showDropdown;
    this.searchPatient = false;
  }

  initFormSubscriptions() {
    this.form.get('language')?.valueChanges.subscribe(locale => {
      if (locale && locale !== '') {
        this.loadTemplates(locale);
        this.isLanguageSelected = true;
        this.selectedLanguage = locale.split('_')[0];
      } else {
        this.isLanguageSelected = false;
      }
    });
    this.form.get('template')?.valueChanges.subscribe(templateUuid => {
      if (templateUuid && templateUuid !== '') {
        this.isTemplateSelected = true;
        this.consent.templateUuid = templateUuid;
        this.consentService.setConsent(this.consent);
        this.initDecoyUserData();
      } else this.isTemplateSelected = false;
    });
  }

  processConsents() {
    // Clean last export if exists
    this.processFinished = false;
    this.isProcessInitiated = true;
    this.content = new Blob;
    this.zip = new JSZip();
    this.zipProgressPercentage = 0;
    this.logMessages = [this.translate.instant("SUPER-ADMIN.GEN-CONSENTS-PER-SPECIALITY.FILL-ALL-REQUIRED-FIELDS")];

    if (this.procedureId) {
      this.loadSingleProcedure(this.procedureId);
    }
    if (this.specialtyId) {
      this.loadProcedures(this.specialtyId);
    }
    this.initDecoyUserData();
  }

  initDecoyUserData() {
    const tempUser = new User();
    if (this.selectedCompany?.uuid) tempUser.companyUuid = this.selectedCompany.uuid;
    if (this.selectedCompany?.businessName) tempUser.company = this.selectedCompany.businessName;

    this.decoyUser = JSON.parse(JSON.stringify(tempUser));
    this.loadCiTemplate = true;
  }

  loadProcedures(specialtyId: number) {
    this.procedureService
      .getProceduresByCountry(specialtyId.toString(), 'es', this.selectedLanguage)
      .subscribe(procedures => {
        if (procedures.length != 0) {
          this.procedures = procedures;
          
          const procedurePromises = procedures.map(procedure => {
            if (procedure.code) {
              return this.getProcedure(parseInt(procedure.code));
            } else {
              return Promise.resolve();
            }
          });
  
          Promise.all(procedurePromises)
            .then(() => {
              this.generateZip();
            })
            .catch(error => {
              console.error(this.translate.instant("SUPER-ADMIN.GEN-CONSENTS-PER-SPECIALITY.ERRORS-IN-PDF-GENERATION", {error: error}));
            });
        } else {
          this.logData(this.translate.instant("SUPER-ADMIN.GEN-CONSENTS-PER-SPECIALITY.SPECIALITY-HAS-NO-PROCEDURES", {selectedLanguage: this.selectedLanguage}))
          this.processFinished = true;
        }
      });
  }

  loadSingleProcedure(procedureId: number) {
    this.getProcedure(procedureId)
      .then(() => {
        this.generateZip();
      })
      .catch(error => {
        console.error(this.translate.instant("SUPER-ADMIN.GEN-CONSENTS-PER-SPECIALITY.ERROR-GENERATING-PDF", {error: error}));
      });
  }

  get logContent(): string {
    return this.logMessages.join('\n');
  }

  getProcedure(idProcedure: number): Promise<void> {
    this.logData('Recuperando datos del procedimiento: ' + idProcedure);
    return new Promise((resolve, reject) => {
      this.procedureService.getProcedureWithImagesByCode(idProcedure.toString()).subscribe({
        next: procedure => {
          if (procedure) {
            this.logData(this.translate.instant(
              'SUPER-ADMIN.GEN-CONSENTS-PER-SPECIALITY.RECOVERING-PROCEDURE-DATA',
              {idProcedure: idProcedure}
            ));
            this.consentsShareService.setSelectedProcedure(procedure);
            this.selectedTemplate = this.ciTemplateComponent?.selectedTemplate;

            if (this.selectedTemplate) {
              this.consent.procedureName = procedure.title;
              this.logData(this.translate.instant(
                'SUPER-ADMIN.GEN-CONSENTS-PER-SPECIALITY.STARTING-PDF-CONVERSION-OF',
                {idProcedure: idProcedure}
              ));
              this.htmlToPdfService.convertHtmlToPdf(this.createConverterInput(this.selectedTemplate)).subscribe({
                next: pdfContentB64 => {
                  if (pdfContentB64.pdfBase64) {
                    const sanitizedFilename = StringUtils.sanitizeFilename(
                      `${idProcedure} ${'_' + (procedure.title ?? '')}`
                    );
                    this.addPdfToZip(sanitizedFilename, pdfContentB64.pdfBase64);
                    this.logData(this.translate.instant(
                      'SUPER-ADMIN.GEN-CONSENTS-PER-SPECIALITY.SUCCESS-PDF-CONVERSION-OF',
                      {idProcedure: idProcedure}
                    ));
                  }
                  resolve();
                },
                error: () => {
                  reject();
                },
              });
            } else {
              resolve();
            }
          } else {
            resolve();
          }
        },
        error: () => {
          reject();
        },
      });
    });
  }

  addPdfToZip(name: string, pdfContentB64: string) {
    this.logData(this.translate.instant(
      'SUPER-ADMIN.GEN-CONSENTS-PER-SPECIALITY.ADDING-PROCEDURE-PDF-TO-ZIP',
      {name: name}
    ));
    const pdfContent = atob(pdfContentB64);
    const byteArray = new Uint8Array(pdfContent.length);

    for (let i = 0; i < pdfContent.length; i++) {
      byteArray[i] = pdfContent.charCodeAt(i);
    }

    this.zip.file(`${name}.pdf`, byteArray, { binary: true });
  }

  generateZip() {
    this.zip
      .generateAsync({ type: 'blob' }, metadata => {
        this.zipProgressPercentage = Math.floor(metadata.percent);
      })
      .then(content => {
        this.processFinished = true;
        this.isProcessInitiated = false;
        this.content = content;
      })
      .catch(error => {
        console.error(this.translate.instant(
          'SUPER-ADMIN.GEN-CONSENTS-PER-SPECIALITY.ERROR-PROCEDURE-PDF-TO-ZIP',
          {error: error}
        ));
      });
  }

  downloadZip() {
    if (this.processFinished && this.content) {
      saveAs(this.content, 'procedures.zip');
    }
  }

  private createConverterInput(ciTemplate: CiTemplate): ConvertInputModel {
    const convertInputModel = new ConvertInputModel();
    convertInputModel.topMargin = ciTemplate.topMargin;
    convertInputModel.bottomMargin = ciTemplate.bottomMargin;
    convertInputModel.htmlHeader = this.convertToBase64(ciTemplate.header);
    convertInputModel.htmlBody = this.convertToBase64(ciTemplate.body);
    convertInputModel.htmlFooter = this.convertToBase64(ciTemplate.footer);

    return convertInputModel;
  }

  private convertToBase64(input: string | undefined): string {
    if (input) return btoa(unescape(encodeURIComponent(input)));
    else return '';
  }

  loadTemplates(locale: string) {
    this.templateService.getCiTemplates(locale).subscribe(templates => {
      this.templates = templates;
    });
  }

  loadLanguages() {
    this.languageService.getLanguagesApi().subscribe({
      next: langs => {
        this.languages = langs;
      },
    });
  }

  getCompanies(searchTerm: string) {
    if (this.lastSearchTerm !== searchTerm) {
      this.toggleDropdown(true);
      if (searchTerm !== '') {
        this.companyService.getCompanyLikeBusinessName(searchTerm).subscribe({
          next: data => {
            this.companies = data;
          },
        });
      }
    }
  }

  setSelectedCompany(companyNameAndUuidDTO: CompanyNameAndUuidDTO) {
    this.isCompanySelected = true;
    this.lastSearchTerm = companyNameAndUuidDTO.businessName ?? '';
    this.toggleDropdown(true, false);
    this.selectedCompany = companyNameAndUuidDTO;
    this.form.get('companyName')?.setValue(companyNameAndUuidDTO.businessName);
    this.refreshCompanyLogo(companyNameAndUuidDTO.uuid);
  }

  refreshCompanyLogo(companyUuid: string | undefined) {
    this.cacheImplService.removeItem(`company-logo-MAIN`)
    if (this.ciTemplateComponent) 
      this.ciTemplateComponent.refreshCompanyLogo(companyUuid);
  }

  logData(message: string) {
    this.logMessages.push(message);
    this.scrollToBottom();
  }

  private scrollToBottom(): void {
    try {
      this.logContainer.nativeElement.scrollTop = this.logContainer.nativeElement.scrollHeight;
    } catch (error) {
      console.error(this.translate.instant(
        'SUPER-ADMIN.GEN-CONSENTS-PER-SPECIALITY.SCROLL-TO-BOTTOM-ERROR',
        {error: error}
      ));
    }
  }
}
