import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DEFAULT_ELCI_LANGUAGE, DEFAULT_TEMPLATE_RGPD } from '@app/core/constants/Constants';
import { Variable } from '@app/core/models/input/consent/variable.model';
import { Language } from '@app/core/models/input/language.model';
import { RgpdTemplate } from '@app/core/models/input/template/rgpd-template.model';
import { TemplateLiteInputDTO } from '@app/core/models/input/template/template-lite.model';
import { User } from '@app/core/models/input/user.model';
import { VarOutputDTO } from '@app/core/models/output/consent/consent-var-output-model';
import { RgpdOutputDTO } from '@app/core/models/output/rgpd/rgpd.model';
import { AlertService } from '@app/core/services/alert/alert.service';
import { LoginService } from '@app/core/services/login/login.service';
import { RgpdService } from '@app/core/services/rgpd/rgpd.service';
import { RoleService } from '@app/core/services/role/role.service';
import { TemplateService } from '@app/core/services/template/template.service';
import { VariableService } from '@app/core/services/utils/variable/variable.service';
import { faBackward } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { LanguageService } from '../../../../core/services/language/language.service';
import { PatientService } from '@app/core/services/patient/patient.service';
import { Patient } from '@app/core/models/input/patient/patient.model';
import { TranslateCustomService } from '@app/shared/services/translate/translate.service';
import { QuickApiService } from '@app/quick-api/api-handler/services/quick-api/quick-api.service';
@Component({
  selector: 'app-gen-docs',
  templateUrl: './gen-docs.component.html',
  styleUrls: ['./gen-docs.component.scss'],
})
export class GenDocsComponent implements OnInit, OnDestroy {
  faBackward = faBackward;

  user?: User;

  vars?: Variable[];
  dataSignatureForm!: FormGroup;
  patientUuid?: string;
  selectedTemplate?: RgpdTemplate;
  isApp = true;
  isQuickapi = false;

  selectedTemplateUuid: string | null = '';
  selectedTemplateLanguage = DEFAULT_ELCI_LANGUAGE;

  templates?: TemplateLiteInputDTO[];
  languages?: Language[];
  patient?: Patient;

  // This property is set in the service when the quick api object is created and its passed to the consent.
  quickApiUuid: string | undefined = undefined;

  varSubscription?: Subscription;
  templateSubscription?: Subscription;
  quickApiUuidSubscription?: Subscription;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly variableService: VariableService,
    private readonly rgpdService: RgpdService,
    private readonly alertService: AlertService,
    private readonly translate: TranslateService,
    private readonly roleService: RoleService,
    private readonly loginService: LoginService,
    private readonly languageService: LanguageService,
    private readonly templateService: TemplateService,
    private readonly quickApiService: QuickApiService,
    private readonly patientService: PatientService,
    private readonly translateService: TranslateCustomService
  ) {
    this.activatedRoute.params.subscribe(param => {
      this.patientUuid = param['uuid'];
      this.getPatientByUuid(param['uuid']);
    });
  }

  ngOnInit() {
    this.getServiceSubscriptions();
    this.user = this.loginService.userValue ?? new User();
    this.getLanguages();


    if (this.roleService.isQuickapi()) {
      this.isApp = false;
      this.isQuickapi = true;
    }
  }

  handleChildFormGroup(childFormGroup: FormGroup) {
    // Now you have access to the FormGroup emitted by the child component
    // You can use it in the parent component as needed 
    this.dataSignatureForm = childFormGroup; // For example, patch the parent form with child form values    
    this.onSubmit();    
  }

  getServiceSubscriptions(): void {
    this.varSubscription = this.variableService.vars$.subscribe(vars => {
      if (vars) {
        this.vars = vars;
      }
    });
    this.templateSubscription = this.templateService.template$.subscribe(
      template => {
        if (template) {
          this.selectedTemplate = template;
        }
      }
    );
    this.quickApiUuidSubscription =
    this.quickApiService.quickApiUuid$.subscribe(quickApiUuid => {
      if (quickApiUuid) {
        this.quickApiUuid = quickApiUuid;
        // Once setted in this consent clean the subscription value
        this.quickApiService.setQuickApiUuid(null);
      }
    });
  }

  onSubmit() {
    this.rgpdService.postRgpd(this.mapDataRgpd()).subscribe({
      next: () => {
        this.alertService.success(
          this.translate.instant(
            'PRIVATE.PATIENT.GENERATE-DOC.CREATE-RGPD-SUCCESS'
          )
        );
        if (this.roleService.isQuickapi()) {
          const patientName = `${
            this.vars?.filter(variable => variable.name === 'signer_name_1')[0]
              .value
          } ${
            this.vars?.filter(
              variable => variable.name === 'signer_last_name_1'
            )[0].value
          } ${
            this.vars?.filter(
              variable => variable.name === 'signer_last_name_2'
            )[0].value
          }`;
          this.router.navigate(['/portal/quick-api/patients/doc-ok'], {
            queryParams: { document: 'RGPD', patientName: patientName, actionDoc: this.translate.instant('QUICKAPI.QUERY-PARMAS-GENERATE') },
          });
        } else {
          this.router.navigate(['../'], { relativeTo: this.activatedRoute });
        }
      },
      error: () => {
        this.alertService.error(
          this.translate.instant(
            'PRIVATE.PATIENT.GENERATE-DOC.CREATE-RGPD-ERROR'
          )
        );
      },
    });
  }

  mapDataRgpd(): RgpdOutputDTO {
    return {
      patient: {
        // Map patient-related values from the form group
        uuid: this.patient?.uuid,
        documentNumber: this.patient?.documentNumber,
        email: this.dataSignatureForm.get('patientEmail')?.value,
        phonePrefix: this.dataSignatureForm.get('patientPrefix')?.value,
        mobile: this.dataSignatureForm.get('patientPhone')?.value,
        representativePatients: [
          {
            documentNumber: this.dataSignatureForm.get('firstRepresentativeDocumentNumber')?.value,
            email: this.dataSignatureForm.get('firstRepresentativeEmail')?.value,
            phonePrefix: this.dataSignatureForm.get('firstRepresentativePrefix')?.value,
            mobile: this.dataSignatureForm.get('firstRepresentativePhone')?.value,
          },
          {
            documentNumber: this.dataSignatureForm.get('secondRepresentativeDocumentNumber')?.value,
            email: this.dataSignatureForm.get('secondRepresentativeEmail')?.value,
            phonePrefix: this.dataSignatureForm.get('secondRepresentativePrefix')?.value,
            mobile: this.dataSignatureForm.get('secondRepresentativePhone')?.value,
          }
        ]
      },
      templateUuid: this.selectedTemplate?.uuid ?? undefined,
      templateType: this.selectedTemplate?.type ?? undefined,
      variables: this.constructVariables(this.vars ?? []),
      device: this.dataSignatureForm.get('device')?.value ?? undefined,
      quickApiUuid: this.quickApiUuid ?? undefined,
      outputTypeId:
        this.dataSignatureForm.get('outputTypeId')?.value ?? undefined,
    };
  }

  constructVariables(variables: Variable[]): VarOutputDTO[] {
    return variables.map(variable => {
      const { uuid, value } = variable;
      return { uuid, value };
    });
  }

  changeTemplateLanguage(locale: string) {
    this.getTemplateList(locale);
  }

  changeSelectedTemplate(templateUUid: string | null) {
    this.templateService.setSelectedTemplateUuid(templateUUid);
  }

  getTemplateList(locale: string): void {
    this.templateService.getRgpdTemplates(locale).subscribe({
      next: templates => {
        if (templates) {
          this.templates = templates;
          // The selected template order is CompanyLocale -> CompanyTemplate -> ElciDefaultTemplate
          if (templates.length > 1) {
            this.selectedTemplateUuid =
              templates.filter(
                template => template.type !== DEFAULT_TEMPLATE_RGPD
              )[0].uuid ?? null;
          } else {
            this.selectedTemplateUuid = templates[0].uuid ?? null;
          }
          this.changeSelectedTemplate(this.selectedTemplateUuid);

          // Translate the DEFAULT_RGPD value
          this.templates.forEach((item, index) => {
            if (item.type === DEFAULT_TEMPLATE_RGPD) {
              this.translate.get(item.type).subscribe((res: string) => {
                if (this.templates?.[index]) {
                  this.templates[index].type = res;
                }
              });
            }
          });
        }
      },
    });
  }

  getPatientByUuid(patientUuid: string) {
    this.patientService.getPatientByUuid(patientUuid).subscribe({
      next: patient => {
        if (patient) {
          this.patient = patient
        }
      },
    });
  }

  getLanguages(): void {
    this.languageService.getRgpdTemplateLanguagesApi().subscribe({
      next: languages => {
        if (languages && languages.length > 0) {
          this.languageService.setRgpdTemplateLanguages(languages);
          this.languages = languages;

          // Find user's language in the list, or use the first one from the list
          const selectedLanguageObj = languages.find(lang => lang?.locale?.replace("_", "-") === this.translateService.currentLang()) ?? languages[0];

          // Extract the locale (string) from the selected language object
          const selectedLanguageLocale = selectedLanguageObj ? selectedLanguageObj.locale : DEFAULT_ELCI_LANGUAGE;

          // Check if selectedLanguageLocale is set, otherwise use DEFAULT_ELCI_LANGUAGE
          this.selectedTemplateLanguage = selectedLanguageLocale ?? DEFAULT_ELCI_LANGUAGE;

        } else {
          // If no languages are returned from the backend, use DEFAULT_ELCI_LANGUAGE
          this.selectedTemplateLanguage = DEFAULT_ELCI_LANGUAGE;
        }
        this.getTemplateList(this.selectedTemplateLanguage);
      },
    });
  }




  ngOnDestroy(): void {
    if (this.varSubscription) {
      this.varSubscription.unsubscribe();
    }
    if (this.templateSubscription) {
      this.templateSubscription.unsubscribe();
    }
    if (this.quickApiUuidSubscription) {
      // Set the uuid to null
      this.quickApiService.setQuickApiUuid(null);
      this.quickApiUuidSubscription.unsubscribe();
    }
  }
}
