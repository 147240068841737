import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APP_LANGUAGES, CI_LANGUAGES, RGPD_LANGUAGES } from '@app/core/constants/Constants';
import { Language } from '@app/core/models/input/language.model';
import { EnvironmentService } from '@app/environment.service';
import { CacheImplService } from '@app/shared/services/cache/cache-impl.service';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor(
    private readonly http: HttpClient,
    private readonly env: EnvironmentService,
    private readonly cacheService: CacheImplService,
  ) { }

  private _languages?: Language[];

  // Save the value in the service and in the local storage
  public setLanguages(languages: Language[]) {
    this.cacheService.setItemNotEncrypted(APP_LANGUAGES ,JSON.stringify(languages))
    this._languages = languages;
  }

  // Get the values firstly try in the variable and if is null search it in the session storage
  private getLanguages() {
    if (!this._languages || this._languages.length === 0) {
      this._languages = JSON.parse(this.cacheService.getItemNotEncrypted(APP_LANGUAGES) ??  "[]");
      return this._languages ?? [];
    }
    return this._languages;
  }

  getLanguagesApi(): Observable<Language[]> {
    if (!this.getLanguages() || this.getLanguages().length === 0) {
      return this.http.get<Language[]>(`${this.env.apiUrl}/languages`);
    } else {
      return of(this.getLanguages())
    }
  }

  // Ci template languages
  private _ciTemplateLanguages?: Language[];

  // Save the value in the service and in the local storage
  public setCiTemplateLanguages(languages: Language[]) {
    this.cacheService.setItemNotEncrypted(CI_LANGUAGES ,JSON.stringify(languages))
    this._ciTemplateLanguages = languages;
  }

  // Get the values firstly try in the variable and if is null search it in the session storage
  private getCiTemplateLanguages() {
    if (!this._ciTemplateLanguages || this._ciTemplateLanguages.length === 0) {
      this._ciTemplateLanguages = JSON.parse(this.cacheService.getItemNotEncrypted(CI_LANGUAGES) ??  "[]");
      return this._ciTemplateLanguages ?? [];
    }
    return this._ciTemplateLanguages;
  }

  getCiTemplateLanguagesApi(): Observable<Language[]> {
    if (!this.getCiTemplateLanguages() || this.getCiTemplateLanguages().length === 0) {
      return this.http.get<Language[]>(`${this.env.apiUrl}/templates/consent/languages`);
    } else {
      return of(this.getCiTemplateLanguages())
    }
  }

    // Ci template languages
    private _rgpdTemplateLanguages?: Language[];

    // Save the value in the service and in the local storage
    public setRgpdTemplateLanguages(languages: Language[]) {
      this.cacheService.setItemNotEncrypted(RGPD_LANGUAGES ,JSON.stringify(languages))
      this._rgpdTemplateLanguages = languages;
    }

    // Get the values firstly try in the variable and if is null search it in the session storage
    private getRgpdTemplateLanguages() {
      if (!this._rgpdTemplateLanguages || this._rgpdTemplateLanguages.length === 0) {
        this._rgpdTemplateLanguages = JSON.parse(this.cacheService.getItemNotEncrypted(RGPD_LANGUAGES) ??  "[]");
        return this._rgpdTemplateLanguages ?? [];
      }
      return this._rgpdTemplateLanguages;
    }

    getRgpdTemplateLanguagesApi(): Observable<Language[]> {
      if (!this.getRgpdTemplateLanguages() || this.getRgpdTemplateLanguages().length === 0) {
        return this.http.get<Language[]>(`${this.env.apiUrl}/templates/rgpd/languages`);
      } else {
        return of(this.getRgpdTemplateLanguages())
      }
    }
}
