import { Pipe, PipeTransform } from '@angular/core';
import { PATTERN_DATE_MINUTES } from '@app/core/constants/Constants';
import { User } from '@app/core/models/input/user.model';
import { LoginService } from '@app/core/services/login/login.service';
import { EnvironmentService } from '@app/environment.service';
import { DateTime } from 'luxon';
import { TranslateCustomService } from '../../../shared/services/translate/translate.service';

@Pipe({
  name: 'UTCToLocal',
})
export class UTCToLocalPipe implements PipeTransform {
  // Constructor with injected services: EnvironmentService and CacheService
  constructor(
    private readonly envs: EnvironmentService,
    private readonly translateCustomService: TranslateCustomService,
    private readonly loginService: LoginService,
  ) {
    this.currentLang();
  }

  // Private member variable to hold the default language setting
  private lang: string = this.envs.defaultLanguage;

  // Implementing the transform method from PipeTransform
  transform(value: string | undefined, format = DateTime.DATETIME_SHORT_WITH_SECONDS): string {
    if (!value) {
      return ''; // Return an empty string if the input value is null or undefined
    }

    // Parse the input date string as a DateTime object in UTC using a predefined format
    const dateTime = DateTime.fromFormat(value, PATTERN_DATE_MINUTES, {
      zone: 'utc',
    }); // Example format: "2023-12-15 07:25:42"

    const user = this.loginService.userValue ?? new User();

    // Convert the DateTime object to local time based on the current language setting and return it in a short format
    return dateTime
      .setZone(user.language?.timeZone ?? "Europe/Madrid")
      .toLocaleString(format);
  }

  // Method to update the current language setting based on an observable value from cacheService
  public currentLang() {
    this.lang = this.translateCustomService.currentLang();
  }
}
