<div class="wrapper">
    <div class="cabecera">
        <img class="movil" src="../../../assets/img/catalogo/cabecera-movil.jpg" alt="Catálogo de el Consentimiento">
        <img class="desktop" src="../../../assets/img/catalogo/cabecera.jpg" alt="Catálogo de el Consentimiento">
        <h1 class="titulo_seccion tipo-second-color bold">Catálogo</h1>
    </div>
    <div class="maxwidth">
        <div class="content">
            <h4 class="procedure_name tipo-main-color">{{procedureContent?.title}}</h4>
            <p class="especiality tipo-second-color bold">
                {{ specialitiesNames  }}
              </p>
            <div class="content" [innerHTML]="clause?.[0]?.text">               
            </div>
            <a href="/contacto"><p class="tipo-second-color bold seguir">...Seguir leyendo</p></a>
            <div class="aviso back-light-blue-color">
                <p>Tenemos una base de datos de <strong>Consentimientos Informados</strong> en constante actualización por nuestro <strong>equipo de peritos-médicos y juristas.</strong> Si quiere acceder al catálogo completo de su especialidad, pídanos <a href="/contacto" class="tipo-second-color bold">información</a> sin compromiso.</p>
            </div>  
        </div> 
           
        <div class="buttons">
            <button class="button back-blue-color tipo-white-color"  (click)="goBack()">Volver al listado</button>
        </div>
    </div>
    <div class="pie">
        <img class="movil" src="../../../assets/img/catalogo/footer-movil.jpg" alt="Catálogo de el Consentimiento">
        <img class="desktop" src="../../../assets/img/catalogo/footer.jpg" alt="Catálogo de el Consentimiento">
    </div>
</div>   
