import { Injectable } from '@angular/core';
import { LoginService } from '../login/login.service';
import { User } from '@app/core/models/input/user.model';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RoleService { 

  constructor(private readonly loginService: LoginService,
    private readonly router: Router) { }

  hasRole(roles: number[]): boolean {
    const user = this.loginService.userValue;
    if (user) {
      // check if route is restricted by role
      const actualUserRoleIds = user.roles
        ?.map(role => role.id ?? null)
        .filter(id => id !== null) as number[]; // as number[] forces Typescript to treat it as number array, since we've already eliminated the possibility of undefined or null values
      if (
        roles &&
        actualUserRoleIds &&
        !actualUserRoleIds.some(roleId => roles.includes(roleId))
      ) {
        // If user not has the role to access this
        return false;
      }
      // If user has the role
      return true;
    }
    // If no user
    return false;
  }

  userHasRole(userRoles: User, roles: number[]): boolean {
   
    if (userRoles) {
      // check if route is restricted by role
      const actualUserRoleIds = userRoles.roles
        ?.map(role => role.id ?? null)
        .filter(id => id !== null) as number[]; // as number[] forces Typescript to treat it as number array, since we've already eliminated the possibility of undefined or null values
      if (
        roles &&
        actualUserRoleIds &&
        !actualUserRoleIds.some(roleId => roles.includes(roleId))

      ) {
        // If user not has the role to access this
        return false;
      }
      // If user has the role
      return true;
    }
    // If no user
    return false;
  }

  isQuickapi():boolean  {       
    return this.router.url.includes('quick-api');     
   
  }

}
