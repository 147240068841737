import { Component } from '@angular/core';
import { SPECIALITY_CLASSES } from '@app/core/constants/Constants';
import { SpecialitiesStatsDTO } from '@app/core/models/front/public';

@Component({
  selector: 'app-catalogo',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss'],
})
export class CatalogoComponent {
  specialityName?: string;
  specialities?: SpecialitiesStatsDTO;

  handleChildSpecialities(specialities: SpecialitiesStatsDTO) {
    this.specialities = specialities;
  }

  putClassBySpecialityId(id?: number): string {
    if (id == null) {
      return 'one';
    }
  
    for (const [className, idSet] of Object.entries(SPECIALITY_CLASSES)) {
      if (idSet.has(id)) {
        return className;
      }
    }
  
    return 'four';
  }
}
