import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ProceduresAndSpecialty, SpecialitiesStatsDTO, SpecialityProcedurePublicOutputDTO } from '@app/core/models/front/public';
import { AlertService } from '@app/core/services/alert/alert.service';
import { PublicService } from '@app/core/services/public/public.service';
import { CatalogueSearchService } from '@app/core/services/share-search/catalogue-search.service';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  faMagnifyingGlass = faMagnifyingGlass;

  specialities?: SpecialitiesStatsDTO;
  proceduresBySpecialty?: SpecialityProcedurePublicOutputDTO[];
  
  @Output() specialitiesEvent = new EventEmitter<SpecialitiesStatsDTO>();
  @Output() proceduresEvent = new EventEmitter<ProceduresAndSpecialty[]>();

   dataSearch!: FormGroup;
   specialityId = 0;
   procedureName = '';

   constructor(
    private readonly publicService: PublicService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly alertService: AlertService,
    private readonly shareSearch: CatalogueSearchService
  ) { 
    this.activatedRoute.params.subscribe(params => {
      if (params['procedureName']) {
        this.procedureName = params['procedureName'];
      }
      if (params['id']) {
        this.specialityId = params['id'];
      }
      if (this.procedureName || this.specialityId) {
        this.searchProceduresBySpecialty();
      }
    });
  }

  ngOnInit(): void {   
    this.dataSearch = new FormGroup({      
      specialityName: new FormControl({ value: this.specialityId, disabled: false }),
      procedureName: new FormControl({ value: this.procedureName, disabled: false })    
    });
    this.getSpecialties();
  }

  getSpecialties() {
    this.publicService.getTotalProceduresBySpecialties().subscribe({
      next: respuesta => {
        this.specialities = respuesta; 
        this.specialitiesEvent.emit(this.specialities)       
      },
    });
  }

  getSearch(){
    if(this.dataSearch.get('procedureName')?.value != '' || this.dataSearch.get('specialityName')?.value != 0 ){
      this.specialityId = this.dataSearch.get('specialityName')?.value;
      this.procedureName = this.dataSearch.get('procedureName')?.value;     
      this.searchProceduresBySpecialty();     
      this.router.navigate(['/catalogo/'+ this.specialityId +'/procedure-name/'+ this.procedureName], {relativeTo: this.activatedRoute});
          
    }else {
      this.alertService.error('Debe elegir al menos una especialidad o filtrar por nombre con al menos 3 caracteres');
    }
  }

  searchProceduresBySpecialty() {
    this.publicService.getProceduresListBySpecialtySearch(this.procedureName, this.specialityId == 0 ? undefined : this.specialityId).subscribe({
      next: proceduresBySpecialty => {
        this.proceduresBySpecialty = proceduresBySpecialty;       
        this.shareSearch.updateProcedures(this.proceduresBySpecialty)   
      }
    })
  }
}
