import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'localeToCountrySuffix'
})
export class LocaleToCountrySuffixPipe implements PipeTransform {

  constructor(private readonly sanitizer: DomSanitizer) {}

  transform(locale: string | undefined): SafeHtml {
    if (!locale || locale === 'es' || locale === 'es_ES') {
      return '';
    }
    const parts = locale.split('_');
    const countryCode = parts.length > 1 ? parts[1].toLowerCase() : '';

    if (countryCode) {
      const flagHtml = `<span class="flag-icon flag-icon-${countryCode}"></span>`;
      return this.sanitizer.bypassSecurityTrustHtml(flagHtml);
    }
    return '';
  }
}
