<div class="wrapper">    
    <div class="list">
      <h4 class="title tipo-second-color bold" id="title_table">
        {{ 'SUPER-ADMIN.LIST-SPECIALTIES.TITLE-LIST-SPECIALTIES' | translate }}
      </h4>
      <table class="table" aria-describedby="title_table">
        <thead class="tipo-second-color bold">
          <tr>
            <th scope="col" class="id">{{ 'SUPER-ADMIN.LIST-COMPANY.ID' | translate }}</th>
            <th scope="col" class="specialty_title">
              <span>{{ 'SUPER-ADMIN.LIST-CONSENTS-ADMIN.DATA-SPECIALTY' | translate }}</span>
            </th>                
            <th scope="col" class="state">
              <span>{{ 'PRIVATE.CONSENTS.LIST-CONSENTS.LABEL-STATE' | translate }}</span>
            </th>
            <th scope="col" class="state">
              <span>{{'PRIVATE.CONSENTS.LIST-CONSENTS.LABEL-ACTION' | translate}}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let specialty of specialties" class="clickable-row">
            <td class="id">{{ specialty.id }}</td>
            <td class="specialty_name">{{ specialty.name }}</td>              
            <td class="state">
              <span class="green bold" *ngIf="specialty.active">{{ 'SUPER-ADMIN.LIST-SPECIALTIES.ACTIVE' | translate }}</span>
              <span class="red bold" *ngIf="!specialty.active">{{ 'SUPER-ADMIN.LIST-SPECIALTIES.INACTIVE' | translate }}</span>
            </td> 
            <td>
              <button class="button tipo-second-color" (click)="onSpecialtyClick(specialty.id ?? 0)" >
                <fa-icon [icon]="faFileCirclePlus" class="icono iconono"></fa-icon>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>    
  
    <app-modal [showModal]="showModal" (closeModal)="onCloseModal()">
      <app-gen-consents-per-specialty [specialtyId]="selectedSpecialtyId"></app-gen-consents-per-specialty>
    </app-modal>
  </div>
  