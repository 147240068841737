import { Component, OnInit } from '@angular/core';
import { Filter } from '@app/core/models/front/filters';
import { SignedDocumentsByCompany } from '@app/core/models/input/statistics/documents-generated.model';
import { StatisticsService } from '@app/core/services/statistics/statistics.service';
import { FilterUtils } from '@app/core/utils/filter.utils';

@Component({
  selector: 'app-total-signs-by-company',
  templateUrl: './total-signs-by-company.component.html',
  styleUrls: ['./total-signs-by-company.component.scss'],
})
export class TotalSignsByCompanyComponent implements OnInit {
  filters = new Array<Filter>();
  signatures?: SignedDocumentsByCompany;

  // Utils
  filterUtils: FilterUtils;

  nameFilter = new Filter();
  nameFilterStr?: string;

  constructor(private readonly statisticsService: StatisticsService) {
    this.filterUtils = new FilterUtils(this.filters);
  }

  ngOnInit(): void {
    this.getSignaturesByCompany();
  }

  addFilter(name: string, filter: string) {
    if (name === 'signatureDate-start' || name === 'signatureDate-end') {
      filter = this.convertDateFormat(filter);
    }
    this.filterUtils.addFilter(name, filter);
    this.getSignaturesByCompany();
  }

  getSignaturesByCompany() {
    this.statisticsService.getSignaturesByCompany(this.filters).subscribe({
      next: respuesta => {
        this.signatures = respuesta;
      },
    });
  }

  convertDateFormat(dateString: string): string {
    const [day, month, year] = dateString.split('/');
    return `${year}-${month}-${day}`;
  }
}
