import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import OutputTypeEnum from '@app/core/enums/output-type.enum';
import SubscriptionTypeEnum from '@app/core/enums/subscription-type.enum';
import { SubscriptionTypes } from '@app/core/models/input/company/company-parameters.model';
import { Device } from '@app/core/models/input/device.model';
import { Patient, PatientFromForm } from '@app/core/models/input/patient/patient.model';
import { PhonePrefix } from '@app/core/models/input/phone-prefix/phone-prefix.model';
import { User } from '@app/core/models/input/user.model';
import { LoginService } from '@app/core/services/login/login.service';
import { PhonePrefixService } from '@app/core/services/phone-prefix/phone-prefix.service';
import PatientUtils from '@app/core/utils/patient.utils';
import { ElciValidators } from '@app/core/utils/validators';
import { faEnvelopeCircleCheck, faPaperPlane, faTabletScreenButton } from '@fortawesome/free-solid-svg-icons';
import { Subscription, catchError, of } from 'rxjs';
import { DeviceService } from '../../../../core/services/device/device.service';
import { SubmitButtonService } from '../../../../core/services/submit-button/submit-button.service';

@Component({
  selector: 'app-generate-doc',
  templateUrl: './generate-doc.component.html',
  styleUrls: ['./generate-doc.component.scss']
})
export class GenerateDocComponent implements OnInit, OnChanges, OnDestroy {
  faEnvelopeCircleCheck = faEnvelopeCircleCheck;
  faPaperPlane = faPaperPlane;
  faTabletScreenButton = faTabletScreenButton;

  @Output() formGroupEvent = new EventEmitter<FormGroup>();
  @Output() selectedOutputTypeChange = new EventEmitter<number>();
  @Output() selectedDevice = new EventEmitter<string>();
  @Input() isPatientCreated = true;
  @Input() patient?: Patient;
  @Input() patientFromForm?: PatientFromForm;
  @Input() isCheckedLegal1?:boolean;
  @Input() isCheckedLegal2?:boolean;


  // Html used variables
  outputTypeEnum = OutputTypeEnum
  subscriptionTypeEnum = SubscriptionTypeEnum;
  selectedOutputType = OutputTypeEnum.DIGITAL_VID; 
  selectedSubscriptionType = SubscriptionTypeEnum.BIOMETRIC;
  user?: User;
  devices?: Device[];
  dataSignatureForm!: FormGroup;
 
  isOnSubmit = false;
  phonePrefixes?: Array<PhonePrefix> = [];

  submitButtonSubscription?: Subscription;
  isRemote = false;
  isBiometric = true;

  constructor(
    private readonly loginService: LoginService,
    private readonly deviceService: DeviceService,
    private readonly phonePrefixService: PhonePrefixService,
    private readonly submitButtonService: SubmitButtonService,
    ) {
      this.dataSignatureForm = this.initForm();
    }

  ngOnInit(): void {
    this.getServiceSubscriptions();
    // Save the session user into a class variable.
    this.user = this.loginService.userValue ?? new User(); 
     // Comprobar el tipo de suscripción y si no es remoto, deshabilitar botón
     const subscriptionType: SubscriptionTypes[]  = this.user.subscription ?? [];  
     this.isRemote = subscriptionType.some(subscription => subscription.id === SubscriptionTypeEnum.REMOTE);     
     
        
    this.getDevices();
     //Consulta de los prefijos de país
     this.getPhonePrefix().subscribe({
      next: data => {
        if (Array.isArray(data)) {
          this.phonePrefixes = data;
        }
      },
    });
    this.selectedOutputTypeChange.emit(this.selectedOutputType);   
  
    this.validarDigitalVID();
    this.validateRemote();       
  }

  emitSelectedDevice() {
    this.selectedDevice.emit(this.dataSignatureForm.get("device")?.value);  
  }  

  ngOnChanges() {  
    if (this.patientFromForm) {
      this.fillDataPatientRemoteNew(this.patientFromForm);
    }
    if(this.patient) {
      this.fillDataPatientRemote(this.patient);
    }
    this.validateRemote();
  }


  getPhonePrefix() {
    return this.phonePrefixService.getPhonePrefixApi().pipe(
      catchError(() => {
        console.error(`Error fetching prefix`);
        return of(new PhonePrefix());
      })
    );
  }

  emitForm() {
    // Emit the entire FormGroup object
    this.formGroupEvent.emit(this.dataSignatureForm);

  }

 

  // Submit con paciente que ya existe
  onSubmit() {    
    this.isOnSubmit = true;
    if (this.dataSignatureForm.valid) {
      this.formGroupEvent.emit(this.dataSignatureForm);     
    }   
  }

  protected changeSubscriptionType(newValue: number, execute: boolean) {
    if(execute){
    this.selectedOutputType = newValue;     
    this.dataSignatureForm.controls['outputTypeId'].setValue(this.selectedOutputType);  
      this.validarDigitalVID();
    this.validateRemote();
    }
  }

  private validarDigitalVID() {    
    if (OutputTypeEnum.DIGITAL_VID === this.selectedOutputType) {
      this.dataSignatureForm.controls['device'].enable();
      this.dataSignatureForm.controls['device'].setValue(this.user?.userDevice?.description);
      this.selectedDevice.emit(this.dataSignatureForm.get("device")?.value)
      this.dataSignatureForm.controls['outputTypeId'].setValue(this.selectedOutputType); 
            
    } else {
      // Disable the device required if is not digital sign
      this.dataSignatureForm.controls['device'].disable();
    }
    this.selectedOutputTypeChange.emit(this.selectedOutputType);
  }

  validateRemote() { 
    if (OutputTypeEnum.DIGITAL_REMOTE === this.selectedOutputType && this.patient) {    
      
        this.dataSignatureForm.controls['patientEmail'].enable();
        this.dataSignatureForm.controls['patientPrefix'].enable();
        this.dataSignatureForm.controls['patientPhone'].enable();   

      if (this.numberOfRepresentatives() === 1 || this.numberOfRepresentatives() === 2) {
        this.dataSignatureForm.controls['patientEmail'].disable();
        this.dataSignatureForm.controls['patientPrefix'].disable();
        this.dataSignatureForm.controls['patientPhone'].disable();

        this.dataSignatureForm.controls['firstRepresentativeEmail'].enable();
        this.dataSignatureForm.controls['firstRepresentativePrefix'].enable();
        this.dataSignatureForm.controls['firstRepresentativePhone'].enable();

        this.dataSignatureForm.controls['secondRepresentativeEmail'].enable();
        this.dataSignatureForm.controls['secondRepresentativePrefix'].enable();
        this.dataSignatureForm.controls['secondRepresentativePhone'].enable();     
       
      } else {
        this.dataSignatureForm.controls['firstRepresentativeEmail'].disable();
        this.dataSignatureForm.controls['firstRepresentativePrefix'].disable();
        this.dataSignatureForm.controls['firstRepresentativePhone'].disable();

        this.dataSignatureForm.controls['secondRepresentativeEmail'].disable();
        this.dataSignatureForm.controls['secondRepresentativePrefix'].disable();
        this.dataSignatureForm.controls['secondRepresentativePhone'].disable();
      }
    
  }  else {
      // Disable the device required if is not digital sign
      this.dataSignatureForm.controls['patientEmail'].disable();
      this.dataSignatureForm.controls['patientPrefix'].disable();
      this.dataSignatureForm.controls['patientPhone'].disable();
      this.dataSignatureForm.controls['firstRepresentativeEmail'].disable();
      this.dataSignatureForm.controls['firstRepresentativePrefix'].disable();
      this.dataSignatureForm.controls['firstRepresentativePhone'].disable();
      this.dataSignatureForm.controls['secondRepresentativeEmail'].disable();
      this.dataSignatureForm.controls['secondRepresentativePrefix'].disable();
      this.dataSignatureForm.controls['secondRepresentativePhone'].disable();
    }
  }

  protected numberOfRepresentatives(): number {
    if (this.patient) {
      return PatientUtils.numberOfRepresentativesActive(this.patient);
    }
    return 0;
  }

  // función que valida cada dato del formulario
  shouldShowError(inputControlName: string): boolean {
    const control = this.dataSignatureForm.get(inputControlName);
    return !!(this.isOnSubmit && control?.invalid);
   
  }

  getDevices() {
    this.deviceService
    .getUserDevices()
    .subscribe({
      next: devices => {
        this.deviceService.setDevices(devices)
        this.devices = devices;
        this.dataSignatureForm.get("device")?.setValue(this.user?.userDevice?.description ?? '0');
      
      },
    });
  }

  fillDataPatientRemote(patient: Patient) {
    this.dataSignatureForm.controls['patientEmail'].setValue(patient?.email);
    this.dataSignatureForm.controls['patientPrefix'].setValue(patient?.phonePrefix === null ? this.user?.defaultPhonePrefix : patient?.phonePrefix);
    this.dataSignatureForm.controls['patientPhone'].setValue(patient?.mobile);

    const firstRepresentative = PatientUtils.firstRepresentative(patient);
    if (firstRepresentative) {
      this.dataSignatureForm.controls['firstRepresentativeEmail'].setValue(firstRepresentative?.email);
      this.dataSignatureForm.controls['firstRepresentativePrefix'].setValue(firstRepresentative?.phonePrefix === null ? this.user?.defaultPhonePrefix : firstRepresentative?.phonePrefix);
      this.dataSignatureForm.controls['firstRepresentativePhone'].setValue(firstRepresentative?.mobile);
      this.dataSignatureForm.controls['firstRepresentativeDocumentNumber'].setValue(firstRepresentative?.documentNumber);
    }

    const secondRepresentative = PatientUtils.secondRepresentative(this.patient);
    if (secondRepresentative) {
      this.dataSignatureForm.controls['secondRepresentativeEmail'].setValue(secondRepresentative?.email);
      this.dataSignatureForm.controls['secondRepresentativePrefix'].setValue(secondRepresentative?.phonePrefix === null ? this.user?.defaultPhonePrefix : secondRepresentative?.phonePrefix);
      this.dataSignatureForm.controls['secondRepresentativePhone'].setValue(secondRepresentative?.mobile);
      this.dataSignatureForm.controls['secondRepresentativeDocumentNumber'].setValue(secondRepresentative?.documentNumber);
    }
  }

  fillDataPatientRemoteNew(patient: PatientFromForm) {
    this.dataSignatureForm.controls['patientEmail'].setValue(patient?.email);
    this.dataSignatureForm.controls['patientPrefix'].setValue(patient?.phonePrefix === null ? this.user?.defaultPhonePrefix : patient?.phonePrefix);
    this.dataSignatureForm.controls['patientPhone'].setValue(patient?.phone);
  
    this.dataSignatureForm.controls['firstRepresentativeEmail'].setValue(patient?.email1);
    this.dataSignatureForm.controls['firstRepresentativePrefix'].setValue(patient?.phonePrefix1 === null ? this.user?.defaultPhonePrefix : patient?.phonePrefix1);
    this.dataSignatureForm.controls['firstRepresentativePhone'].setValue(patient?.phone1);
    this.dataSignatureForm.controls['firstRepresentativeDocumentNumber'].setValue(patient?.nif1);    

    this.dataSignatureForm.controls['secondRepresentativeEmail'].setValue(patient?.email2);
    this.dataSignatureForm.controls['secondRepresentativePrefix'].setValue(patient?.phonePrefix2 === null ? this.user?.defaultPhonePrefix : patient?.phonePrefix2);
    this.dataSignatureForm.controls['secondRepresentativePhone'].setValue(patient?.phone2);
    this.dataSignatureForm.controls['secondRepresentativeDocumentNumber'].setValue(patient?.nif2);
   
  }

  private initForm(): FormGroup {
    // Validamos e inicializamos formulario
    return new FormGroup({
      device: new FormControl({ value: this.user?.userDevice?.description, disabled: false }, [Validators.required, ElciValidators.notEqualToZero]),
      outputTypeId: new FormControl({ value:'', disabled: false }, [Validators.required]),
      patientEmail: new FormControl({ value:'', disabled: false }, [
        Validators.required,
        ElciValidators.emailValidator,
      ]),
      patientPrefix: new FormControl({ value:'', disabled:false}),
      patientPhone: new FormControl({ value:'', disabled: false }),
      firstRepresentativeEmail: new FormControl({ value: '', disabled: false }, [
        ElciValidators.emailValidator
      ]),
      firstRepresentativeDocumentNumber: new FormControl({ value: '', disabled: false }),
      firstRepresentativePrefix: new FormControl({ value:'', disabled:false}),
      firstRepresentativePhone: new FormControl({ value: '', disabled: false }),
      secondRepresentativeEmail: new FormControl({ value: '', disabled: false }, [
        ElciValidators.emailValidator       
      ]),
      secondRepresentativePrefix: new FormControl({ value:'', disabled:false}),
      secondRepresentativePhone: new FormControl({ value: '', disabled: false }),
      secondRepresentativeDocumentNumber: new FormControl({ value: '', disabled: false }),
    });
  }

  getServiceSubscriptions(): void {
    this.submitButtonSubscription =
      this.submitButtonService.generateDocIsOnSubmit$.subscribe(isOnSubmit => {
        if (isOnSubmit != null) {
          this.isOnSubmit = isOnSubmit;
        }
      });
  }


  ngOnDestroy(): void {
    if (this.submitButtonSubscription) {
      this.submitButtonSubscription.unsubscribe();
    }
  }
}
