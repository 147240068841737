import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DEFAULT_ELCI_LANGUAGE } from '@app/core/constants/Constants';
import { COMPANY_ROLES, DOCTOR_ROLES, GENERATE_CIS, SUPERVISOR, VIEW_CIS } from '@app/core/constants/Permissions';
import OutputTypeEnum from '@app/core/enums/output-type.enum';
import StatusEnum from '@app/core/enums/status.enum';
import { Consent } from '@app/core/models/input/consent/consent.model';
import { User } from '@app/core/models/input/user.model';
import { LoginService } from '@app/core/services/login/login.service';
import { RoleService } from '@app/core/services/role/role.service';
import { faBackward, faMaximize, faMinimize } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { ConsentService } from '../../../../core/services/consent/consent.service';
import { ConsentsShareService } from '../../services/consents-share.service';

@Component({
  selector: 'app-edit-consent',
  templateUrl: './edit-consent.component.html',
  styleUrls: ['./edit-consent.component.scss'],
})
export class EditConsentComponent implements OnInit, OnDestroy {
  faMaximize = faMaximize;
  faBackward = faBackward;
  faMinimize = faMinimize;

  isMax = false;
  consent?: Consent;
  user = new User();
  canGenerateCis = false;
  isDoctor = false;
  StatusEnum = StatusEnum;
  OutputEnum = OutputTypeEnum;
  patientName?: string;
  patientFirstName?: string;
  patientSecondName?: string;

  isApp = true;
  isQuickapi = false;

  GENERATE_CIS = GENERATE_CIS;
  COMPANY_ROLES = COMPANY_ROLES;
  DOCTOR_ROLES = DOCTOR_ROLES;
  SUPERVISOR = SUPERVISOR;

  private selectedTemplateLanguageSubscription?: Subscription;
  selectedTemplateLanguage = DEFAULT_ELCI_LANGUAGE;

  constructor(
    public roleService: RoleService,
    private readonly route: ActivatedRoute,
    private readonly consentService: ConsentService,
    private readonly consentsShareService: ConsentsShareService,
    private readonly loginService: LoginService
  ) {}

  ngOnInit() {
    this.handleSelectedTemplateLanguageChange();    

    if (this.roleService.isQuickapi()) {
      this.isApp = false;
      this.isQuickapi = true;
    }

    this.consentService.getConsent(this.route.snapshot.paramMap.get('consentUuid') as string).subscribe({
      next: consent => {
        this.consent = consent;        
        this.consentService.setConsent(consent);
        this.user = this.loginService.userValue ?? new User();      
        this.canGenerateCis = this.roleService.hasRole(VIEW_CIS) ;

        this.isDoctor = this.roleService.hasRole(DOCTOR_ROLES) && this.consent?.doctor?.uuid === this.user.uuid;
        this.patientName = this.consent?.patient?.name;
        this.patientFirstName = this.consent?.patient?.firstLastName;
        this.patientSecondName = this.consent?.patient?.secondLastName;      
      },
    });
  }

  handleSelectedTemplateLanguageChange(): void {
    this.selectedTemplateLanguageSubscription = this.consentsShareService.templateLanguage$.subscribe(locale => {
      if (locale && this.selectedTemplateLanguage !== locale && locale != '') {
        this.selectedTemplateLanguage = locale;
      }
    });
  }

  maximizeTemplate() {
    this.isMax = !this.isMax;
  }

  ngOnDestroy(): void {
    if (this.selectedTemplateLanguageSubscription) {
      this.selectedTemplateLanguageSubscription.unsubscribe();
    }
  }
}
