import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { COMPANY_ROLES, SUPERVISOR } from '@app/core/constants/Permissions';
import OutputTypeEnum from '@app/core/enums/output-type.enum';
import StatusEnum from '@app/core/enums/status.enum';
import { Consent } from '@app/core/models/input/consent/consent.model';
import { User } from '@app/core/models/input/user.model';
import { FileService } from '@app/core/services/file/file.service';
import { LoginService } from '@app/core/services/login/login.service';
import { RoleService } from '@app/core/services/role/role.service';
import DownloadUtils from '@app/core/utils/download.utils';
import { faEye, faReceipt, faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-detail-consent',
  templateUrl: './detail-consent.component.html',
  styleUrls: ['./detail-consent.component.scss'],
})
export class DetailConsentComponent implements OnInit {
  @Input() consent?: Consent;


  faEye = faEye;
  faReceipt = faReceipt;
  faSpinner = faSpinner;

  downloadingFile = false;
  downloadingReport = false;

  StatusEnum = StatusEnum;
  consentUuid?: string;
  user = new User();
  canDownloadFile = false;
  OutputType = OutputTypeEnum;

  constructor(
    public roleService: RoleService,
    private readonly fileService: FileService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly loginService: LoginService
  ) {
    this.activatedRoute.params.subscribe(param => {
      this.consentUuid = param['consentUuid'];
    });
  }

  ngOnInit(): void {
    this.user = this.loginService.userValue ?? new User();    

    if (this.roleService.hasRole(COMPANY_ROLES) || this.roleService.hasRole(SUPERVISOR)) {
      this.canDownloadFile = true;
    }

  }

  downloadCiFile(uuid: string | undefined): void {
    this.downloadingFile = true;
    if (uuid) {
      this.fileService.getConsentPdf(uuid).subscribe({
        next: dataDocument => {
          DownloadUtils.downloadFO(dataDocument);
          this.downloadingFile = false;
        },
      });
    }
  }

  downloadCiEvidencesFile(uuid: string | undefined): void {
    this.downloadingReport = true;
    if (uuid) {
      this.fileService.getConsentReportPdf(uuid).subscribe({
        next: dataDocument => {
          DownloadUtils.downloadFO(dataDocument);
          this.downloadingReport = false;
        },
      });
    }
  }
}
