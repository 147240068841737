import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ProcedurePublicDTO,
  ProceduresBySpecialty,
  SpecialitiesStatsDTO,
  SpecialityProcedurePublicOutputDTO
} from '@app/core/models/front/public';
import { EnvironmentService } from '@app/environment.service';
import { BehaviorSubject, Observable, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PublicService {
  constructor(
    private readonly http: HttpClient, 
    private readonly env: EnvironmentService) {}

  private readonly specialityStatsSubject: BehaviorSubject<SpecialitiesStatsDTO | null> =
    new BehaviorSubject<SpecialitiesStatsDTO | null>(null);
  specialityStats$: Observable<SpecialitiesStatsDTO | null> = this.specialityStatsSubject.asObservable();

  setSpecialitiesStats(specialitiesStats: SpecialitiesStatsDTO) {
    this.specialityStatsSubject.next(specialitiesStats);
  }

  getTotalProceduresBySpecialties(forceRefresh = false): Observable<SpecialitiesStatsDTO> {
    if (!forceRefresh && this.specialityStatsSubject.value !== null) {
      return of(this.specialityStatsSubject.value);
    }
    return this.http.get<SpecialitiesStatsDTO>(`${this.env.apiConsents}/public/specialties/stats`).pipe(
      tap(specialitiesStats => {
        this.specialityStatsSubject.next(specialitiesStats);
      })
    );
  }

  getProceduresListBySpecialtySearch(
    procedureName?: string,
    specialityId?: number
  ): Observable<SpecialityProcedurePublicOutputDTO[]> {
    let httpParams = new HttpParams();
  
    if (specialityId !== undefined) {
      httpParams = httpParams.set('specialityId', specialityId.toString());
    }
    if (procedureName) {
      httpParams = httpParams.set('procedureName', procedureName);
    }
  
    return this.http.get<ProceduresBySpecialty[]>(
      `${this.env.apiConsents}/public/specialties/search`,
      { params: httpParams }
    );
  }

  getProcedureContent(code: number): Observable<ProcedurePublicDTO> {
    return this.http.get<ProcedurePublicDTO>(`${this.env.apiConsents}/public/procedures/${code}`);
  }

}
