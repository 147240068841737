import { Component, OnInit } from '@angular/core';
import { Filter } from '@app/core/models/front/filters';
import { ApiDocumentsByCompany } from '@app/core/models/input/statistics/documents-generated.model';
import { StatisticsService } from '@app/core/services/statistics/statistics.service';
import { FilterUtils } from '@app/core/utils/filter.utils';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-total-api-documents-by-company',
  templateUrl: './total-api-documents-by-company.component.html',
  styleUrls: ['./total-api-documents-by-company.component.scss']
})
export class TotalApiDocumentsByCompanyComponent implements OnInit {

  
  faCaretDown = faCaretDown;
  faCaretUp = faCaretUp;
  

  filters = new Array<Filter>();  
 

  // Utils
  filterUtils: FilterUtils;

  nameFilter = new Filter();
  nameFilterStr?: string; 

  apiDocuments?: ApiDocumentsByCompany[];


  constructor(private readonly statisticsService: StatisticsService,) 
              {this.filterUtils = new FilterUtils(this.filters);}


  ngOnInit(): void {
    this.getApiDocumentsByCompany();
  }

  addFilter(name: string, filter: string) { 
    if (name === 'creationDate-start' || name === 'creationDate-end') {
      filter = this.convertDateFormat(filter);
    } 
     this.filterUtils.addFilter(name, filter);  
     this.getApiDocumentsByCompany(); 
   }
   removeFilter(name: string) {
    // If we filter the requested page will be 0
   
    this.filterUtils.removeFilter(name);
    this.getApiDocumentsByCompany();
  }

 

   getApiDocumentsByCompany(){
    this.statisticsService.getApiDocumentsByCompany(this.filters).subscribe({
      next: respuesta => {       
        this.apiDocuments = respuesta;
      }
    })    
  }

   convertDateFormat(dateString: string): string {
    const [day, month, year] = dateString.split('/');
    return `${year}-${month}-${day}`;
  }

}
