import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import TokenSubscribeEnum from '@app/core/enums/token-subscribe.enum';
import { User } from '@app/core/models/input/user.model';
import { ChangePasswordOutputDTO } from '@app/core/models/output/change-password-output-DTO';
import { CCGuestChangePasswordModel } from '@app/core/models/output/guest-change-password-DTO';
import { EnvironmentService } from '@app/environment.service';
import { TranslateCustomService } from '@app/shared/services/translate/translate.service';
import jwt_decode from 'jwt-decode';
import { BehaviorSubject, Observable, map, of, switchMap } from 'rxjs';
import { CacheImplService } from '../../../shared/services/cache/cache-impl.service';
import { Token } from '../../models/input/token.model';
import { NotificationService } from '../notification/notification.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private readonly userSubject: BehaviorSubject<User | null>;
  public user: Observable<User | null>;

  constructor(
    public userService: UserService,
    private readonly router: Router,
    private readonly http: HttpClient,
    private readonly env: EnvironmentService,
    private readonly cacheImplService: CacheImplService,
    private readonly translateCustom: TranslateCustomService,
    private readonly notificationService: NotificationService
  ) {
    this.userSubject = new BehaviorSubject(
      JSON.parse((this.cacheImplService.getItem('user') as string) ?? false)
    );
    this.user = this.userSubject.asObservable();
    if (this.userValue?.uuid) {
      // Connect the websocket
      this.notificationService.joinUserQueue(this.userValue.uuid);
    }
  }

  public get userValue() {
    return this.userSubject.value;

  }

  public setUserValue(user: User | null) {
    this.userSubject.next(user);
  }

  login(username: string, password: string, token: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const url = token != '' ? `${this.env.apiHost}/elci-login-ssi` : `${this.env.apiUrl}/login`;
    return this.http
      .post(
        url,
        { username, password, token },
        { headers: headers, observe: 'response' }
      )
      .pipe(
        switchMap(response => {
          const token: string = response.headers.get('authorization') ?? '';
          const onlyToken = token != '' ? token.split(/(\s+)/)[2] : '';
          this.cacheImplService.setItem('token', onlyToken);
          const tokenDecode: Token = jwt_decode(onlyToken);
          if(tokenDecode.authorities?.includes(TokenSubscribeEnum.ROLE_ACTIVATE)){
            return of(tokenDecode);
          }
          if (tokenDecode.authorities?.includes(TokenSubscribeEnum.ROLE_USER_DISABLED)) {
            return of(tokenDecode);
          }

          return this.userService.getActualUserInfo().pipe(
            map(user => {

              // Set the user in local storage
              this.cacheImplService.setItem('user', JSON.stringify(user));
              // The replace is because the backend uses the locale like this "es_ES" and the front "es-ES"
              const translatedToAngularLocale = user.language?.locale?.replace("_", "-") ?? this.env.defaultLanguage;
              // Change the language of the app for the user language
              this.translateCustom.changeLang(translatedToAngularLocale)
              // Emit the user to the subscribers
              this.userSubject.next(user);

              if (user?.uuid) {
                // Connect the websocket
                this.notificationService.joinUserQueue(user.uuid);
              }
              return response; // Devolvemos la respuesta original para mantener el flujo de datos
            })
          );
        })
      );
  }

  loginAdmin(username: string, password: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http
      .post(
        `${this.env.apiHost}/loginelcisudo`,
        { username, password },
        { headers: headers, observe: 'response' }
      )
      .pipe(
        switchMap(response => {
          const token: string = response.headers.get('authorization') ?? '';
          const onlyToken = token != '' ? token.split(/(\s+)/)[2] : '';
          this.cacheImplService.setItem('token', onlyToken);

          return this.userService.getActualSudoInfo().pipe(
            map(user => {

              // Set the user in local storage
              this.cacheImplService.setItem('user', JSON.stringify(user));
              // The replace is because the backend uses the locale like this "es_ES" and the front "es-ES"
              const translatedToAngularLocale = user.language?.locale?.replace("_", "-") ?? this.env.defaultLanguage;
              // Change the language of the app for the user language
              this.translateCustom.changeLang(translatedToAngularLocale)
              // Emit the user to the subscribers
              this.userSubject.next(user);
              return response; // Devolvemos la respuesta original para mantener el flujo de datos
            })
          );
        })
      );
  }

  resetPassword(guestChangePassword: CCGuestChangePasswordModel) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Password-Reset': 'true',
    });
    return this.http
      .post(
        `${this.env.apiUrl}/users/reset-password`,
        `${JSON.stringify(guestChangePassword)}`,
        { headers: headers, observe: 'response' }
      );
  }

  public activateUser(): Observable<HttpResponse<object>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.patch(
      this.env.apiUrl + '/users/activate',
      null,
      { headers: headers, observe: 'response' }
    );
  }



  loginGuest(token: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http
      .post(
        `${this.env.apiHost}/loginguest`,
        `{"token": ${JSON.stringify(token)}}`,
        { headers: headers, observe: 'response' }
      )
      .pipe(
        map(response => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          const token: string = response.headers.get('authorization') ?? '';
          const onlyToken = token != '' ? token.split(/(\s+)/)[2] : ''; // Remove "Bearer" before token
          this.cacheImplService.setItem('token', onlyToken);
          return response;
        })
      );
  }

  public forceChangePassword(
    changePasswordOutputDTO: ChangePasswordOutputDTO
  ): Observable<HttpResponse<object>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this.cacheImplService.getItem('token') ?? '',
    });
    return this.http.put(
      this.env.apiUrl + '/users/reset-password',
      JSON.stringify(changePasswordOutputDTO),
      { headers: headers, observe: 'response' }
    );
  }
  // The exception is handled in the error interceptor

  logout(redirectRoute: string | null) {
    // Clear the session storage
    this.cacheImplService.clear();
    // Set userSubject to null
    this.userSubject.next(null);
    // Redirect to /login

    if (redirectRoute) {
      this.router.navigate(['/' + redirectRoute]);
    }
  }
  
}
