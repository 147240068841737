import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'yesNo',
})
export class YesNoPipe implements PipeTransform {
  constructor(private readonly translate: TranslateService) {}

  transform(value: boolean | undefined): string {
    if (value == undefined || value == null) return '';
    return value
      ? this.translate.instant('WORLDS.YES')
      : this.translate.instant('WORLDS.NO');
  }
}
