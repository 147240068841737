import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Status } from '@app/core/models/input/common/status.model';
import { EnvironmentService } from '@app/environment.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StatusService {
  constructor(private readonly http: HttpClient, private readonly env: EnvironmentService) {}

  getStatuses(): Observable<Status[]> {
    return this.http.get<Status[]>(`${this.env.apiUrl}/status`);
  }
}
