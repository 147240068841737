<div class="wrapper">
    <div class="buttons">
        <button class="button tipo-white-color back-blue-color" [routerLink]="['/super-admin/register/company']">
                <fa-icon [icon]="faHouseMedical" class="icono"></fa-icon>
                {{'SUPER-ADMIN.LIST-COMPANY.BUTTON-NEW-CLIENT' | translate}}
        </button>
    </div>
    <div class="subcompanies" *ngIf="isMotherCompany">
        <h4 class="title tipo-second-color bold" id="title_table">{{'SUPER-ADMIN.LIST-COMPANY.TITLE-SUBCOMPANIES' | translate}} Nombreempresa</h4>
        <app-info-company [isListCompanies]="true"></app-info-company>
    </div>
    <div class="list">
        <h4 class="title tipo-second-color bold">{{'SUPER-ADMIN.LIST-COMPANY.TITLE-LIST-COMPANIES' | translate}}</h4>
        <table class="table" aria-describedby="title_table">
            <thead class="tipo-second-color bold">
                <th scope="col" class="id">{{'SUPER-ADMIN.LIST-COMPANY.ID' | translate}}</th>
                <th scope="col" class="company_name_title">
                    <div class="column">
                        <div class="row">
                            <span>{{'SUPER-ADMIN.LIST-COMPANY.COMPANY-NAME' | translate}}</span>
                            <div class="column arrows">
                                <fa-icon [icon]="faCaretUp" class="icono fa-xl up" (click)="sortCompanies('businessName', 'asc')"></fa-icon>
                                <fa-icon [icon]="faCaretDown" class="icono fa-xl down" (click)="sortCompanies('businessName', 'desc')"></fa-icon>
                            </div>
                        </div>
                        <input type="text" name="filterName" (debounceInput)="addFilter('businessName', filterName.value)" [appDebounceInput]="300" #filterName id="filterName" placeholder="{{'PRIVATE.PATIENT.LIST-PATIENTS.FILTER' | translate }}">
                    </div>
                </th>
                <th scope="col" class="document">
                    <div class="column">
                        <div class="row">
                            <span>{{'SUPER-ADMIN.LIST-COMPANY.DOCUMENT' | translate}}</span>
                            <div class="column arrows">
                                <fa-icon [icon]="faCaretUp" class="icono fa-xl up" (click)="sortCompanies('cif', 'asc')"></fa-icon>
                                <fa-icon [icon]="faCaretDown" class="icono fa-xl down" (click)="sortCompanies('cif', 'desc')"></fa-icon>
                            </div>
                        </div>
                        <input type="text" name="filterDocument" (debounceInput)="addFilter('cif', filterDocument.value)" [appDebounceInput]="300" #filterDocument id="filterDocument" placeholder="{{'PRIVATE.PATIENT.LIST-PATIENTS.FILTER' | translate }}">
                    </div>
                </th>

                <th scope="col" class="subscription"><span>{{'SUPER-ADMIN.LIST-COMPANY.SUBSCRIPTION' | translate}}</span></th>
                <th scope="col" class="type"><span>{{'SUPER-ADMIN.LIST-COMPANY.TYPE' | translate}}</span> </th>
                <th scope="col" class="year_price"><span>{{'SUPER-ADMIN.LIST-COMPANY.PRICE' | translate}}</span></th>
                <th scope="col" class="login"><span>{{'SUPER-ADMIN.LIST-COMPANY.LOGIN' | translate}}</span></th>
                <th scope="col" class="email"><span>{{'SUPER-ADMIN.LIST-COMPANY.EMAIL' | translate}}</span></th>
                <th scope="col" class="date_on">
                    <div class="column">
                        <div class="row">
                            <span>{{ "PRIVATE.PATIENT.LIST-PATIENTS.TITLE-DATE" | translate}}</span>
                            <div class="column arrows">
                                <fa-icon [icon]="faCaretUp" class="icono fa-xl up" (click)="sortCompanies('creationDate', 'asc')"></fa-icon>
                                <fa-icon [icon]="faCaretDown" class="icono fa-xl down" (click)="sortCompanies('creationDate', 'desc')" ></fa-icon>
                            </div>
                        </div>
                    </div>
                </th>
               
                <th scope="col" class="state">
                    <div class="column">
                    <span>{{ 'PRIVATE.CONSENTS.LIST-CONSENTS.LABEL-STATE' | translate }}</span>
                    <select name="state" id="state" [(ngModel)]="selectedStatus" (ngModelChange)="addStatusFilter($event)" >
                        <option value="0"> {{ 'PRIVATE.CONSENTS.LIST-CONSENTS.OPTION-1' | translate }}</option>
                        <option *ngFor="let status of statuses" [value]="status.id">
                            {{status.name}}
                          </option>
                    </select>
                    </div>
                </th>
            </thead>
            <tbody>
                <tr [routerLink]="['./'+ company.uuid]" *ngFor= "let company of companies" >
                <td class="id">{{company.id}}</td>
                <td class="company_name">
                    <ng-container *ngIf="company.language?.locale | localeToCountrySuffix as suffix">
                        <span class="small-flag" [innerHTML]="suffix"></span>
                      </ng-container>{{ company.businessName }}
                </td>                
                <td class="document" >{{company.cif}}</td>
                <td class="subscription" >{{company.doctorsAmount}}</td>
                <td class="type" >{{company.his === null || company.his === '' ? "Empresa" : company.his}}</td>
                <td class="year_price" >{{company.price !== undefined && company.billingType?.name === "Mensual" ? company.price*12 : company.price}}</td>
                <td class="login" >{{company.username}}</td>
                <td class="email" >{{company.email}}</td>
                <td class="date_on" >{{company.creationDate | date : 'dd/MM/YYYY'}}</td>
               
                <td class="state" >
                    <span
                    class="green bold"
                    *ngIf="GREEN_STATUS.includes(company.userStatus?.id ?? -1)">
                    {{ company.userStatus?.name }}
                  </span>
                  <span
                    class="red bold"
                    *ngIf="RED_STATUS.includes(company.userStatus?.id ?? -1)" >
                    {{ company.userStatus?.name }}
                  </span>
                  <span
                    class="orange bold"
                    *ngIf="ORANGE_STATUS.includes(company.userStatus?.id ?? -1)">
                    {{ company.userStatus?.name }}
                  </span>
                </td> 
                </tr>
                <!-- TODO para cuando haya empresas madre e hijas -->
                <!-- <tr [routerLink]="['../company']" >
                    <td class="id">146/1570</td>
                    <td class="company_name">
                        <fa-icon [icon]="faSquarePlus" class="icono tipo-second-color"></fa-icon>
                        Miranza Inversiones Oftalmológicas
                    </td>
                    <td class="document" >B789654123</td>
                    <td class="type" >Empresa</td>
                    <td class="subscription" >hasta 5 facultativos</td>
                    <td class="month_price" >8,3€</td>
                    <td class="year_price" >100€</td>
                    <td class="login" >admin</td>
                    <td class="date_on" >01/01/2021</td>
                    <td class="date_off" >20/02/2024</td>
                    <td class="state" >Baja</td>
                </tr>
                <tr [routerLink]="['../company']" >
                    <td class="id">146/1570</td>
                    <td class="company_name">
                        <fa-icon [icon]="faRightLong" class="icono tipo-second-color"></fa-icon>
                        Empresa hija
                    </td>
                    <td class="document" >B789654123</td>
                    <td class="type" >Empresa</td>
                    <td class="subscription" >hasta 5 facultativos</td>
                    <td class="month_price" >8,3€</td>
                    <td class="year_price" >100€</td>
                    <td class="login" >admin</td>
                    <td class="date_on" >01/01/2021</td>
                    <td class="date_off" >20/02/2024</td>
                    <td class="state" >Baja</td>
                </tr> -->

            </tbody>
        </table>
        <!-- Pagination -->
        <app-paginator
        [totalElements]="page?.totalElements ?? 0"
        [numbersOfElements]="page?.numberOfElements ?? 0"
        [totalPages]="page?.totalPages ?? 0"
        [pageNumber]="pagination.page"
        [lastPage]="page?.last ?? false"
        (paginate)="paginar($event)"></app-paginator>
    <!-- End of Pagination -->
</div>
