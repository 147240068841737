import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  ADMINISTRATORS_ROLES,
  ALL_ROLES,
  COMPANY_ROLES,
  DOCTOR_ROLES,
  FREEMIUM_COMPANY_ROLES,
  FREEMIUM_DOCTOR_ROLES,
  HEADER_ROLES,
  USERS_LOGIN,
  VIEW_CIS,
} from '@app/core/constants/Permissions';
import RoleEnum from '@app/core/enums/role.enum';
import { User } from '@app/core/models/input/user.model';
import { LoginService } from '@app/core/services/login/login.service';
import { RoleService } from '@app/core/services/role/role.service';
import {
  faChevronDown,
  faRightFromBracket,
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  // fontawesome
  faRightFromBracket = faRightFromBracket;
  faChevronDown = faChevronDown;

  // roles
  COMPANY_ROLES = COMPANY_ROLES;
  USERS_LOGIN = USERS_LOGIN;
  DOCTOR_ROLES = DOCTOR_ROLES;
  ADMINISTRATORS_ROLES = ADMINISTRATORS_ROLES;
  HEADER_ROLES = HEADER_ROLES;
  VIEW_CIS = VIEW_CIS;
  FREEMIUM_COMPANY_ROLES = FREEMIUM_COMPANY_ROLES;
  FREEMIUM_DOCTOR_ROLES = FREEMIUM_DOCTOR_ROLES;

  menuvisible = false;
  logoblanco = false;
  selected = false;

  showSubMenu = false;

  menuItems: MenuItem[] = [
    {
      name: 'PRIVATE.HEADER.MENU-LI1',
      route: '/portal',
      subMenuItems: [],
      permissions: HEADER_ROLES,
    },
    {
      name: 'PRIVATE.HEADER.MENU-LI2',
      route: '/portal/patients',
      subMenuItems: [],
      permissions: ALL_ROLES,
    },
    {
      name: 'PRIVATE.HEADER.MENU-LI3',
      route: '/portal/consents',
      subMenuItems: [],
      permissions: VIEW_CIS,
    },
    {
      name: 'PRIVATE.HEADER.MENU-LI4',
      route: '', // You can leave this empty since it's a submenu
      permissions: COMPANY_ROLES,
      subMenuItems: [
        {
          name: 'PRIVATE.HEADER.SUBMENU-LI1',
          route: '/portal/professionals',
          subMenuItems: [],
          permissions: COMPANY_ROLES,
        },
        {
          name: 'PRIVATE.HEADER.SUBMENU-LI2',
          route: '/portal/administrators',
          subMenuItems: [],
          permissions: COMPANY_ROLES,
        },
      ],
    },
    {
      name: 'PRIVATE.HEADER.MENU-LI5',
      route: '/portal/configuration/preferences',
      subMenuItems: [],
      permissions: ALL_ROLES,
    },
  ];

  // user
  user?: User;
  @ViewChild('header_button') headerbutton!: ElementRef;

  constructor(
    public roleService: RoleService,
    private readonly router: Router,
    private readonly loginService: LoginService
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.dropDownSubMenuIfIsSelected();
      }
    });
  }

  ngOnInit(): void {
    this.user = this.loginService.userValue ?? new User();
  }

  logOut() {
    this.loginService.logout("login");
  }

  dropDownSubMenuIfIsSelected(): void {
    const currentRoute = this.router.url;

    // Auto dropDown the menu if any subMenu items is selected
    for (const menuItem of this.menuItems) {
      const submenuItem = menuItem.subMenuItems.find(
        subItem => currentRoute.indexOf(subItem.route) !== -1
      );
      if (submenuItem) {
        this.showDesktopSubMenu();
        break;
      }
    }
  }
  mostrarMenu() {
    this.menuvisible = !this.menuvisible;
    this.logoblanco = !this.logoblanco;
  }

  quitarMenu() {
    this.menuvisible = false;
    this.logoblanco = false;
  }

  showDesktopSubMenu() {
    this.showSubMenu = true;
  }
  removeDesktopSubMenu() {
    this.showSubMenu = false;
  }

  selectMenuOption(option: string) {
    const enlace = document.querySelectorAll('.menu_a');
    enlace.forEach(element => {
      if (option === element.id) {
        element.classList.add('selected');
      } else {
        element.classList.remove('selected');
      }
    });
  }
}

interface MenuItem {
  name: string;
  route: string;
  subMenuItems: MenuItem[];
  permissions: RoleEnum[];
}
