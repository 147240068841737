import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AvisoLegalComponent } from './pages/avisolegal/aviso-legal.component';
import { CatalogoComponent } from './pages/catalogo/catalogo.component';
import { LoginComponent } from './pages/clientes/login.component';
import { ComofuncionaComponent } from './pages/comofunciona/comofunciona.component';
import { ContactoComponent } from './pages/contacto/contacto.component';
import { CookiesComponent } from './pages/cookies/cookies.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginAdministrationComponent } from './pages/login-administration/login-administration.component';
import { PoliticaComponent } from './pages/politica/politica.component';
import { ProcedureDetailComponent } from './pages/procedure-detail/procedure-detail.component';
import { ProceduresSearchComponent } from './pages/procedures-search/procedures-search.component';
import { QueesComponent } from './pages/quees/quees.component';
import { QuienessomosComponent } from './pages/quienessomos/quienessomos.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { PublicComponent } from './public.component';

const routes: Routes = [
  {
    path: '',
    component: PublicComponent,
    children: [
      { path: '', component: HomeComponent },
      { path: 'quees', component: QueesComponent },
      { path: 'comofunciona', component: ComofuncionaComponent },
      { path: 'quienessomos', component: QuienessomosComponent },
      { path: 'catalogo',         
        children: [
          {
            path:'',
            component: CatalogoComponent,
          },
          {
            path:':id',
            component: ProceduresSearchComponent            
          } ,
          {
            path: ':id/procedure-name/:procedureName',
            component:ProceduresSearchComponent
          }, 
          {
            path: ':id/procedure-name',
            component:ProceduresSearchComponent
          }, 
          {
            path: 'procedure-name/:procedureName',
            component:ProceduresSearchComponent
          }, 
          {
            path:'procedure-code/:code',
            component: ProcedureDetailComponent
          }              
        ] 
      },
      { path: 'contacto', component: ContactoComponent },
      { path: 'login', component: LoginComponent },
      { path: 'avisolegal', component: AvisoLegalComponent },
      { path: 'politicaprivacidad', component: PoliticaComponent },
      { path: 'cookies', component: CookiesComponent },
      { path: 'user/new-password', component: ResetPasswordComponent },
      { path: 'admin', component: LoginAdministrationComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PublicRoutingModule {}
