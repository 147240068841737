import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class DataClientService {
dataClient?: FormGroup;

  constructor() {
    this.dataClient = new FormGroup({});
  }

  addFormGroup(formName: string, formGroup: FormGroup) {
    this.dataClient?.removeControl(formName)
    this.dataClient?.addControl(formName, formGroup);
  }
}
