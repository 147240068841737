import { Component, Input, OnInit } from '@angular/core';
import { Device, UserDevice } from '@app/core/models/input/device.model';
import { User } from '@app/core/models/input/user.model';
import { AlertService } from '@app/core/services/alert/alert.service';
import { LoginService } from '@app/core/services/login/login.service';
import { CacheImplService } from '@app/shared/services/cache/cache-impl.service';
import { TranslateService } from '@ngx-translate/core';
import { DeviceService } from '../../../../core/services/device/device.service';

@Component({
  selector: 'app-edit-device',
  templateUrl: './edit-device.component.html',
  styleUrls: ['./edit-device.component.scss'],
})
export class EditDeviceComponent implements OnInit {
  selectValueDisp?: string = '';

  @Input()
  devices?: Device[];

  user?: User;
  constructor(
    private readonly deviceService: DeviceService,
    private readonly loginService: LoginService,
    private readonly alertService: AlertService,
    private readonly translate: TranslateService,
    private readonly cacheImplService: CacheImplService
  ) {}

  ngOnInit(): void {
    this.user = this.loginService.userValue ?? new User();
    this.selectValueDisp = this.user.userDevice?.description;   
  }

  changeDevice() {
    if (this.selectValueDisp && this.user) {
      if (!this.user.userDevice) {
        this.user.userDevice = new UserDevice();
      }
      this.user.userDevice.description = this.selectValueDisp;

      this.deviceService
        .changeUserDevice(this.user?.userDevice)
        .subscribe(() => {
          this.cacheImplService.setItem('user', JSON.stringify(this.user));

          this.loginService.setUserValue(this.user ?? null);
        });
      this.alertService.success(
        this.translate.instant(
          'PRIVATE.PERSONALIZATION.EDIT-DEVICE.MESSAGE-DEVICE-CHANGE-OK'
        )
      );
    }
  }
}
