import { Injectable } from '@angular/core';
import {
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AlertService } from '../../services/alert/alert.service';
import { LoginService } from '../../services/login/login.service';
import { USERS_ADMIN_ELCI } from '@app/core/constants/Permissions';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationAdminGuard {
  constructor(
    private readonly router: Router,
    private readonly loginService: LoginService,
    private readonly alertService: AlertService
  ) {}

  canActivate(
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const user = this.loginService.userValue;   
   
    if (user) {
      // check if route is restricted by role
   
      const roleIds = USERS_ADMIN_ELCI.map(role => role.valueOf());     
      const actualUserRoleIds: number[] = (user.roles ?? [])
      .map(role => role.id)
      .filter(id => typeof id === 'number') as number[];
      if (
        roleIds &&
        actualUserRoleIds &&
        !actualUserRoleIds.some(roleId => roleIds.includes(roleId))
      ) {
        // role not authorized so redirect to home page
        this.alertService.info('Please Log In!');
        this.router.navigate(['/admin']);
        return false;
      }

      // authorized so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/admin'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}

