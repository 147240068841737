import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Filter } from '@app/core/models/front/filters';
import { Pagination } from '@app/core/models/front/pagination';
import { Sorters } from '@app/core/models/front/sorters';
import { ChangePassword } from '@app/core/models/input/change-pass.model';
import { Status } from '@app/core/models/input/common/status.model';
import { Page } from '@app/core/models/input/page/pages.model';
import { UserOrPatientFullName } from '@app/core/models/input/patient/patient-search.model';
import { Specialty } from '@app/core/models/input/specialty.model';
import { UserInput } from '@app/core/models/input/user-input.model';
import { User, UserSignature } from '@app/core/models/input/user.model';
import { SignatureOutput } from '@app/core/models/output/signature-output.model';
import { UserOutput } from '@app/core/models/output/user-output.model';
import { EnvironmentService } from '@app/environment.service';
import StorageTypeEnum from '@app/shared/enums/storage-type.enum';
import { CacheImplService } from '@app/shared/services/cache/cache-impl.service';
import { Observable, of } from 'rxjs';
import { UtilsService } from '../utils/utils.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private readonly http: HttpClient,
    private readonly env: EnvironmentService,
    private readonly uSrv: UtilsService,
    private readonly cacheService: CacheImplService
  ) {}

  getActualUserInfo() {
    return this.http.get<User>(`${this.env.apiUrl}/users/info`);
  }

  getActualSudoInfo() {
    return this.http.get<User>(`${this.env.apiUrl}/sudos/info`);
  }

  getUsers(
    pagination: Pagination,
    filters: Filter[],
    sorters: Sorters
  ): Observable<Page> {
    return this.http.get<Page>(
      `${this.env.apiUrl}/users?` +
        this.uSrv.genPaginationInUri(pagination) +
        this.uSrv.getFiltersInUri(filters) +
        this.uSrv.genSortersInUri(sorters)
    );
  }

  getUserByUuid(uuid: string) {
    return this.http.get<UserInput>(`${this.env.apiUrl}/users/${uuid}`);
  }

  getUserStatus(){
    return this.http.get<Status[]>(`${this.env.apiUrl}/users/status`);
  }

  newUser(dataUser: UserOutput) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post<UserOutput>(
      `${this.env.apiUrl}/users`,
      JSON.stringify(dataUser),
      { headers: headers }
    );
  }

  editUser(dataUser: UserOutput) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.put<UserOutput>(
      `${this.env.apiUrl}/users/edit/${dataUser.uuid}`,
      JSON.stringify(dataUser),
      { headers: headers }
    );
  }

  getDoctorsByNameOrDocument(
    searchTerm: string
  ): Observable<UserOrPatientFullName[]> {
    return this.http.get<UserOrPatientFullName[]>(
      `${this.env.apiUrl}/users/companyDoctors/${searchTerm}`
    );
  }

  getDoctors(): Observable<UserOrPatientFullName[]> {
    return this.http.get<UserOrPatientFullName[]>(
      `${this.env.apiUrl}/users/companyDoctors`
    );
  }

  changePassword(uuid: string, dataPass: ChangePassword) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.put<ChangePassword>(
      `${this.env.apiUrl}/users/password/${uuid}`,
      JSON.stringify(dataPass),
      { headers: headers }
    );
  }

  getUserSpecialties(userUuid: string, language = "ES"): Observable<Specialty[]> {
    return this.http.get<Specialty[]>(
      `${this.env.apiUrl}/users/${userUuid}/consents/specialties?language=${language}`
    );
  }

  getUserSignatureApi(uuid: string): Observable<UserSignature> {
    if (Object.keys(this.getUserSignature()).length !== 0 && uuid === this.getUserSignature().userUuid) {
      return of(this.getUserSignature() as UserSignature);
    } else {
      return this.http.get<UserSignature>(
        `${this.env.apiUrl}/users/${uuid}/signature`);
    }
  }

  editUserSignature(uuid: string, userSignature: SignatureOutput) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.put<SignatureOutput>(
      `${this.env.apiUrl}/users/${uuid}/signature`,
      JSON.stringify(userSignature),
      { headers: headers }
    );
  }

  deleteUserSignature(uuid: string) {
    return this.http.delete(`${this.env.apiUrl}/users/${uuid}/signature`);
  }

  getIfCompanyCanAddNewDoctor(): Observable<HttpResponse<null>> {
    return this.http.get<null>(`${this.env.apiUrl}/users/can-add-new-doctor`, {
      observe: 'response'
    });
  }

  private _userSignature?: UserSignature | null;

  // Get the values firstly try in the variable and if is null search it in the session storage
  private getUserSignature(): UserSignature {
    if (!this._userSignature) {
        this._userSignature = JSON.parse(
          this.cacheService.getItemNotEncrypted('user-signature') ?? '{}'
        );
      return this._userSignature ?? new UserSignature();
    }
    return this._userSignature;
  }

  // Save the value in the service and in the local storage
  public setUserSignature(userSignature: UserSignature) {
    if (userSignature?.userUuid) {
      this.cacheService.setItemNotEncrypted(
        'user-signature',
        JSON.stringify(userSignature)
      );
      this._userSignature = userSignature;
    }
  }

  public deleteUserSignatureStorage() {
    this.cacheService.removeItem('user-signature', StorageTypeEnum.SESSION_STORAGE);
    this._userSignature = null;
  }

}
