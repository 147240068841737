import { Component } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { STORAGE_KEY_LOCALE } from './core/constants/Constants';
import { CacheImplService } from './shared/services/cache/cache-impl.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {

  constructor(
    readonly translate: TranslateService,
    readonly cacheService: CacheImplService,
    ) {
    if (cacheService.getItemNotEncrypted(STORAGE_KEY_LOCALE) === ""){
      cacheService.setItemNotEncrypted(STORAGE_KEY_LOCALE, "es-ES");
    }
    translate.setDefaultLang('es-ES');
    translate.setDefaultLang(cacheService.getItemNotEncrypted(STORAGE_KEY_LOCALE) as string ?? 'es-ES');
  }


}
