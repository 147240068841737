import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DEFAULT_ELCI_SPECIALTY_LANG } from '@app/core/constants/Constants';
import { Procedure } from '@app/core/models/input/procedure/procedure.model';
import { EnvironmentService } from '@app/environment.service';
import { Observable } from 'rxjs';
import { UtilsService } from '../utils/utils.service';
import { SpecialityOutputDTO } from '@app/core/models/input/specialty/specialty.model';

@Injectable({
  providedIn: 'root'
})
export class ProcedureService {

  constructor(
    private readonly http: HttpClient,
    private readonly env: EnvironmentService,
    private readonly uSrv: UtilsService,
  ) { }

  getProcedure(id: string): Observable<Procedure> {
    return this.http.get<Procedure>(`${this.env.apiUrl}/consents/procedures/code/`+id);
  }

  getSpecialityByID(specialtyId: string, language = DEFAULT_ELCI_SPECIALTY_LANG): Observable<SpecialityOutputDTO> {
    return this.http.get<SpecialityOutputDTO>(`${this.env.apiUrl}/consents/specialties/${specialtyId}?language=${language}`);
  }

  // Only sudo. If is not sudo will return an ElciException
  getProceduresByCountry(specialtyId: string, country: string, language = DEFAULT_ELCI_SPECIALTY_LANG,): Observable<Procedure[]> {
    return this.http.get<Procedure[]>(`${this.env.apiUrl}/consents/procedures/specialty/`+specialtyId+"?"+this.uSrv.genParameterInUri("language", language)+"&"+this.uSrv.genParameterInUri("country", country));
  }

  getTopProcedures(userUuid: string): Observable<Procedure[]> {
    return this.http.get<Procedure[]>(`${this.env.apiUrl}/users/${userUuid}/topProcedures/doctor`);
  }

  getProcedureWithImagesByCode(code: string) {
    return this.http.get<Procedure>(`${this.env.apiUrl}/consents/procedures/code/${code}`);
  }

}
