import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import LanguageEnum from '@app/core/enums/language.enum';
import { State } from '@app/core/models/input/state.model';
import { AlertService } from '@app/core/services/alert/alert.service';
import { DataClientService } from '@app/core/services/data-client/data-client.service';
import { StateService } from '@app/core/services/state/state.service';
import { ElciValidators } from '@app/core/utils/validators';
import { faCircleArrowLeft, faCircleArrowRight } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-page5-data-rgpd',
  templateUrl: './page5-data-rgpd.component.html',
  styleUrls: ['./page5-data-rgpd.component.scss'],
})
export class Page5DataRgpdComponent implements OnInit {
  faCircleArrowRight = faCircleArrowRight;
  faCircleArrowLeft = faCircleArrowLeft;

  @Output() continue4 = new EventEmitter();
  @Output() continue5 = new EventEmitter();
  dataRgpd!: FormGroup;
  classInput = '';
  isSubmit = false;
  isCheckedCopied = true;
  selectValue = null;

  states?: State[];

  constructor(
    private readonly dataClientService: DataClientService,
    private readonly alertService: AlertService,
    private readonly translate: TranslateService,
    private readonly stateService: StateService
  ) {}

  togglePagesFourFive() {
    this.continue4.emit();
  }

  togglePagesFiveSix() {
    this.continue5.emit();
  }

  // función que valida cada dato del formulario
  shouldShowError(inputControlName: string): boolean {
    const control = this.dataRgpd.get(inputControlName);
    return !!(control && control.touched && control.invalid);
  }

  ngOnInit(): void {
    this.dataRgpd = new FormGroup({
      copied: new FormControl({ value: true, disabled: false }),
      businessNameFis: new FormControl({ value: '', disabled: false }, [Validators.required]),
      addressFis: new FormControl({ value: '', disabled: false }, [Validators.required]),
      postCodeFis: new FormControl({ value: '', disabled: false },  [Validators.pattern(/^\d{5,6}$/),]),
      emailLOPD: new FormControl({ value: '', disabled: false }, [ElciValidators.emailValidator]),
      cifFis: new FormControl({ value: '', disabled: false }, [
        Validators.required
      ]),
      localityFis: new FormControl({ value: '', disabled: false }, [Validators.required]),
      stateIdFis: new FormControl({ value: '', disabled: false }, [Validators.required]),
      
    });

    if (this.dataClientService.dataClient?.get('dataAccess.languageId')?.value.id === LanguageEnum.es_EC){
      this.dataRgpd.get('cifFis')?.setValidators([ Validators.pattern(/^\d{13}$/)]);
      
    }else {
      this.dataRgpd.get('cifFis')?.setValidators([ Validators.pattern(/^\w{9}$/)]);
    }
    this.dataRgpd.get('cifFis')?.updateValueAndValidity();


    // consultamos las provincias asignadas al país
    this.stateService.getStateByCountryId(this.dataClientService.dataClient?.get('dataAccess.languageId')?.value.id).subscribe({
      next: states => {
        this.states = states;
      },
    });

    this.fillInputs();
    this.selectValue = this.dataRgpd.controls['stateIdFis'].value;
  }

  checkedCopied() {
    this.isCheckedCopied = !this.isCheckedCopied;
    if (this.isCheckedCopied) {
      this.fillInputs();
    } else {
      this.dataRgpd.controls['businessNameFis'].setValue('');
      this.dataRgpd.controls['addressFis'].setValue('');
      this.dataRgpd.controls['postCodeFis'].setValue('');
      this.dataRgpd.controls['emailLOPD'].setValue('');
      this.dataRgpd.controls['cifFis'].setValue('');
      this.dataRgpd.controls['localityFis'].setValue('');
      this.dataRgpd.controls['stateIdFis'].setValue('');
     
    }
  }

  fillInputs() {
    this.dataRgpd.controls['businessNameFis'].setValue(
      this.dataClientService.dataClient?.get('dataCompany.businessName')?.value
    );
    this.dataRgpd.controls['addressFis'].setValue(this.dataClientService.dataClient?.get('dataContact.address')?.value);
    this.dataRgpd.controls['postCodeFis'].setValue(
      this.dataClientService.dataClient?.get('dataContact.postCode')?.value
    );
    this.dataRgpd.controls['emailLOPD'].setValue(
      this.dataClientService.dataClient?.get('dataContact.emailLOPD')?.value
    );
    this.dataRgpd.controls['cifFis'].setValue(this.dataClientService.dataClient?.get('dataCompany.cif')?.value);
    this.dataRgpd.controls['localityFis'].setValue(
      this.dataClientService.dataClient?.get('dataContact.locality')?.value
    );
    this.dataRgpd.controls['stateIdFis'].setValue(this.dataClientService.dataClient?.get('dataContact.stateId')?.value);
    this.dataRgpd.controls['emailLOPD'].setValue(this.dataClientService.dataClient?.get('dataContact.email')?.value);
   
   
  }

  onSubmit() {
    this.isSubmit = true;

    Object.keys(this.dataRgpd.controls).forEach(controlName => {
      this.dataRgpd.get(controlName)?.markAsTouched();
    });

    if (this.dataRgpd.valid) {      
      this.dataClientService.addFormGroup('dataRgpd', this.dataRgpd);
      this.togglePagesFiveSix();
    } else {
      this.classInput = 'wrong';
      this.alertService.error(this.translate.instant('PRIVATE.PATIENT.DATA-PATIENT.MESSAGE-PATIENT-NEW-ERROR'));
    }
  }
}
