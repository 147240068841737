import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ADMINISTRATORS_ROLES } from '@app/core/constants/Permissions';
import RoleEnum from '@app/core/enums/role.enum';
import TokenSubscribeEnum from '@app/core/enums/token-subscribe.enum';
import { Token } from '@app/core/models/input/token.model';
import { CCGuestChangePasswordModel } from '@app/core/models/output/guest-change-password-DTO';
import { RoleService } from '@app/core/services/role/role.service';
import { ElciValidators } from '@app/core/utils/validators';
import { faCircleQuestion, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs';
import { AlertService } from '../../../core/services/alert/alert.service';
import { LoginService } from '../../../core/services/login/login.service';
import { TranslateCustomService } from '@app/shared/services/translate/translate.service';
import { EnvironmentService } from '@app/environment.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  faCircleXmark = faCircleXmark;
  faCircleQuestion = faCircleQuestion;

  login!: FormGroup;
  reset!: FormGroup;
  activation!: FormGroup;
  showConfirmActivation = false;
  isCheckedActivation = false;
  tokenDecode: Token = new Token();
  roleEnum = RoleEnum;
  role?: number;
  isOpenWindow = false;
  noValido = false;
  error = false;
  tokenSSICredential = '';

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly loginService: LoginService,
    private readonly alertService: AlertService,
    private readonly roleService: RoleService,
    private readonly translate: TranslateService,
    private readonly translateCustom: TranslateCustomService,
    private readonly env: EnvironmentService,
  ) {
    this.loginService.logout(null); // First logout, this resets all the user things
    this.initForms();
    this.loginWithTokenFromURL();
  }

  loginApp() {
    this.loginService
      .login(this.login.value.username, this.login.value.password, this.tokenSSICredential)
      .pipe(first())
      .subscribe({
        next: value => {
          this.tokenDecode = value as Token;
          if (this.tokenDecode?.authorities?.includes(TokenSubscribeEnum.ROLE_USER_DISABLED)) {
            this.alertService.error(this.translate.instant('SERVICES.LOGIN-SERVICE.DISABLED-ACCOUNT'));
            return;
          }
          if (this.tokenDecode?.authorities?.includes(TokenSubscribeEnum.ROLE_ACTIVATE)) {
            this.showConfirmActivation = true;
            const translatedToAngularLocale = this.tokenDecode?.language?.replace("_", "-") ?? this.env.defaultLanguage;
            this.translateCustom.changeLang(translatedToAngularLocale)
          } else if (this.roleService.hasRole(ADMINISTRATORS_ROLES)) {
            const returnUrl = this.route.snapshot.queryParamMap.get('returnUrl') ?? '/portal/patients';
            this.router.navigateByUrl(returnUrl);
          } else {
            const returnUrl = this.route.snapshot.queryParamMap.get('returnUrl') ?? '/portal';
            this.router.navigateByUrl(returnUrl);
          }
        },
      });
  }

  resetPassword() {
    const guestChangePassword = new CCGuestChangePasswordModel(
      this.reset.value.usuarioReset,
      this.reset.value.emailReset
    );
    this.closeWindow();
    this.loginService.resetPassword(guestChangePassword).subscribe({
      next: () => {
        this.alertService.success('Se ha enviado el correo para restablecer la contraseña');
      },
    });
  }

  checkedActivation() {
    this.isCheckedActivation = !this.isCheckedActivation;
  }

  confirmActivation() {
    this.loginService
      .activateUser()
      .pipe(first())
      .subscribe({
        next: () => {
          this.loginApp();
        },
        error: error => {
          this.alertService.error(error.message);
        },
      });
  }

  initForms(): void {
    this.login = new FormGroup({
      username: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
      ]),
      password: new FormControl('', Validators.required),
    });

    this.reset = new FormGroup({
      usuarioReset: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
      ]),
      emailReset: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        ElciValidators.emailValidator,
      ]),
    });

    this.activation = new FormGroup({
      doctorActivation: new FormControl('', [Validators.requiredTrue]),
    });
  }

  openWindow(): void {
    this.isOpenWindow = true;
  }

  closeWindow(): void {
    this.isOpenWindow = false;
  }


  goUrlCredential() {
    window.location.href = this.env.urlCredential;
  }

  loginWithTokenFromURL() {
    const token = this.route.snapshot.queryParamMap.get('token');
    if(token){
      this.tokenSSICredential = token;
      this.loginApp();  
    }
  }
}

