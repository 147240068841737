import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QuickApiInputDTO, QuickApiOutputDTO } from '@app/core/models/output/quick-api.model';
import { EnvironmentService } from '@app/environment.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QuickApiService {

  constructor(
    private readonly http: HttpClient,
    private readonly env: EnvironmentService,
  ) { }


  saveQuickApiData(quickApi: QuickApiOutputDTO) {
    const headers = new HttpHeaders({
      'Content-Type':  'application/json'
    })

    return this.http.post<QuickApiInputDTO>(`${this.env.apiUrl}/api/quick`, JSON.stringify(quickApi), {headers: headers})
  }

  private quickApiUuidSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  quickApiUuid$: Observable<string | null> = this.quickApiUuidSubject.asObservable();

  setQuickApiUuid(quickApiUuid: string | null) {
    this.quickApiUuidSubject.next(quickApiUuid);
  }
}
