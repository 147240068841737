import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  VariableContainerTypeEnum,
  VariableTypeEnum,
} from '@app/core/enums/superdocs.enum';
import { Variable } from '@app/core/models/input/consent/variable.model';
import { UserInput } from '@app/core/models/input/user-input.model';
import { VarOutputDTO } from '@app/core/models/output/consent/consent-var-output-model';
import { DigitalDocumentOutputDTO } from '@app/core/models/output/digitalDocuments/digital-document.model';
import { AlertService } from '@app/core/services/alert/alert.service';
import { DigitalDocumentsService } from '@app/core/services/digital-documents/digital-documents.service';
import { TemplateService } from '@app/core/services/template/template.service';
import { UserService } from '@app/core/services/user/user.service';
import { VariableService } from '@app/core/services/utils/variable/variable.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import {
  ADMINISTRATORS_ROLES,
  COMPANY_ROLES,
} from '../../../../core/constants/Permissions';
import { UserSignature } from '../../../../core/models/input/user.model';
import { RoleService } from '../../../../core/services/role/role.service';
import { PatientService } from '@app/core/services/patient/patient.service';
import { Patient } from '@app/core/models/input/patient/patient.model';
import { SubmitButtonService } from '@app/core/services/submit-button/submit-button.service';
import { QuickApiService } from '@app/quick-api/api-handler/services/quick-api/quick-api.service';

@Component({
  selector: 'app-gen-dds',
  templateUrl: './gen-dds.component.html',
  styleUrls: ['./gen-dds.component.scss'],
})
export class GenDdsComponent implements OnInit, OnDestroy {
  vars?: Variable[];
  dataSignatureFrom!: FormGroup;
  patientUuid?: string;
  patient?: Patient;
  templateUuid?: string;

  isSelectVariablesProcessed = true;

  isApp = true;
  isQuickapi = false;

  // This property is set in the service when the quick api object is created and its passed to the consent.
  quickApiUuid: string | undefined = undefined;

  varSubscription?: Subscription;
  quickApiUuidSubscription?: Subscription;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly variableService: VariableService,
    private readonly digitalDocumentsService: DigitalDocumentsService,
    private readonly alertService: AlertService,
    private readonly translate: TranslateService,
    private readonly userService: UserService,
    private readonly roleService: RoleService,
    private readonly templateService: TemplateService,
    private readonly quickApiService: QuickApiService,
    private readonly patientService: PatientService,
    private readonly submitButtonService: SubmitButtonService,
  ) {
    this.activatedRoute.params.subscribe(param => {
      this.patientUuid = param['uuid'];
      this.templateUuid = param['template-uuid'];
      this.getPatientByUuid(this.patientUuid ?? '');
    });
  }

  ngOnInit(): void {
    this.handleSubscriptions();

    if (this.roleService.isQuickapi()) {
      this.isApp = false;
      this.isQuickapi = true;
    }
  }

  handleChildFormGroup(childFormGroup: FormGroup) {
    // Now you have access to the FormGroup emitted by the child component
    // You can use it in the parent component as needed
    this.dataSignatureFrom = childFormGroup; // For example, patch the parent form with child form values
    this.onSubmit();
  }

  handleSubscriptions() {
    this.varSubscription = this.variableService.vars$.subscribe(vars => {
      if (vars) {
        this.vars = vars;
      }
    });
    this.quickApiUuidSubscription =
      this.quickApiService.quickApiUuid$.subscribe(quickApiUuid => {
        if (quickApiUuid) {
          this.quickApiUuid = quickApiUuid;
          // Once setted in this consent clean the subscription value
          this.quickApiService.setQuickApiUuid(null);
        }
      });
  }

  onSubmit() {
    if (this.vars) {
      // This is used for update the VariableContainerTypeEnum.DOCUMENT
      // this.getDocumentVariableContainer(this.vars);
      this.getDocumentVariableContainer(this.vars);
    }
  }

  submitData() {
    this.digitalDocumentsService
      .postDigitalDocument(this.mapDigitalDocumentData())
      .subscribe({
        next: () => {
          this.alertService.success(
            this.translate.instant(
              'PRIVATE.PATIENT.GENERATE-DD.CREATE-DD-SUCCESS'
            )
          );
          if (this.roleService.isQuickapi()) {
            const patientName = `${
              this.vars?.filter(
                variable => variable.name === 'signer_name_1'
              )[0].value
            } ${
              this.vars?.filter(
                variable => variable.name === 'signer_last_name_1'
              )[0].value
            }`;
            this.router.navigate(['/portal/quick-api/patients/doc-ok'], {
              queryParams: { document: 'documento', patientName: patientName, actionDoc: this.translate.instant('QUICKAPI.QUERY-PARMAS-GENERATE') },
            });
          } else {
            this.router.navigate(['../../'], {
              relativeTo: this.activatedRoute,
            });
          }
        },
        error: () => {
          this.alertService.error(
            this.translate.instant(
              'PRIVATE.PATIENT.GENERATE-DD.CREATE-DD-ERROR'
            )
          );
        },
      });
  }

  /**
   * Retrieves and processes variables associated with document elements.
   *
   * This method operates on an array of `Variable` objects. Each variable is processed based on its type
   * and associated DOM element. If the variable is associated with an input element, its value is
   * retrieved and stored. The method handles different types of variables and merges the processed
   * variables with subject variables in the variable service.
   *
   * @param {Variable[]} variables - An array of `Variable` objects to be processed.
   */
  getDocumentVariableContainer(variables: Variable[]) {
    // Initialize an array to hold modified variables.
    const variablesModified: Variable[] = [];

    this.processSelectVariables(variables);
    let errorsInVariables = false;

    // Sort and filter the variables for those associated with the document.
    this.variableService
      .sortContainerTypes(variables)
      .get(VariableContainerTypeEnum.DOCUMENT)
      ?.forEach(variable => {
        // Skip processing if the variable lacks a UUID or is of type CHECK.
        if (!variable.uuid || VariableTypeEnum.CHECK === variable.type) {
          return; // TODO
        }

        // Retrieve the DOM element associated with the variable UUID.
        const element = document.getElementById(variable.uuid);

        // Skip processing if the element is not an HTMLInputElement or if its value is empty.
        if (!(element instanceof HTMLInputElement)) {
          return;
        }

        if (VariableTypeEnum.STRING === variable.type && element.value === '' && variable.requiered) {
          this.alertService.error(this.translate.instant(
            'PRIVATE.PATIENT.GEN-DDS.REQUIRED-INPUT-VARIABLE',
            {textInputName: variable.name}
          ))
          errorsInVariables = true;
          this.submitButtonService.setGenerateDocIsOnSubmit(false);
        }

        // Push the modified variable to the array.
        variablesModified.push({
          uuid: variable.uuid,
          value: element.value,
        });
      });

    // Merge the modified variables with subject variables in the variable service.
    this.variableService.mergeWithSubjectVariables(variablesModified);

    if (this.isSelectVariablesProcessed && !errorsInVariables) {
      this.submitData();
    }
  }

  processSelectVariables(selectVariables: Variable[]) {
    selectVariables.forEach(variable => {
      switch (variable.anchor) {
        case '%doctor_select%':
          if (
            this.roleService.hasRole([
              ...ADMINISTRATORS_ROLES,
              ...COMPANY_ROLES,
            ])
          ) {
            this.isSelectVariablesProcessed = false;
            this.processDoctorSelect(variable);
          }
          break;
        case '%other_special_select%':
          // TODO For other select with data search to db
          break;
        default:
          // TODO for normal selects, only prints the value into the variable
          break;
      }
    });
  }
  processDoctorSelect(variable: Variable) {
    if (variable?.uuid) {
      // Retrieve the DOM element associated with the variable UUID.
      const element = document.getElementById(variable.uuid);

      if (!(element instanceof HTMLSelectElement) || element.value === '') {
        return;
      }

      // Assuming getDoctorByUuid returns a Promise<UserInput>
      this.getDoctorByUuid(element.value ?? '').then((doctor: UserInput) => {
        const doctorDataVariables = {
          doctor_name: doctor.name, // Hide the doctor name and last name
          doctor_last_name: `${doctor.firstLastname} ${doctor.secondLastname}`,
        };
        const template = this.templateService.getTemplate();
        if (template) {
          this.variableService.mapVariables(template, doctorDataVariables);
        }
        this.isSelectVariablesProcessed = true;
        this.submitData();
      });
    }
  }

  getDoctorByUuid(doctorUuid: string): Promise<UserInput> {
    return new Promise((resolve, reject) => {
      this.userService.getUserByUuid(doctorUuid).subscribe({
        next: doctor => {
          if (doctor?.uuid) {
            this.getDoctorSignature(doctor?.uuid).then(
              (userSignature: UserSignature) => {
                if (userSignature) {
                  const doctorSignature = this.constructBase64Image(
                    userSignature.format ?? '',
                    userSignature.content ?? ''
                  );
                  const doctorSelectData = {
                    doctor_signature: doctorSignature,
                  };
                  const template = this.templateService.getTemplate();
                  if (template) {
                    this.variableService.mapVariables(
                      template,
                      doctorSelectData
                    );
                  }
                }
                resolve(doctor);
              }
            );
          }
        },
        error: err => {
          reject(new Error(err));
        },
      });
    });
  }

  private constructBase64Image(format: string, content: string): string {
    return `data:image/${format};base64,${content}`;
  }

  private getDoctorSignature(uuid: string): Promise<UserSignature> {
    return new Promise((resolve, reject) => {
      this.userService.getUserSignatureApi(uuid).subscribe({
        next: (data: UserSignature) => {
          this.userService.setUserSignature(data);
          resolve(data);
        },
        error: err => {
          reject(new Error(err));
        },
      });
    });
  }

  mapDigitalDocumentData(): DigitalDocumentOutputDTO {
    return {
      patient: {
        // Map patient-related values from the form group
        uuid: this.patient?.uuid,
        documentNumber: this.patient?.documentNumber,
        email: this.dataSignatureFrom.get('patientEmail')?.value,
        phonePrefix: this.dataSignatureFrom.get('patientPrefix')?.value,
        mobile: this.dataSignatureFrom.get('patientPhone')?.value,
        representativePatients: [
          {
            documentNumber: this.dataSignatureFrom.get('firstRepresentativeDocumentNumber')?.value,
            email: this.dataSignatureFrom.get('firstRepresentativeEmail')?.value,
            phonePrefix: this.dataSignatureFrom.get('firstRepresentativePrefix')?.value,
            mobile: this.dataSignatureFrom.get('firstRepresentativePhone')?.value,
          },
          {
            documentNumber: this.dataSignatureFrom.get('secondRepresentativeDocumentNumber')?.value,
            email: this.dataSignatureFrom.get('secondRepresentativeEmail')?.value,
            phonePrefix: this.dataSignatureFrom.get('secondRepresentativePrefix')?.value,
            mobile: this.dataSignatureFrom.get('secondRepresentativePhone')?.value,
          }
        ]
      },
      templateUuid: this.templateUuid ?? undefined,
      variables: this.constructVariables(this.vars ?? []),
      device: this.dataSignatureFrom.get('device')?.value ?? undefined,
      outputTypeId:
        this.dataSignatureFrom.get('outputTypeId')?.value ?? undefined,
      quickApiUuid: this.quickApiUuid ?? undefined,
    };
  }

  getPatientByUuid(patientUuid: string) {
    this.patientService.getPatientByUuid(patientUuid).subscribe({
      next: patient => {
        if (patient) {
          this.patient = patient
        }
      },
    });
  }

  constructVariables(variables: Variable[]): VarOutputDTO[] {
    return variables.map(variable => {
      const { uuid, value } = variable;
      return { uuid, value };
    });
  }

  ngOnDestroy(): void {
    if (this.varSubscription) {
      this.varSubscription.unsubscribe();
    }
    if (this.quickApiUuidSubscription) {
      // Set the uuid to null
      this.quickApiService.setQuickApiUuid(null);
      this.quickApiUuidSubscription.unsubscribe();
    }
  }
}
