import { formatDate } from '@angular/common';
import { Component, Inject, Input, LOCALE_ID, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { COMPANY_ROLES, DOCTOR_ROLES } from '@app/core/constants/Permissions';
import { Statistics } from '@app/core/models/input/statistics/statistics.model';
import { StatisticsService } from '@app/core/services/statistics/statistics.service';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {
  DOCTOR_ROLES = DOCTOR_ROLES;
  COMPANY_ROLES = COMPANY_ROLES;

  @Input()
  statistics = new Statistics();


  dataStatistics!: FormGroup;
  today = new Date();
  firstDay = new Date(this.today.getFullYear(), this.today.getMonth()-1, 1);
  lastDay = new Date(this.today.getFullYear(), this.today.getMonth(), 0);
  startDate!: string | null;
  endDate!: string | null;


  constructor(
    private readonly statisticsService: StatisticsService,
    @Inject(LOCALE_ID) private readonly locale: string
  ) { }


  ngOnInit(): void {
    this.dataStatistics = new FormGroup({
      startDate: new FormControl(),
      endDate: new FormControl(),
    })

    this.startDate = formatDate(this.firstDay, "yyyy-MM-dd", this.locale);
    this.endDate = formatDate(this.lastDay, "yyyy-MM-dd", this.locale);

    this.statisticsService.getStatistics(this.startDate, this.endDate).subscribe({
      next: data => {
        this.statistics = data;
      }
    })

    this.dataStatistics.controls['startDate'].setValue(this.startDate);
    this.dataStatistics.controls['endDate'].setValue(this.endDate);
  }



  onSubmit() {
    this.startDate = this.dataStatistics.get('startDate')?.value;
    this.endDate = this.dataStatistics.get('endDate')?.value;

    this.statisticsService.getStatistics(this.startDate, this.endDate).subscribe({
      next: data => {
        this.statistics = data;
      }
    })

  }
}
