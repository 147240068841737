import { Component, OnInit } from '@angular/core';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { ConsentsBySpecialty } from '@app/core/models/input/statistics/elements-by-specialty.model';
import { StatisticsService } from '@app/core/services/statistics/statistics.service';
import { Filter } from '@app/core/models/front/filters';
import { FilterUtils } from '@app/core/utils/filter.utils';

@Component({
  selector: 'app-consents-by-specialty',
  templateUrl: './consents-by-specialty.component.html',
  styleUrls: ['./consents-by-specialty.component.scss']
})
export class ConsentsBySpecialtyComponent implements OnInit{
  faCaretDown = faCaretDown;
  faCaretUp = faCaretUp;

  consents?: ConsentsBySpecialty[];
  filters = new Array<Filter>();
    // Utils
    filterUtils: FilterUtils;
  
  constructor(private readonly statisticsService: StatisticsService, ) 
  { this.filterUtils = new FilterUtils(this.filters);}

  ngOnInit(): void {
    this.getConsentsBySpecialty();  
  }

  addFilter(name: string, filter: string) {  
    if (name === 'creationDate-start' || name === 'creationDate-end') {
      filter = this.convertDateFormat(filter);
    } 
    this.filterUtils.addFilter(name, filter);
    this.getConsentsBySpecialty();  
  }

  getConsentsBySpecialty(){
    this.statisticsService.getConsentsBySpecialty(this.filters).subscribe({
      next: respuesta => { 
       this.consents = respuesta;
      }
    })
  }

  convertDateFormat(dateString: string): string {
    const [day, month, year] = dateString.split('/');
    return `${year}-${month}-${day}`;
  }
}
