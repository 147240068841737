import { Component, Input, OnChanges, OnDestroy, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DEFAULT_ELCI_LANGUAGE, RGPD_LOGO } from '@app/core/constants/Constants';
import { Company } from '@app/core/models/input/company/company.model';
import { Variable } from '@app/core/models/input/consent/variable.model';
import { Logo } from '@app/core/models/input/logos/logo.model';
import { Patient } from '@app/core/models/input/patient/patient.model';
import { RepresentativePatientDTO } from '@app/core/models/input/patient/representative.model';
import { RgpdTemplate } from '@app/core/models/input/template/rgpd-template.model';
import { User } from '@app/core/models/input/user.model';
import { UserOutput } from '@app/core/models/output/user-output.model';
import { CompanyService } from '@app/core/services/company/company.service';
import { LoginService } from '@app/core/services/login/login.service';
import { RgpdService } from '@app/core/services/rgpd/rgpd.service';
import { TemplateService } from '@app/core/services/template/template.service';
import { VariableService } from '@app/core/services/utils/variable/variable.service';
import StringUtils from '@app/core/utils/string.utils';
import { Observable, Subscription, forkJoin, map } from 'rxjs';
import { PatientService } from '../../../../core/services/patient/patient.service';
import { PatientsShareService } from '../../service/patients-share.service';
import { VariableTypeEnum } from '@app/core/enums/superdocs.enum';

@Component({
  selector: 'app-rgpd-template',
  templateUrl: './rgpd-template.component.html',
  encapsulation: ViewEncapsulation.ShadowDom
})
export class RgpdTemplateComponent implements OnDestroy, OnChanges {
  template?: RgpdTemplate;
  selectedTemplate?: RgpdTemplate;

  @Input() patient?: Patient;
  user?: User;
  patientUuid = '';
  company?: Company;
  companyRgpdLogo?: string;
  searchingTemplateUuid: string | null = null;

  private selectedTemplateUuidSubscription?: Subscription;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly loginService: LoginService,
    private readonly rgpdService: RgpdService,
    private readonly variableService: VariableService,
    private readonly patientService: PatientService,
    private readonly companyService: CompanyService,
    private readonly patientShareService: PatientsShareService,
    private readonly templateService: TemplateService
  ) {
    this.activatedRoute.params.subscribe(param => {
      this.patientUuid = param['uuid'];
      this.user = this.loginService.userValue ?? new User();
      this.joinApiCalls();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['patient']) {
      // Execute any function you need with the new value
      this.constructVariables();
    }
  }

  joinApiCalls() {
    const sources = [
      this.getRgpdDefaultTemplate(),
      this.getCompanyByUuid(this.user?.companyUuid ?? ''),
      this.getRgpdLogo(this.user?.companyUuid ?? ''),
    ];
    forkJoin(sources)
      .pipe(
        map(([template, company, companyRgpdLogo]) => ({
          templates: template,
          company,
          companyRgpdLogo,
        }))
      )
      .subscribe(({ templates: template, company, companyRgpdLogo }) => {
        // Assign to api objects
        this.template = template as RgpdTemplate;
        this.company = company as Company;
        // RGPD Logo
        const logoRgpd = companyRgpdLogo as Logo;
        if (logoRgpd) {
          this.companyRgpdLogo = this.constructRgpdLogo(logoRgpd.format ?? '', logoRgpd.content ?? '');
        }

        this.selectedTemplate = JSON.parse(JSON.stringify(this.template));
        this.searchingTemplateUuid = this.template.uuid ?? null;
        this.templateService.setTemplate(
          JSON.parse(JSON.stringify(template))
        );

        // Set the templateUuid in the shareService
        this.patientShareService.setTemplate(this.selectedTemplate ?? new RgpdTemplate());
        // Construct variables
        this.handleSelectedTemplateChange();
        this.constructVariables();
      });
  }

  /**
   * Constructs the variables object based on available data or placeholders.
   * If the object is null, it waits and doesn't change the anchor.
   * If the object is present, it replaces values with "--".
   */
  constructVariables() {
    if (this.template) {
      // Reset the template with the variables
      this.selectedTemplate = JSON.parse(JSON.stringify(this.template));
    }

    // Body
    this.shouldShowBodySignerBlocks();
    this.showPaperForms();
    this.constructBodySigners();
    this.constructBodyCompanyVariables();
  }

  // ##########################################################################################
  // #                                                                                        #
  // #                                Variable constructors                                   #
  // #                                                                                        #
  // ##########################################################################################

  /**
   * Constructs the body content for signers based on the consent's patient and representatives,
   * if present. It determines the presence and count of representatives and constructs the body
   * content accordingly, updating the template body for each signer.
   */
  constructBodySigners(): void {
    // Check if the consent has a patient associated
    if (this.patient) {
      // Get the representatives from the patient
      const representatives = this.patient?.representativePatients;
      const firstRepresentative = representatives?.[0];
      const secondRepresentative = representatives?.[1];
      const isFirstRepresentativeActive: boolean = representatives?.[0]?.active ?? false;
      const isSecondRepresentativeActive: boolean = representatives?.[1]?.active ?? false;

      // Determine the presence and count of representatives
      const hasRepresentative =
        representatives && representatives?.length !== 0;

        const addresseeData = this.constructBodyAddresseePatient(this.patient);
        this.mapVariables(addresseeData);
        this.updateTemplateBody(addresseeData);

      // Construct and update body content for the first signer or patient
      if (
        hasRepresentative &&
        firstRepresentative &&
        isFirstRepresentativeActive
      ) {
        const firstSignerData =
          this.constructBodyFirstSignerRepresentative(firstRepresentative);
        this.mapVariables(firstSignerData);
        this.updateTemplateBody(firstSignerData);
      } else {
        const signerPatientData = this.constructBodySignerPatient(
          this.patient
        );
        this.mapVariables(signerPatientData);
        this.updateTemplateBody(signerPatientData);
      }


      const secondSignerData =
        this.constructBodySecondSignerRepresentative(secondRepresentative ?? new RepresentativePatientDTO(), isSecondRepresentativeActive);
      this.mapVariables(secondSignerData);
      this.updateTemplateBody(secondSignerData);
    } else {
      // Clean the variables in case of patient or consent undefined
      const empty = {
        signer_name_1:'',
        signer_last_name_1: '',
        signer_document_1: '',
        signer_role_1: '',
        addressee_document_type: '',
        signer_document_type_1: '',
        signer_document_type_2: '',
        signer_name_2: '',
        signer_last_name_2: '',
        signer_role_2: '',
        addressee_email: '',
        addressee_phone: '',
        addressee_phone_prefix: '',
        addressee_phone_full: '',
      };
      this.mapVariables(empty)
      this.updateTemplateBody(empty);
    }
  }

      /**
   * Constructs the body content for a signer who is the consent's patient.
   *
   * @param patient The patient for whom to construct the body content.
   * @returns A record containing the body content for the patient signer.
   */
      constructBodyAddresseePatient(patient: Patient): Record<string, string> {
        return {
          addressee_document_type: 'NIF',
          addressee_name: patient.name ?? '',
          addressee_last_name: `${patient.firstLastName} ${patient.secondLastName}`,
          addressee_document: patient.documentNumber ?? '',
          addressee_email: patient.email ?? '',
          addressee_phone: patient.mobile ?? '',
          addressee_phone_prefix: patient.phonePrefix ?? '',
          addressee_phone_full: patient.mobile ? StringUtils.getPatientFullPhone(patient) : '',
        };
      }

    /**
   * Constructs the body content for a signer who is the consent's patient.
   *
   * @param patient The patient for whom to construct the body content.
   * @returns A record containing the body content for the patient signer.
   */
    constructBodySignerPatient(patient: Patient): Record<string, string> {
      return {
        signer_name_1: patient.name ?? '',
        signer_last_name_1: StringUtils.getPatientFullLastNames(patient),
        signer_document_1: patient.documentNumber ?? '',
        signer_role_1: '',
        addressee_document_type: 'NIF',
        signer_document_type_1: 'NIF',
        signer_document_type_2: 'NIF',
      };
    }

    /**
     * Constructs the body content for the first signer who is a representative.
     *
     * @param representative The first representative for whom to construct the body content.
     * @returns A record containing the body content for the first representative signer.
     */
    constructBodyFirstSignerRepresentative(
      representative: RepresentativePatientDTO
    ): Record<string, string> {
      return {
        signer_name_1: representative.name ?? '',
        signer_last_name_1:
          StringUtils.getRepPatientFullLastNames(representative),
        signer_document_1: representative.documentNumber ?? '',
        addressee_document_type: 'NIF',
        signer_document_type_1: 'NIF',
        signer_role_1: representative.relationship ?? '',
        signer_document_type_2: 'NIF',
      };
    }

    /**
     * Constructs the body content for the second signer who is a representative.
     *
     * @param representative The second representative for whom to construct the body content.
     * @returns A record containing the body content for the second representative signer.
     */
    constructBodySecondSignerRepresentative(
      representative: RepresentativePatientDTO,
      secondRepresentativeActive: boolean
    ): Record<string, string> {
      return {
        signer_name_2: secondRepresentativeActive ? representative.name ?? '' : '',
        signer_last_name_2: secondRepresentativeActive ? StringUtils.getRepPatientFullLastNames(
          representative ?? new RepresentativePatientDTO()
        ) : '',
        signer_document_2: secondRepresentativeActive ? representative.documentNumber ?? '' : '',
        signer_role_2: secondRepresentativeActive ? representative.relationship ?? '' : '',
      };
    }


  constructBodyCompanyVariables() {
    // Check if the consent has a patient associated
    if (this.company) {
      // Define body variables related to signers data
      const bodyCompanyVariables = {
        business_name_rgpd: this.company?.businessNameFis,
        business_name: this.company?.businessName,
        cif: this.company?.cif,
        cif_rgpd: this.company?.cifFis,
        business_address_rgpd: StringUtils.getCompanyFullAddress(this.company),
        direction: StringUtils.getUserFullAddress(
          this.company?.user ?? new UserOutput()
        ),
        city_rgpd: this.company?.user?.locality,
        postal_code_rgpd: this.company?.user?.locality,
        short_address_rgpd: this.company?.user?.address,
        email: this.company?.user?.email,
        business_email_rgpd: this.company?.emailLOPD,
        url: this.company?.website,
        document_date: StringUtils.formatDate(new Date()),
        logo: this.companyRgpdLogo,
      };
      this.mapVariables(bodyCompanyVariables);
      // Update the template header with consent-related variables
      this.updateTemplateBody(bodyCompanyVariables);
    }
  }

  shouldShowBodySignerBlocks() {
    if (this.patient && this.selectedTemplate) {
      const representatives = this.patient?.representativePatients;
      // Determine whether to show the block or not
      const shouldShowBlock2 = representatives && representatives?.length === 1 && representatives?.[0]?.active;
      const shouldShowBlock3 = representatives && representatives.length === 2 && representatives?.[1]?.active;

      // Construct the modified replacement string
      const modifiedReplacementBlock2 = shouldShowBlock2
        ? `id="%block2%"`
        : `id="%block2%" style="display:none;"`;

      const modifiedReplacementBlock3 = shouldShowBlock3
        ? `id="%block3%"`
        : `id="%block3%" style="display:none;"`;

      // Get the body content with nullish coalescing for the default value
      const bodyContent = this.selectedTemplate?.body ?? '';

      const replacedContent = bodyContent
        .replace(
          /id='%block2%' style='display:none;'/g,
          modifiedReplacementBlock2
        )
        .replace(
          /id='%block3%' style='display:none;'/g,
          modifiedReplacementBlock3
        )
        .replace(
          /<img style='float: right;height: 50px;' src='/g, // Format the image
          "<img style='float: right;width: 120px;' src='"
        );

      // Save var if shouldShowBlock1
      this.mapBodyBlocks(shouldShowBlock2, 'block2');
      this.mapBodyBlocks(shouldShowBlock3, 'block3');

      this.selectedTemplate.body = replacedContent;
    }
  }

  showPaperForms() {
    if (this.selectedTemplate?.body) {
      const bodyContent = this.selectedTemplate?.body ?? '';
 
     const replacedContent = bodyContent
     .replace(
       /id='%block_form_vidsigner%'>/g,
       "id='%block_form_vidsigner%' style='display:none;'>"
     )
     .replace(
       /id='%block_form_paper%' style='display:none;'/g,
       ""
     )
     this.selectedTemplate.body = replacedContent;
    }
  
    const formVariables = this.selectedTemplate?.bodyVariables?.filter(
      (variable) => variable.type === VariableTypeEnum.FORM
    );
  
    const formValues: { [key: string]: string } = {};
  
    if (formVariables) {
      formVariables.forEach((variable) => {
        if (variable.anchor && variable.anchor ) {
          formValues[variable.anchor.replaceAll("%", "")] = variable.value as string;
        }
      });
  
      this.updateTemplateBody(formValues);
    }
  }

  private mapBodyBlocks(shouldShow: boolean | undefined, blockName: string) {
    if (shouldShow) {
      const bodyAdditionalClauseVariables = {
        [blockName]: 'true',
      };
      this.mapVariables(bodyAdditionalClauseVariables);
    }
  }

  mapVariables(bodyConsentVariables: Record<string, string | undefined>): void {
    // Iterate through each body variable in the this.selectedTemplate.bodyVariables array
    for (const bodyVariable of this.selectedTemplate?.bodyVariables ?? [
      new Variable(),
    ]) {
      // Get the name property of the current body variable
      const matchKey = bodyVariable.name ?? '';

      // Check if the matchKey exists as a key in bodyConsentVariables
      if (matchKey in bodyConsentVariables) {
        // If a match is found, assign the value from bodyConsentVariables
        // to the value property of the current body variable
        bodyVariable.value = bodyConsentVariables[matchKey] ?? '';
      }
    }
    this.updateVars(this.selectedTemplate?.bodyVariables ?? [new Variable()]);
  }

  private updateVars(vars: Variable[]) {
    this.variableService.setVars(vars);
  }

  /**
   * Updates the template body by replacing variables with their corresponding values.
   * @param variables - The variables to replace in the template body.
   */
  updateTemplateBody(variables: { [key: string]: string | undefined }) {
    if (this.selectedTemplate !== undefined) {
      this.selectedTemplate.body = this.variableService.replaceVariables(
        this.selectedTemplate.body ?? '',
        variables
      );
    }
  }

  handleSelectedTemplateChange(): void {
    this.selectedTemplateUuidSubscription =
      this.templateService.selectedTemplateUuidSubject$.subscribe(
        templateUuid => {
          if (templateUuid && this.searchingTemplateUuid !== templateUuid) {
            this.searchingTemplateUuid = templateUuid;
            this.getTemplate(templateUuid);
          }
        }
      );
  }

  getTemplate(templateUuid: string): void {
    this.templateService.getRgpdTemplateByUuid(templateUuid).subscribe({
      next: template => {
        if (template) {
          this.template = template;
          this.searchingTemplateUuid = template.uuid ?? null;
          this.templateService.setTemplate(
            JSON.parse(JSON.stringify(template))
          );
          this.constructVariables();
        }
      },
    });
  }

  private constructRgpdLogo(format: string, content: string): string {
    return `data:image/${format};base64,${content}`
  }

  private getRgpdLogo(uuid: string) {
    return this.companyService.getCompanyLogoApi(uuid, RGPD_LOGO);
  }

  private getRgpdDefaultTemplate(): Observable<RgpdTemplate> {
    return this.rgpdService.getRgpdDefaultTemplate(this.user?.language?.locale ?? DEFAULT_ELCI_LANGUAGE);
  }

  private getPatientByUuid(patientUuid: string): Observable<Patient> {
    return this.patientService.getPatientByUuid(patientUuid);
  }

  private getCompanyByUuid(companyUuid: string): Observable<Company> {
    return this.companyService.getCompany(companyUuid);
  }

  ngOnDestroy(): void {
    if (this.selectedTemplateUuidSubscription) {
      this.selectedTemplateUuidSubscription.unsubscribe();
    }
  }

}
