import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Filter } from '@app/core/models/front/filters';
import { Pagination } from '@app/core/models/front/pagination';
import { Sorters } from '@app/core/models/front/sorters';
import { Page } from '@app/core/models/input/page/pages.model';
import { Specialty } from '@app/core/models/input/specialty.model';
import {
  ApiDocumentsByCompany,
  DdsByCategoryCompany,
  RgpdsByCompany,
  SignedDocumentsByCompany,
} from '@app/core/models/input/statistics/documents-generated.model';
import { ConsentsBySpecialty, DoctorsBySpecialty } from '@app/core/models/input/statistics/elements-by-specialty.model';
import { Statistics } from '@app/core/models/input/statistics/statistics.model';
import { ConsentsMostUsed } from '@app/core/models/input/statistics/top-consents.model';
import { EnvironmentService } from '@app/environment.service';
import { UtilsService } from '../utils/utils.service';

@Injectable({
  providedIn: 'root',
})
export class StatisticsService {
  constructor(
    private readonly http: HttpClient,
    private readonly env: EnvironmentService,
    private readonly uSrv: UtilsService
  ) {}

  getStatistics(startDate: string | null, endDate: string | null) {
    return this.http.get<Statistics>(`${this.env.apiUrl}/statistics?startDate=${startDate}&endDate=${endDate}`);
  }
  getTotalConsentsByDoctor(pagination: Pagination, filters: Filter[], sorters: Sorters) {
    return this.http.get<Page>(
      `${this.env.apiUrl}/statistics/sudo/doctor-consents?` +
        this.uSrv.genPaginationInUri(pagination) +
        this.uSrv.getFiltersInUri(filters) +
        this.uSrv.genSortersInUri(sorters)
    );
  }

  getDoctorsBySpecialty() {
    return this.http.get<DoctorsBySpecialty[]>(`${this.env.apiUrl}/statistics/sudo/doctors-by-specialty`);
  }
  getConsentsBySpecialty(filters: Filter[]) {
    return this.http.get<ConsentsBySpecialty[]>(
      `${this.env.apiUrl}/statistics/sudo/consents-by-specialty?` + this.uSrv.getFiltersInUri(filters)
    );
  }
  getConsentsMostUsed(filters: Filter[]) {
    return this.http.get<ConsentsMostUsed[]>(
      `${this.env.apiUrl}/statistics/sudo/generated-consents?` + this.uSrv.getFiltersInUri(filters)
    );
  }

  getRGPDsByCompany(filters: Filter[]) {
    return this.http.get<RgpdsByCompany[]>(
      `${this.env.apiUrl}/statistics/sudo/generated-rgpds-by-company?` + this.uSrv.getFiltersInUri(filters)
    );
  }
  getDDsByCategory(filters: Filter[]) {
    return this.http.get<DdsByCategoryCompany[]>(
      `${this.env.apiUrl}/statistics/sudo/generated-dds-by-category-company?` + this.uSrv.getFiltersInUri(filters)
    );
  }
  getSignaturesByCompany(filters: Filter[]) {
    return this.http.get<SignedDocumentsByCompany>(
      `${this.env.apiUrl}/statistics/sudo/signature-totals-by-company?` + this.uSrv.getFiltersInUri(filters)
    );
  }
  getApiDocumentsByCompany(filters: Filter[]) {
    return this.http.get<ApiDocumentsByCompany[]>(
      `${this.env.apiUrl}/statistics/sudo/api-documents-totals-by-company?` + this.uSrv.getFiltersInUri(filters)
    );
  }

  getSpecialties() {
    return this.http.get<Specialty[]>(`${this.env.apiUrl}/consents/specialties`);
  }

  // TODO debemos pensar como hacer la consulta dinámica por idioma
  getProcedures(pagination: Pagination, filters: Filter[], sorters: Sorters) {
    return this.http.get<Page>(
      `${this.env.apiUrl}/consents/procedures?` +
        this.uSrv.genPaginationInUri(pagination) +
        this.uSrv.getFiltersInUri(filters) +
        this.uSrv.genSortersInUri(sorters)
    );
  }
}
