import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { State } from '@app/core/models/input/state.model';
import { EnvironmentService } from '@app/environment.service';

@Injectable({
  providedIn: 'root'
})
export class StateService {

  constructor(
    private readonly http: HttpClient,
    private readonly env: EnvironmentService,
  ) { }

  getStateByCountryId(id: number) {
    return this.http.get<State[]>(`${this.env.apiUrl}/public/states/${id}`);
  }

}
