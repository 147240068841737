export const MIN_PASSWORD_LENGTH = 8;
export const DEFAULT_PAGE_SIZE = 20;
export const DEFAULT_PAGE = 0;
export const DESC = "DESC";
export const ASC = "ASC";
export const RGPD_LOGO = "RGPD";
export const MAIN_LOGO = "MAIN";
export const PATTERN_DATE_MINUTES = "yyyy-MM-dd HH:mm:ss";
export const PATTERN_DATE_MINUTES_ZONE = "yyyy-MM-dd'T'HH:mm:ss.SSSZ";
export const DEFAULT_TEMPLATE_CI = "DEFAULT_CI";
export const DEFAULT_TEMPLATE_RGPD = "DEFAULT_RGPD";
export const DEFAULT_ELCI_LANGUAGE = "es_ES";
export const DEFAULT_ELCI_SPECIALTY_LANG = "es";

// Storage keys
export const APP_LANGUAGES = "languages"
export const STORAGE_KEY_LOCALE = "locale"
export const CI_LANGUAGES = "ciTemplateLanguages"
export const RGPD_LANGUAGES = "rgpdTemplateLanguages"

export const MAX_FILE_SIZE = 250000;

export const SPECIALITY_CLASSES: { [className: string]: Set<number> } = {
  one: new Set([31, 5, 2, 1, 3, 49, 25, 4, 35]),
  two: new Set([7, 27, 10, 29, 33, 32, 26, 44, 36, 21, 20]),
  three: new Set([30, 8, 34, 24, 42, 37, 22]),
};

