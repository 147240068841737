import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import OutputTypeEnum from '@app/core/enums/output-type.enum';
import StatusEnum from '@app/core/enums/status.enum';
import { Status } from '@app/core/models/input/common/status.model';
import { Consent } from '@app/core/models/input/consent/consent.model';
import { ConsentService } from '@app/core/services/consent/consent.service';
import { faCircleExclamation, faEnvelope, faFileCircleCheck, faFileImport, faFileSignature, faFolderOpen, faPrint, faTabletScreenButton } from '@fortawesome/free-solid-svg-icons';
import { OutputType } from '../../../../core/models/input/common/output-type.model';

@Component({
  selector: 'app-statuses',
  templateUrl: './statuses.component.html'
})
export class StatusesComponent {

  constructor(
    private readonly consentService: ConsentService,
    private readonly router: Router,
  ) {

  }

  faFileSignature = faFileSignature;
  faFileImport = faFileImport;
  faCircleExclamation = faCircleExclamation;
  faTabletScreenButton = faTabletScreenButton;
  faPrint = faPrint;
  faFolderOpen = faFolderOpen;
  faFileCircleCheck = faFileCircleCheck;
  faEnvelope = faEnvelope;


  @Input() status?: Status;
  @Input() outputType?: OutputType;
  @Input() objectType?: string;
  @Input() consent?: Consent;

  Status = StatusEnum
  OutputType = OutputTypeEnum

  goToConsentDetail(consent: Consent| undefined) {
    // Only go to consent if is consent
    if (consent) {
      this.consentService.setConsent(consent);
      this.router.navigate([`/portal/consents/${consent.uuid}`]);
    }
  }

}
