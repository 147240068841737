import { Component, Input } from '@angular/core';
import { CompanyDataAdmin } from '@app/core/models/input/company/company.model';

@Component({
  selector: 'app-header-company',
  templateUrl: './header-company.component.html',
  styleUrls: ['./header-company.component.scss']
})
export class HeaderCompanyComponent {
  @Input() companyData?: CompanyDataAdmin;
}
