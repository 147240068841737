import { Component, EventEmitter, Input, Output } from '@angular/core';
import { COMPANY_ROLES } from '@app/core/constants/Permissions';
import RoleEnum from '@app/core/enums/role.enum';
import { UserInput } from '@app/core/models/input/user-input.model';
import { faBackward, faFileCirclePlus } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-file-administrator',
  templateUrl: './file-administrator.component.html',
  styleUrls: ['./file-administrator.component.scss']
})
export class FileAdministratorComponent {
  // fontawesome
  faFileCirclePlus = faFileCirclePlus;
  faBackward = faBackward;
  userData?: UserInput;
  roleEnum = RoleEnum;
  @Input() roleUser?: string;
  @Output() dataAdmin = new EventEmitter<UserInput>();

  // Roles
  COMPANY_ROLES = COMPANY_ROLES;

  receiveAdminData(data:UserInput){
    this.userData = data;
    this.dataAdmin.emit(data);
   
    
  }
}
