<div class="wrapper">
    <div class="cabecera">
        <img class="movil" src="../../../assets/img/catalogo/cabecera-movil.jpg" alt="Catálogo de el Consentimiento">
        <img class="desktop" src="../../../assets/img/catalogo/cabecera.jpg" alt="Catálogo de el Consentimiento">
        <h1 class="titulo_seccion tipo-second-color bold">Catálogo</h1>
    </div>
    <div class="maxwidth">      
        <app-search></app-search>
        <div class="results" *ngFor="let speciality of specialitiesStats">
            <div class="title-specialty">
                <h2 class="specialy tipo-second-color bold" >{{speciality.name}}</h2>
                <h2 class="tipo-second-color"><span class="bold">{{speciality?.procedureCount}}</span> procedimientos</h2>         
            </div>
            <ul class="procedures" >
                <li class="procedure tipo main color" *ngFor= "let procedure of speciality.procedures" [routerLink]="[ '/catalogo/procedure-code/'+ procedure.code ]">{{procedure.title}}</li>           
            </ul>
        </div>
       
        <div class="buttons">
            <button class="button back-blue-color tipo-white-color"  [routerLink]="['/catalogo']">Volver al catálogo</button>
        </div>
    </div>
    <div class="pie">
        <img class="movil" src="../../../assets/img/catalogo/footer-movil.jpg" alt="Catálogo de el Consentimiento">
        <img class="desktop" src="../../../assets/img/catalogo/footer.jpg" alt="Catálogo de el Consentimiento">
    </div>
</div>   


