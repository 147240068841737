import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Country } from '@app/core/models/input/country.model';
import { EnvironmentService } from '@app/environment.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  constructor(
    private readonly http: HttpClient,
    private readonly env: EnvironmentService,
  ) { }

  getCountries(): Observable<Country[]> {
    return this.http.get<Country[]>(`${this.env.apiUrl}/public/countries`);
  }

}
