<div class="back-white-color box_title">
    <h4 class="subtitle tipo-second-color regular center">{{'SUPER-ADMIN.CLIENT.DATA-CONTACT.TITLE-DATA-CONTACT' | translate}}</h4>
</div>
<div class="box-container">
    <div class="box">
        <form [formGroup]="dataContact">
            <div class="form">
                <div class="row">                    
                    <div class="input">
                        <label for="address">*{{'SUPER-ADMIN.CLIENT.DATA-CONTACT.LABEL-ADDRESS' | translate}}</label>
                        <input type="text" id="address" formControlName="address" [ngClass]="{ wrong: shouldShowError('address') }">
                    </div>
                    <p
                    class="message_error wrongtext small"
                    *ngIf="this.dataContact.get('address')?.errors?.['required'] && this.isSubmit">
                    {{'SUPER-ADMIN.CLIENT.DATA-CONTACT.MESSAGE-ERROR-ADDRESS' | translate}}
                  </p>

                    <div class="input">
                        <label for="postal_code">*{{'SUPER-ADMIN.CLIENT.DATA-CONTACT.LABEL-POSTAL-CODE' | translate}}</label>
                        <input type="text" id="postal_code" formControlName="postCode" [ngClass]="{ wrong: shouldShowError('postCode') }">
                    </div>
                    <p
                    class="message_error wrongtext small"
                    *ngIf="this.dataContact.get('postCode')?.errors?.['pattern'] && this.isSubmit">
                    {{'SUPER-ADMIN.CLIENT.DATA-CONTACT.MESSAGE-ERROR-POSTAL-CODE' | translate}}
                  </p>
                    <div class="input">
                        <label for="email_company">*{{'SUPER-ADMIN.CLIENT.DATA-CONTACT.LABEL-EMAIL' | translate}}</label>
                        <input type="email" id="email_company" formControlName="email" [ngClass]="{ wrong: shouldShowError('email') }"> 
                    </div>
                    <p
                    class="message_error wrongtext small"
                    *ngIf="this.dataContact.get('email')?.errors?.['pattern'] && this.isSubmit">
                    {{'SUPER-ADMIN.CLIENT.DATA-COMPANY.MESSAGE-ERROR-EMAIL-AGENT' | translate}}
                  </p>
                    <div class="input">
                        <label for="second_phone">*{{'SUPER-ADMIN.CLIENT.DATA-CONTACT.LABEL-SECOND-PHONE' | translate}}</label>
                        <input type="tel" id="second_phone" formControlName="phone">
                        <span class="small tipo-second-color">{{'SUPER-ADMIN.CLIENT.DATA-CONTACT.MESSAGE-ERROR-MOBILE' | translate}}</span>                       
                    </div>
                </div>
               <div class="row">
                <div class="input">
                    <label for="locality">*{{'SUPER-ADMIN.CLIENT.DATA-CONTACT.LABEL-CITY' | translate}}</label>
                    <input type="text" id="locality" formControlName="locality" [ngClass]="{ wrong: shouldShowError('locality') }">                    
                </div>  
                <p
                class="message_error wrongtext small"
                *ngIf="this.dataContact.get('locality')?.errors?.['required'] && this.isSubmit">
                {{'SUPER-ADMIN.CLIENT.DATA-CONTACT.MESSAGE-ERROR-CITY' | translate}}
              </p>

                <div class="input">
                    <label for="stateId">*{{'SUPER-ADMIN.CLIENT.DATA-CONTACT.LABEL-PROVINCE'| translate}}</label>
                    <select formControlName="stateId" id="stateId" [(ngModel)]="selectValue" [ngClass]="{ wrong: shouldShowError('stateId') }">
                        <option [ngValue]="null"></option>
                        <option value="{{state.id}}" *ngFor= "let state of states; let i=index">{{state.name}}</option>                                             
                    </select>
                </div>
                <p
                class="message_error wrongtext small"
                *ngIf="this.dataContact.get('stateId')?.errors?.['required'] && this.isSubmit">
                {{'SUPER-ADMIN.CLIENT.DATA-CONTACT.MESSAGE-ERROR-PROVINCE' | translate}}
              </p>
                <div class="input">
                    <label for="mobile">*{{'SUPER-ADMIN.CLIENT.DATA-CONTACT.LABEL-MOBILE' | translate}}</label>
                    <input type="tel" id="mobile" formControlName="mobile" [ngClass]="{ wrong: shouldShowError('mobile') }">
                    <span class="small tipo-second-color">{{'SUPER-ADMIN.CLIENT.DATA-CONTACT.MESSAGE-MOBILE' | translate}}</span>
                </div>
                <p
                    class="message_error wrongtext small"
                    *ngIf="this.dataContact.get('mobile')?.errors?.['pattern'] && this.isSubmit">
                    {{'SUPER-ADMIN.CLIENT.DATA-CONTACT.MESSAGE-ERROR-MOBILE' | translate}}
                  </p>
               </div>
            </div>          
          
           <div class="buttons">
            <button class="button tipo-white-color back-blue-color" (click)="togglePagesThreeFour()">
                <fa-icon [icon]="faCircleArrowLeft" class="icono"></fa-icon>
               {{'SUPER-ADMIN.CLIENT.DATA-COMPANY.BUTTON-BACK' | translate}}
            </button>
            <button class="button tipo-white-color back-blue-color" (click)="onSubmit()" >                
               {{'SUPER-ADMIN.CLIENT.DATA-HIRING.BUTTON-CONTINUE' | translate}}
               <fa-icon [icon]="faCircleArrowRight" class="icono"></fa-icon>
            </button>
           </div>
        </form>
    </div> 
 </div>


