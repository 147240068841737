import { Component, OnDestroy, OnInit } from '@angular/core';
import { Consent } from '@app/core/models/input/consent/consent.model';
import { RepresentativePatientDTO } from '@app/core/models/input/patient/representative.model';
import { ConsentService } from '@app/core/services/consent/consent.service';
import { Subscription } from 'rxjs';
import DigitalCiSignatureSignTypeEnum from '../../../../core/enums/digital-ci-signature-sign-type.enum';

@Component({
  selector: 'app-legal-representatives-signs',
  templateUrl: './legal-representative-signs.component.html',
  styleUrls: ['./legal-representative-signs.component.scss'],
})
export class LegalRepresentativeSignsComponent implements OnInit, OnDestroy {
  DigitalCiSignatureSignTypeEnum = DigitalCiSignatureSignTypeEnum;

  consent?: Consent;

  representatives?: RepresentativePatientDTO[];
  isSecondRepresentativeActive?: boolean = false;

  // Subscriptions
  private consentSubscription?: Subscription;

  digitalCiSignatureSignType = DigitalCiSignatureSignTypeEnum.TOGETHER;

  constructor(private readonly consentService: ConsentService) {}

  ngOnInit(): void {
    this.handleConsentChange();
  }

  // This are the entry point when we are using the consentSubscription
  handleConsentChange(): void {
    this.consentSubscription = this.consentService.consent$.subscribe(
      consent => {
        if (consent) {
          this.consent = consent;
          // This method construct the clause variables
          this.representatives = this.consent?.patient?.representativePatients;
          this.isSecondRepresentativeActive =
          this.consent?.patient?.representativePatients?.[1]?.active;

          // Check if the digitalCiSignatureSignType is the same and if isSecondRepresentativeActive
          const secondRepresentativePresentAndActive = this.representatives && this.isSecondRepresentativeActive && this.representatives?.length === 2;
          const digitalCiSignatureSignTypeNotEqual = this.consent?.digitalCiSignatureSignTypeId != this.digitalCiSignatureSignType

          if (digitalCiSignatureSignTypeNotEqual && secondRepresentativePresentAndActive) {
            this.consent.digitalCiSignatureSignTypeId = this.digitalCiSignatureSignType;
            this.consentService.setConsent(this.consent);
          }
        }
      }
    );
  }

  changeSignatureType(newSignatureType: number) {
    this.digitalCiSignatureSignType = newSignatureType;

    if (this.consent) {
      this.consent.digitalCiSignatureSignTypeId = this.digitalCiSignatureSignType;
      this.consentService.setConsent(this.consent);
    }
  }

  ngOnDestroy(): void {
    if (this.consentSubscription) {
      this.consentSubscription.unsubscribe();
    }
  }
}
