import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DEFAULT_ELCI_LANGUAGE, DEFAULT_TEMPLATE_CI } from '@app/core/constants/Constants';
import StatusEnum from '@app/core/enums/status.enum';
import { Language } from '@app/core/models/input/language.model';
import { TemplateLiteInputDTO } from '@app/core/models/input/template/template-lite.model';
import { User } from '@app/core/models/input/user.model';
import { LanguageService } from '@app/core/services/language/language.service';
import { LoginService } from '@app/core/services/login/login.service';
import { RoleService } from '@app/core/services/role/role.service';
import { TemplateService } from '@app/core/services/template/template.service';
import { fa5, faBackward, faHardDrive, faHouseMedicalCircleCheck, faMaximize, faMinimize, faRectangleXmark } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Consent } from '../../../../core/models/input/consent/consent.model';
import { DataConsentComponent } from '../../components/data-consent/data-consent.component';
import { GenConsentComponent } from '../../components/gen-consent/gen-consent.component';
import { ConsentsShareService } from '../../services/consents-share.service';

@Component({
  selector: 'app-new-consent',
  templateUrl: './new-consent.component.html',
  styleUrls: ['./new-consent.component.scss']
})
export class NewConsentComponent implements OnInit, OnDestroy {
  @ViewChild(DataConsentComponent) dataConsentComponent?: DataConsentComponent;
  @ViewChild(GenConsentComponent) genConsentComponent?: GenConsentComponent;

  faMaximize = faMaximize;
  faHardDrive = faHardDrive;
  faHouseMedicalCircleCheck = faHouseMedicalCircleCheck;
  faRectangleXmark = faRectangleXmark;
  faBackward = faBackward;
  faMinimize = faMinimize;
  fa5 = fa5;


  @Input() consent?: Consent;

  user?: User;
  isMax = false;
  isButtonChecked = false;
  isApp = true;
  isQuickapi = false

  selectedTemplate: string | null = ""
  selectedTemplateLanguage = DEFAULT_ELCI_LANGUAGE

  templates?: TemplateLiteInputDTO[];
  languages?: Language[];

  private selectedTemplateLanguageSubscription?: Subscription;
  private selectedTemplateUuidSubscription?: Subscription;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly translate: TranslateService,
    private readonly roleService: RoleService,
    private readonly templateService: TemplateService,
    private readonly loginService: LoginService,
    private readonly languageService: LanguageService,
    private readonly consentsShareService: ConsentsShareService,
  ) {
    // Get user
    this.user = this.loginService.userValue ?? new User();
  }


  ngOnInit(): void {
    this.initializeSubscriptions();
    this.quickApiChecks();

    // Api calls
    this.getLanguages();  
  }  

  private initializeSubscriptions() {
    this.handleSelectedTemplateLanguageChange();
    this.handleSelectedTemplateChange();
  }

  private quickApiChecks() {
    if (this.roleService.isQuickapi()){
      this.isApp = false;
      this.isQuickapi = true;
    }
  }

  changeTemplateLanguage(locale: string) {
    this.getTemplateList(locale);
  }

  changeSelectedTemplate(templateUUid: string | null) {
    this.templateService.setSelectedTemplateUuid(templateUUid);
  }

  onConsentGenerated(isButtonChecked:boolean){
    this.isButtonChecked = isButtonChecked
  }

  submitDataConsentFormDraft() {
    this.dataConsentComponent?.setConsentStatusId(StatusEnum.DRAFT);
    this.submitDataConsentForm();
  }

  submitDataConsentForm() {
    // Call a method in the child component to submit the form
    this.genConsentComponent?.onSubmit();
    this.dataConsentComponent?.onSubmit();
  }

  cancel() {
    if (this.roleService.isQuickapi()){
      window.close();
    }else{
      this.router.navigate(['../'], {relativeTo: this.route});
    }

  }

  maximizeTemplate() {
    this.isMax = !this.isMax;
  }

  // Api calls

  getTemplateList(locale: string): void {
    this.templateService.getCiTemplates(locale).subscribe({
      next: templates => {
        if (templates) {
          this.templates = templates
          // The selected template order is CompanyLocale -> CompanyTemplate -> ElciDefaultTemplate
          if (templates.length > 1) {
            this.selectedTemplate = templates.filter(template => template.type !== DEFAULT_TEMPLATE_CI)[0].uuid ?? null;
          } else {
            this.selectedTemplate = templates[0].uuid ?? null;
          }
          this.changeSelectedTemplate(this.selectedTemplate)
          // Translate the DEFAULT_CI value
          this.templates.forEach((item, index) => {
            if (item.type === DEFAULT_TEMPLATE_CI) {
              this.translate.get(item.type).subscribe((res: string) => {
                if (this.templates?.[index]) {
                  this.templates[index].type = res;
                }
              });
            }
          });
        }
      }
    })
  }

  getLanguages(): void {
    this.languageService.getCiTemplateLanguagesApi().subscribe({
      next: languages => {
        if (languages && languages.length > 0) {
          this.languageService.setCiTemplateLanguages(languages);
          this.languages = languages;

          // Find user's language in the list, or use the first one from the list
          const selectedLanguageObj = languages.find(lang => lang.locale === this.user?.language?.locale) ?? languages[0];

          // Extract the locale (string) from the selected language object
          const selectedLanguageLocale = selectedLanguageObj ? selectedLanguageObj.locale : DEFAULT_ELCI_LANGUAGE;

          // Check if selectedLanguageLocale is set, otherwise use DEFAULT_ELCI_LANGUAGE
          this.selectedTemplateLanguage = selectedLanguageLocale ?? DEFAULT_ELCI_LANGUAGE;

        } else {
          // If no languages are returned from the backend, use DEFAULT_ELCI_LANGUAGE
          this.selectedTemplateLanguage = DEFAULT_ELCI_LANGUAGE;
        }
        this.getTemplateList(this.selectedTemplateLanguage);
      }
    })
  }

  // Subscriptions

  handleSelectedTemplateLanguageChange(): void {
    this.selectedTemplateLanguageSubscription =
      this.consentsShareService.templateLanguage$.subscribe(
        locale => {
          if (locale && this.selectedTemplateLanguage !== locale && locale != '') {
            this.selectedTemplateLanguage = locale;
            this.changeTemplateLanguage(locale);
          }
        }
      );
  }

  handleSelectedTemplateChange(): void {
    this.selectedTemplateUuidSubscription =
      this.templateService.selectedTemplateUuidSubject$.subscribe(
        templateUuid => {
          if (templateUuid) {
            this.selectedTemplate = templateUuid;
          }
        }
      );
  }

  ngOnDestroy(): void {
    if (this.selectedTemplateLanguageSubscription) {
      this.selectedTemplateLanguageSubscription.unsubscribe();
    }
    if (this.selectedTemplateUuidSubscription) {
      this.selectedTemplateUuidSubscription.unsubscribe();
    }
  }

}
