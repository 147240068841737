import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-message-doc-ended',
  templateUrl: './message-doc-ended.component.html',
  styleUrls: ['./message-doc-ended.component.scss']
})
export class MessageDocEndedComponent implements OnInit {
  faCircleXmark = faCircleXmark;
  document?: string;
  patientName?: string;
  actionDoc?: string;
  userNotAuthorized = false;

  constructor(private readonly route: ActivatedRoute) { }

  ngOnInit() {
      this.document = this.route.snapshot.queryParamMap.get('document') ?? "";
      this.patientName = this.route.snapshot.queryParamMap.get('patientName') ?? "";
      this.actionDoc = this.route.snapshot.queryParamMap.get('actionDoc') ?? "";     
      this.userNotAuthorized = this.document === 'unauthorized';
            
  }

  close(){
    window.close();
  }
}
