import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { faFileExport, faPenToSquare, faTrashCan } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-data-professional',
  templateUrl: './data-professional.component.html',
  styleUrls: ['./data-professional.component.scss']
})
export class DataProfessionalComponent {
  faPenToSquare = faPenToSquare;
  faTrashCan = faTrashCan; 
  faFileExport = faFileExport;

    // Inputs
    @Input()
    inEdit?: boolean;
  
    @Input()
    isFieldsDisabled = false; 

    dataProfessional!: FormGroup;
    inEdition = false; 
    isSubmit = false;


constructor()  {
    this.dataProfessional = this.initForm();
  }
  
  
  // función que valida cada dato del formulario
  shouldShowError(inputControlName: string): boolean {
    const control = this.dataProfessional.get(inputControlName);
    return !!(control && control.touched && control.invalid);
  }

  private initForm(): FormGroup {
    // Validamos e inicializamos formulario
   return new FormGroup({         
      statusCredential: new FormControl({ value: '', disabled: false }, []),
      dateOnCredential: new FormControl({ value: '', disabled: false }, []),
      dateOffCredential: new FormControl({ value: '', disabled: false }, []),
      status: new FormControl({ value: '', disabled: false }, []),    
      digitalSign: new FormControl({ value: '', disabled: false }, []),
    });
    
  }

  changeEdit() {
    this.isFieldsDisabled = !this.isFieldsDisabled; 
    this.toggleForm();    
  }

  private toggleForm() {
    if (this.isFieldsDisabled) {
      this.dataProfessional.disable();
      this.inEdition = false;
    } else {
      this.dataProfessional.enable();
      this.inEdition = true;
    }
  }
}
