import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Device } from '@app/core/models/input/device.model';
import { Patient } from '@app/core/models/input/patient/patient.model';
import { User } from '@app/core/models/input/user.model';
import { ReSendDocumentDTO } from '@app/core/models/output/consent/re-send-documentDTO.model';
import { AlertService } from '@app/core/services/alert/alert.service';
import { ConsentService } from '@app/core/services/consent/consent.service';
import { LoginService } from '@app/core/services/login/login.service';
import { RoleService } from '@app/core/services/role/role.service';
import { faPaperPlane, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { DeviceService } from '../../../../core/services/device/device.service';

@Component({
  selector: 'app-pending-bio-consent',
  templateUrl: './pending-bio-consent.component.html',
  styleUrls: ['./pending-bio-consent.component.scss']
})
export class PendingBioConsentComponent implements OnInit {
  faPaperPlane = faPaperPlane;
  faSpinner = faSpinner;

  sendingToRatify = false;

  @Input() patient?: Patient;

  devices?: Device[];
  selectedDevice?: string = '0';
  consentUuid?: string;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly deviceService: DeviceService,
    private readonly loginService: LoginService,
    private readonly consentService: ConsentService,
    private readonly alertService: AlertService,
    private readonly translate: TranslateService,
    private readonly roleService: RoleService
    ) {
      this.activatedRoute.params.subscribe(param => {
        this.consentUuid = param['consentUuid'];
      });
    }

  ngOnInit(): void {
    const user = this.loginService.userValue ?? new User();

    this.deviceService
    .getUserDevices()
    .subscribe({
      next: devices => {
        this.deviceService.setDevices(devices)
        this.devices = devices;
        this.selectedDevice = user.userDevice?.description;
      },
    });
  }

  ratifyConsent() {
    this.sendingToRatify = true;
    if (this.consentUuid) {
      const dataResend: ReSendDocumentDTO = {
        deviceName: this.selectedDevice,
      };
      dataResend.deviceName = this.selectedDevice;
      if (this.devices !== undefined) {
        this.consentService.ratifyConsent(this.consentUuid, dataResend).subscribe({
          next: () => {
            this.sendingToRatify = false;
            this.alertService.success(
              this.translate.instant(
                'PRIVATE.CONSENTS.PENDINGBIO-CONSENT.MESSAGE-RATIFY'
              )
            );
            if (this.roleService.isQuickapi()){
              const patientName =  `${this.patient?.name ?? ''} ${this.patient?.firstLastName ?? ''} ${ this.patient?.secondLastName ?? '' }`
              this.router.navigate(['/portal/quick-api/patients/doc-ok'], {queryParams: { document:this.translate.instant('QUICKAPI.QUERY-PARAMS-DOCUMENT-CI'), patientName: patientName, actionDoc: this.translate.instant('QUICKAPI.QUERY-PARAMS-SEND')  }});
            }else{
              this.router.navigate(['../'], { relativeTo: this.activatedRoute });
            }
          },
          error: () => {
            this.sendingToRatify = false;
          }
        });
      }
    }
  }
}
