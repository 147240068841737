import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { NgxTurnstileModule } from 'ngx-turnstile';
import { CookieServiceComponent } from './components/cookie-service/cookie-service.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { SearchComponent } from './components/search/search.component';
import { AvisoLegalComponent } from './pages/avisolegal/aviso-legal.component';
import { CatalogoComponent } from './pages/catalogo/catalogo.component';
import { LoginComponent } from './pages/clientes/login.component';
import { ComofuncionaComponent } from './pages/comofunciona/comofunciona.component';
import { ContactoComponent } from './pages/contacto/contacto.component';
import { CookiesComponent } from './pages/cookies/cookies.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginAdministrationComponent } from './pages/login-administration/login-administration.component';
import { PoliticaComponent } from './pages/politica/politica.component';
import { ProcedureDetailComponent } from './pages/procedure-detail/procedure-detail.component';
import { ProceduresSearchComponent } from './pages/procedures-search/procedures-search.component';
import { QueesComponent } from './pages/quees/quees.component';
import { QuienessomosComponent } from './pages/quienessomos/quienessomos.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { PublicRoutingModule } from './public-routing.module';
import { PublicComponent } from './public.component';

@NgModule({
  declarations: [
    HomeComponent,
    QueesComponent,
    ComofuncionaComponent,
    QuienessomosComponent,
    CatalogoComponent,
    ContactoComponent,
    LoginComponent,
    AvisoLegalComponent,
    PoliticaComponent,
    CookiesComponent,
    FooterComponent,
    HeaderComponent,
    CookieServiceComponent,
    PublicComponent,
    ResetPasswordComponent,
    LoginAdministrationComponent,
    ProcedureDetailComponent,
    SearchComponent,
    ProceduresSearchComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    PublicRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxTurnstileModule
  ]
})
export class PublicModule { }
