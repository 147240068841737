import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PatientService } from '@app/core/services/patient/patient.service';
import { faHospitalUser } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-gen-docs-quick',
  templateUrl: './gen-docs-quick.component.html',
  styleUrls: ['./gen-docs-quick.component.scss']
})
export class GenDocsQuickComponent implements OnInit {
  faHospitalUser = faHospitalUser;

  patientUuid?: string;
  patientname?: string;
  patientfirstLastName?: string;
  patientsecondLastName?: string;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly patientService: PatientService,

  ) {
    this.activatedRoute.params.subscribe(params => {
      this.patientUuid = params['uuid'];

    });
  }



  ngOnInit(): void {
    this.patientService.getPatientByUuid(this.patientUuid ?? '').subscribe({
      next: data => {
        this.patientname = data.name;
        this.patientfirstLastName = data.firstLastName;
        this.patientsecondLastName = data.secondLastName;
      },
    });
  }

}
