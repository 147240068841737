<div class="wrapper">
  <div class="cabecera">
    <img class="movil" src="../../../assets/img/catalogo/cabecera-movil.jpg" alt="Catálogo de el Consentimiento" />
    <img class="desktop" src="../../../assets/img/catalogo/cabecera.jpg" alt="Catálogo de el Consentimiento" />
    <h1 class="titulo_seccion tipo-second-color bold">Catálogo</h1>
  </div>
  <div class="maxwidth">
    <div class="contenido tipo-second-color bold">
      <h1 class="total center tipo-main-color">
        {{ specialities?.totalProceduresCount }} consentimientos de {{ specialities?.totalSpecialitiesCount }} especialidades
      </h1>
     <app-search (specialitiesEvent)="handleChildSpecialities($event)"></app-search>
      <div class="specialities">
        <span
        *ngFor="let speciality of specialities?.specialities"
        [routerLink]="['./' + speciality.id]"
        class="especialidad"
        [ngClass]="putClassBySpecialityId(speciality.id)">
        {{ speciality.name }}
      </span>
      </div>
     
    </div>
  </div>
  <div class="pie">
    <img class="movil" src="../../../assets/img/catalogo/footer-movil.jpg" alt="Catálogo de el Consentimiento" />
    <img class="desktop" src="../../../assets/img/catalogo/footer.jpg" alt="Catálogo de el Consentimiento" />
  </div>
</div>
