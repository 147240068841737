import { Component, Input} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import StatusEnum from '@app/core/enums/status.enum';
import { Filter } from '@app/core/models/front/filters';
import { ConsentsByStatuses } from '@app/core/models/input/statistics/consents-by-statuses.model';


@Component({
  selector: 'app-consents-state',
  templateUrl: './consents-state.component.html',
  styleUrls: ['./consents-state.component.scss'],
})
export class ConsentsStateComponent  {
  @Input() consentsByStatuses?: ConsentsByStatuses[];

  // enum
  status = StatusEnum;


  constructor(private readonly router: Router,
              private readonly activatedRoute: ActivatedRoute) {}

 
  goConsent(statusId:number){
    const filter = new Filter('statusId', statusId.toString())
    this.router.navigate(
    ['consents'],
    { queryParams: { filter: JSON.stringify(filter) }, relativeTo: this.activatedRoute,}

    );
    }
}
