import { Injectable } from '@angular/core';
import { SpecialityProcedurePublicOutputDTO } from '@app/core/models/front/public';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CatalogueSearchService {
  private readonly proceduresSource = new BehaviorSubject<SpecialityProcedurePublicOutputDTO[] | null>(null);
  currentProcedures: Observable<SpecialityProcedurePublicOutputDTO[] | null> = this.proceduresSource.asObservable();
 
  updateProcedures(procedures: SpecialityProcedurePublicOutputDTO[]) {
    this.proceduresSource.next(procedures);
  }
}
