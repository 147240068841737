import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Clause } from '@app/core/models/input/procedure/clause.model';
import { EnvironmentService } from '@app/environment.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClausesService {

  constructor( 
    private readonly http: HttpClient,
    private readonly env: EnvironmentService
  ) { }


  getClausesByProcedure(procedureId: string): Observable<Clause[]> {
    return this.http.get<Clause[]>(`${this.env.apiUrl}/consents/clauses/procedure/`+procedureId);
  }

}
