import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { faUserDoctor } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  faUserDoctor = faUserDoctor;
  @ViewChild('header') header!: ElementRef;
  @ViewChild('header_button') headerbutton!: ElementRef;

  constructor(private readonly renderer: Renderer2) {}

  addClassHeaderBlanco() {
    this.renderer.addClass(this.header.nativeElement, 'headerblanco');
    this.renderer.addClass(this.headerbutton.nativeElement, 'headerblanco');
  }

  addClassFondoBlanco() {
    this.renderer.removeClass(this.header.nativeElement, 'headerblanco');
    this.renderer.removeClass(this.headerbutton.nativeElement, 'headerblanco');
    this.renderer.addClass(this.header.nativeElement, 'fondoblanco');
    this.renderer.addClass(this.headerbutton.nativeElement, 'fondoblanco');
  }

  removeClassTodas() {
    this.renderer.removeClass(this.header.nativeElement, 'headerblanco');
    this.renderer.removeClass(this.headerbutton.nativeElement, 'headerblanco');
    this.renderer.removeClass(this.header.nativeElement, 'fondoblanco');
    this.renderer.removeClass(this.headerbutton.nativeElement, 'fondoblanco');
  }

  menuvisible = false;
  logoblanco = false;

  mostrarMenu() {
    this.menuvisible = !this.menuvisible;
    this.logoblanco = !this.logoblanco;
  }

  quitarMenu() {
    this.menuvisible = false;
    this.logoblanco = false;
  }
}
