import { Injectable } from '@angular/core';
import { EnvironmentService } from '@app/environment.service';
import { TranslateService } from "@ngx-translate/core";
import { CacheImplService } from '../cache/cache-impl.service';
import { STORAGE_KEY_LOCALE } from '@app/core/constants/Constants';

@Injectable({
  providedIn: 'root'
})
export class TranslateCustomService {

  constructor(
    private readonly translate: TranslateService,
    private readonly envs: EnvironmentService,
    private readonly cacheService: CacheImplService,
  ) {}

  public changeLang(lang: string) {
    this.cacheService.setItemNotEncrypted(STORAGE_KEY_LOCALE, lang)
    this.translate.use(lang);
  }

  public currentLang() {
    return this.cacheService.getItemNotEncrypted(STORAGE_KEY_LOCALE) ?? this.envs.defaultLanguage;
  }


}
