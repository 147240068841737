import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CompanyDataAdmin } from '@app/core/models/input/company/company.model';
import { CompanyService } from '@app/core/services/company/company.service';

@Component({
  selector: 'app-list-users',
  templateUrl: './list-users.component.html',
  styleUrls: ['./list-users.component.scss'],
})
export class ListUsersComponent implements OnInit {
  companyUuid?: string;
  companyData?: CompanyDataAdmin;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly companyService: CompanyService
  ) {
    this.activatedRoute.params.subscribe(param => {
      this.companyUuid = param['companyUuid'];
    });
  }

  showProfessionals = false;
  showAdmins = false;

  ngOnInit(): void {
    // Obtén los datos de la ruta actual
    this.activatedRoute.data.subscribe(data => {
      this.showProfessionals = data['showProfessionals'] || false;
      this.showAdmins = data['showAdmins'] || false;
    });

    if (!this.showProfessionals && !this.showAdmins) {
      this.showProfessionals = true;
      this.showAdmins = true;
    }
    this.getCompanyDataAdmin();
  }

  getCompanyDataAdmin() {
    if (this.companyUuid !== undefined) {
      this.companyService.getCompanyParameters(this.companyUuid).subscribe({
        next: data => {
          this.companyData = data;
        },
      });
    }
  }
}
