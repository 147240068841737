<div class="back-white-color box_title">
  <h4 class="subtitle tipo-second-color regular center">
    {{ 'SUPER-ADMIN.CLIENT.DATA-HIRING.TITLE-DATA-HIRING' | translate }}
  </h4>
</div>
<div class="box-container">
  <div class="box">
    <form [formGroup]="dataHiring">
      <div class="form">
        <div class="row">
          <div class="input">
            <label for="doctorsAmountId">
              {{
                'SUPER-ADMIN.CLIENT.DATA-HIRING.LABEL-PROFESSIONALS' | translate
              }}
            </label>
            <select
              formControlName="doctorsAmountId"
              id="doctorsAmountId"
              [ngClass]="{ wrong: shouldShowError('doctorsAmountId') }">
              <option
                value="{{ doctors.id }}"
                *ngFor="let doctors of doctorsAmounts">
                {{ doctors.name }}
              </option>
            </select>
          </div>
          <p
            class="message_error wrongtext small"
            *ngIf="this.dataHiring.get('doctorsAmountId')?.errors?.['required'] && this.isSubmit">
            {{
              'SUPER-ADMIN.CLIENT.DATA-HIRING.MESSAGE-ERROR-PROFESSIONALS'
                | translate
            }}
          </p>

          <div class="input">
            <label for="billingTypeId">
              {{ 'SUPER-ADMIN.CLIENT.DATA-HIRING.LABEL-PAYMENT' | translate }}
            </label>
            <select
              formControlName="billingTypeId"
              id="billingTypeId"
              [ngClass]="{ wrong: shouldShowError('billingTypeId') }">
              <option
                value="{{ billing.id }}"
                *ngFor="let billing of billingTypes">
                {{ billing.name }}
              </option>
            </select>
          </div>
          <p
            class="message_error wrongtext small"
            *ngIf="this.dataHiring.get('billingTypeId')?.errors?.['required'] && this.isSubmit">
            {{
              'SUPER-ADMIN.CLIENT.DATA-HIRING.MESSAGE-ERROR-PAYMENT' | translate
            }}
          </p>

          <div class="input">
            <label for="paymentMethodTypeId">
              {{ 'SUPER-ADMIN.CLIENT.DATA-HIRING.LABEL-MODE' | translate }}
            </label>
            <select
              formControlName="paymentMethodTypeId"
              id="paymentMethodTypeId"
              (change)="checkSepa()"
              [ngClass]="{ wrong: shouldShowError('paymentMethodTypeId') }">
              <option
                value="{{ method.id }}"
                *ngFor="let method of paymentMethodTypes">
                {{ method.name }}
              </option>
            </select>
          </div>
          <p
            class="message_error wrongtext small"
            *ngIf="this.dataHiring.get('paymentMethodTypeId')?.errors?.['required'] && this.isSubmit">
            {{
              'SUPER-ADMIN.CLIENT.DATA-HIRING.MESSAGE-ERROR-MODE' | translate
            }}
          </p>

          <div class="input">
            <label for="registryTypeId">
              {{ 'SUPER-ADMIN.CLIENT.DATA-HIRING.LABEL-TYPE' | translate }}
            </label>
            <select
              formControlName="registryTypeId"
              id="registryTypeId"
              (change)="checkHis()"
              [ngClass]="{ wrong: shouldShowError('registryTypeId') }">
              <option value="{{ type.id }}" *ngFor="let type of registryTypes">
                {{ type.name }}
              </option>
            </select>
          </div>
          <p
            class="message_error wrongtext small"
            *ngIf="this.dataHiring.get('registryTypeId')?.errors?.['required'] && this.isSubmit">
            {{
              'SUPER-ADMIN.CLIENT.DATA-HIRING.MESSAGE-ERROR-TYPE' | translate
            }}
          </p>
          <div class="input" *ngIf="isHis">
            <label for="his">{{'SUPER-ADMIN.CLIENT.DATA-HIRING.LABEL-HIS' | translate}}</label>
            <input type="text" id="nameHis" formControlName="his"
            [ngClass]="{ wrong: shouldShowError('his') }"/>
          </div>
          <p
          class="message_error wrongtext small"
          *ngIf="this.dataHiring.get('his')?.errors?.['required'] && this.isSubmit">
          {{
            'SUPER-ADMIN.CLIENT.DATA-HIRING.MESSAGE-ERROR-HIS' | translate
          }}
        </p>
        </div>

        <div class="row">
          <div class="input">
            <label for="price">
              {{ 'SUPER-ADMIN.CLIENT.DATA-HIRING.LABEL-AMOUNT' | translate }}
            </label>
            <input
              type="text"
              id="price"
              formControlName="price"
              [ngClass]="{ wrong: shouldShowError('price') }" />
          </div>
          <p
            class="message_error wrongtext small"
            *ngIf="(this.dataHiring.get('price')?.errors?.['required'] || this.dataHiring.get('price')?.errors?.['pattern']) && this.isSubmit">
            {{'SUPER-ADMIN.CLIENT.DATA-HIRING.MESSAGE-ERROR-AMOUNT' | translate}}
          </p>

          <div class="input">
            <label for="suscriptionsTypeId">
              {{
                'SUPER-ADMIN.CLIENT.DATA-HIRING.LABEL-SUSCRIPTION' | translate
              }}
            </label>
            <select
              formControlName="subscriptionsTypeId"
              id="subscriptionsTypeId"
              [ngClass]="{ wrong: shouldShowError('subscriptionsTypeId') }" multiple>
              <option
                value="{{ type.id }}"
                *ngFor="let type of subscriptionTypes">
                {{ type.name }}
              </option>
            </select>
          </div>
          <p class="small">{{'SUPER-ADMIN.CLIENT.DATA-HIRING.MESSAGE-ABVICE-SUSCRIPTION' | translate}}</p>
          <p
            class="message_error wrongtext small"
            *ngIf="this.dataHiring.get('subscriptionsTypeId')?.errors?.['required'] && this.isSubmit">
            {{
              'SUPER-ADMIN.CLIENT.DATA-HIRING.MESSAGE-ERROR-SUSCRIPTION'
                | translate
            }}
          </p>

          <div class="input" *ngIf="isSepa">
            <label for="bankAccount">
              {{ 'SUPER-ADMIN.CLIENT.DATA-HIRING.LABEL-SEPA' | translate }}
            </label>
            <input
              type="text"
              id="bankAccount"
              formControlName="bankAccount"
              [ngClass]="{ wrong: shouldShowError('bankAccount') }" />
          </div>
          <p
            class="message_error wrongtext small"
            *ngIf="this.dataHiring.get('bankAccount')?.errors?.['required'] && this.isSubmit">
            {{
              'SUPER-ADMIN.CLIENT.DATA-HIRING.MESSAGE-ERROR-SEPA' | translate
            }}
          </p>

          <div class="input" *ngIf="!isHis">
            <label for="signaturesAmountId">
              {{ 'SUPER-ADMIN.CLIENT.DATA-HIRING.LABEL-SIGNS' | translate }}
            </label>
            <select
              formControlName="signaturesAmountId"
              id="signaturesAmountId"
              [ngClass]="{ wrong: shouldShowError('signaturesAmountId') }">
              <option
                value="{{ signature.id }}"
                *ngFor="let signature of signaturesAmounts">
                {{ signature.name }}
              </option>
            </select>
          </div>
          <p
            class="message_error wrongtext small"
            *ngIf="this.dataHiring.get('signaturesAmountId')?.errors?.['required'] && this.isSubmit">
            {{
              'SUPER-ADMIN.CLIENT.DATA-HIRING.MESSAGE-ERROR-SIGNS' | translate
            }}
          </p>

          <div class="input" >
            <label class="speciality" *ngIf="isHis" >
              {{ 'PRIVATE.DOCTOR.DATA-DOCTOR.SPECIALTY' | translate }}
            </label>

            <div class="specialties_input" *ngIf="isHis">
              <div class="specialties">
                <span
                  class="selected"
                  *ngFor="let item of specialty; let i = index">
                  {{ item.name }}
                  <fa-icon
                    [icon]="faCircleXmark"
                    class="icono"
                    (click)="deleteItem(i, item)"></fa-icon>
                </span>
              </div>
              <ul class="specialty_select" (click)="editField()">
                <li
                  value="{{ specialty.id }}"
                  *ngFor="let specialty of specialties; let i = index"
                  (click)="addItem(i, specialty)">
                  {{ specialty.name }}
                </li>
              </ul>
              <p class="small messageSpecialty">
                {{
                  'SUPER-ADMIN.CLIENT.DATA-HIRING.MESSAGE-FIELD-SPECIALTY'
                    | translate
                }}
              </p>
            </div>
          </div>
        </div>
      </div>    
      <div class="buttons">
        <button
          class="button tipo-white-color back-blue-color"
          (click)="onSubmit()">
          {{ 'SUPER-ADMIN.CLIENT.DATA-HIRING.BUTTON-CONTINUE' | translate }}
          <fa-icon [icon]="faCircleArrowRight" class="icono iconono"></fa-icon>
        </button>
      </div>
    </form>
  </div>
</div>
