<div class="back-white-color grdp" *ngIf="openBanner">
    <p class="tipo-main-color regular">Este portal web únicamente utiliza cookies propias con finalidad técnica y estadística, no recaba ni cede datos de carácter personal de los usuarios sin su conocimiento.</p>
       <p class="tipo-main-color regular"> Puede obtener más información en nuestra <a [routerLink]="['politicaprivacidad']" target="_blank">política de Privacidad</a> y la <a [routerLink]="['cookies']" target="_blank"> política de Cookies.</a></p>
       <p class="tipo-main-color regular">Pulse el botón ACEPTAR, para confirmar que ha leído y aceptado la información presentada. Después de aceptar no volveremos a mostrar este mensaje.</p>
    <div class="botones inlineflex">
        <button class="button tipo-white-color back-blue-color" (click)="acceptCookies()">           
               Aceptar
        </button>
        <div class="titular-regular bt-rechazar">
            <span class="tipo-second-color" (click)="rejectCookies()">Rechazar</span>
        </div>
    </div>
  </div>
