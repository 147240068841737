import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { COMPANY_ROLES, DOCTOR_ROLES, FREEMIUM_COMPANY_ROLES, FREEMIUM_DOCTOR_ROLES, HEADER_ROLES } from '@app/core/constants/Permissions';
import SubscriptionTypeEnum from '@app/core/enums/subscription-type.enum';
import { SubscriptionTypes } from '@app/core/models/input/company/company-parameters.model';
import { Device } from '@app/core/models/input/device.model';
import { User } from '@app/core/models/input/user.model';
import { DeviceService } from '@app/core/services/device/device.service';
import { LoginService } from '@app/core/services/login/login.service';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss'],
})
export class ConfigurationComponent implements OnInit {

  //roles
  DOCTOR_ROLES = DOCTOR_ROLES;
  COMPANY_ROLES = COMPANY_ROLES;
  HEADER_ROLES = HEADER_ROLES;
  FREEMIUM_COMPANY_ROLES = FREEMIUM_COMPANY_ROLES;
  FREEMIUM_DOCTOR_ROLES = FREEMIUM_DOCTOR_ROLES;

  selectedTab?: string;
  user?: User;
  subscriptions?: SubscriptionTypes[] = [];
  isBiometric = false;

  @Input()  devices?: Device[];
  enrollmentCode?: string;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly loginService: LoginService,
    private readonly deviceService: DeviceService,)
    {
    this.activatedRoute.params.subscribe(params => {
      this.selectedTab = params['tab'];
    });
  }

  ngOnInit(): void {

    this.user = this.loginService.userValue ?? new User();

     // Comprobar el tipo de suscripción y si es solo papel, no mostrar botón Biométrica
     const subscriptionType: SubscriptionTypes[]  = this.user.subscription ?? [];
     this.isBiometric = !!subscriptionType.find(subscription => subscription.id === SubscriptionTypeEnum.BIOMETRIC);

    this.deviceService.getUserDevices().subscribe({
      next: devices => {
        this.deviceService.setDevices(devices)
        this.devices = devices;
      },
    });


    // If is not tab set move the user to the global
    if (!this.selectedTab) {
      this.changeTabToDefault("global");
    }
  }

  handleCompanyEnrollmentcode(code: string) {
    this.enrollmentCode = code;
  }

  changeTab(tab: string) {
    // Select the tab
    this.selectedTab = tab;
    // Update the router
    this.router.navigate(['../', tab], {
      relativeTo: this.activatedRoute,
    });
  }

  changeTabToDefault(tab: string) {
    this.selectedTab = tab;
    // Update the router
    this.router.navigate([tab], {
      relativeTo: this.activatedRoute,
    });
  }



}
