import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Filter } from '@app/core/models/front/filters';
import { Pagination } from '@app/core/models/front/pagination';
import { Sorters } from '@app/core/models/front/sorters';
import { Page } from '@app/core/models/input/page/pages.model';
import { Patient } from '@app/core/models/input/patient/patient.model';
import { EnvironmentService } from '@app/environment.service';
import { UtilsService } from '../utils/utils.service';
import { Observable } from 'rxjs';
import { UserOrPatientFullName } from '@app/core/models/input/patient/patient-search.model';

@Injectable({
  providedIn: 'root',
})
export class PatientService {
  constructor(
    private readonly http: HttpClient,
    private readonly env: EnvironmentService,
    private readonly uSrv: UtilsService
  ) {}

  getPatients(pagination: Pagination, filters: Filter[], sorters: Sorters) {
    return this.http.get<Page>(
      `${this.env.apiUrl}/patients?` +
        this.uSrv.genPaginationInUri(pagination)+
        this.uSrv.getFiltersInUri(filters) +
        this.uSrv.genSortersInUri(sorters)
    );
  }

  getPatientByUuid(uuid: string) {
    return this.http.get<Patient>(`${this.env.apiUrl}/patients/${uuid}`);
  }

  getPatientsByNameOrDocument(searchTerm: string): Observable<UserOrPatientFullName[]> {
    return this.http.get<UserOrPatientFullName[]>(`${this.env.apiUrl}/patients/search/${searchTerm}`);
  }

  getPatientsByNameAndRepresentativeDocument(name: string, representativeDocument: string): Observable<UserOrPatientFullName[]> {
    return this.http.get<UserOrPatientFullName[]>(`${this.env.apiUrl}/patients/search/${name}/${representativeDocument}`);
  }

  editPatient (dataPatient: Patient){
    const headers = new HttpHeaders({
      'Content-Type':  'application/json'
    })

    return this.http.put<Patient>(`${this.env.apiUrl}/patients/${dataPatient.uuid}`,  JSON.stringify(dataPatient), {headers: headers})
  }

  newPatient (dataPatient: Patient){
    const headers = new HttpHeaders({
      'Content-Type':  'application/json'
    })

    return this.http.post<Patient>(`${this.env.apiUrl}/patients`,  JSON.stringify(dataPatient), {headers: headers})
  }

  deletePatientByUuid (uuid: string){
    return this.http.delete(`${this.env.apiUrl}/patients/${uuid}`);
  }
}
