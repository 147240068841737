import { Component } from '@angular/core';
import { CacheImplService } from '../../../shared/services/cache/cache-impl.service';

@Component({
  selector: 'app-cookie-service',
  templateUrl: './cookie-service.component.html',
  styleUrls: ['./cookie-service.component.scss'],
})
export class CookieServiceComponent {
  private readonly cookieName = 'cookiesAccepted';
  public openBanner = true;

  constructor(private readonly cacheImplService: CacheImplService ) 
      {   
        // Comprueba si la cookie ya está presente al inicializar el componente
        if (this.cacheImplService.checkCookie(this.cookieName)) {
          this.openBanner = false;  
        }      
      }

  public acceptCookies() {
    this.cacheImplService.setCookie(this.cookieName, 'true', 365);
    this.openBanner = false;
  }

  public rejectCookies() {    
    this.cacheImplService.deleteCookie(this.cookieName);  
    this.openBanner = false;   
  }
}
