import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import ObjectTypeEnum from '@app/core/enums/objectType.enum';
import { DocumentInfoSignerDTO } from '@app/core/models/input/consent/consent-status-output.model';
import { ConsentService } from '@app/core/services/consent/consent.service';
import { DigitalDocumentsService } from '@app/core/services/digital-documents/digital-documents.service';
import { RgpdService } from '@app/core/services/rgpd/rgpd.service';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-window-error',
  templateUrl: './window-error.component.html',
  styleUrls: ['./window-error.component.scss']
})
export class WindowErrorComponent implements OnInit{
  faCircleXmark = faCircleXmark; 
  @Output() windowClose = new EventEmitter<void>();

  @Input() downloadUuid?: string;
  @Input() objectType?: string;

  errorType?: string;
  messageError?: string;
  dateError?: Date;
  signers?: DocumentInfoSignerDTO[];
 
  // Enum
  ObjectTypeEnum = ObjectTypeEnum;

  constructor(
    private readonly consentService: ConsentService,
    private readonly rgpdService: RgpdService,
    private readonly ddsService: DigitalDocumentsService
  ) {}

  closeWindow() {    
   this.windowClose.emit();    
  }

  ngOnInit(): void { 
    if(this.objectType === ObjectTypeEnum.CONSENT){     
      this.getConsentStatus();
    }
    if(this.objectType === ObjectTypeEnum.RGPD){      
      this.getRgpdStatus();
    }
    if(this.objectType === ObjectTypeEnum.DIGITAL_DOCUMENT){     
      this.getDdStatus();
    }
    
    }

  getConsentStatus() {
    if(this.downloadUuid != undefined){
      this.consentService.getConsentStatus(this.downloadUuid).subscribe({
        next: data => {
          this.errorType = data.status?.name;
          this.messageError = data.error;
          this.dateError = data.creationDate;          
          this.signers = data.signers;
        }
      })
    }    
  }

  getRgpdStatus() {
    if(this.downloadUuid != undefined){
      this.rgpdService.getRgpdStatus(this.downloadUuid).subscribe({
        next: dataRgpd => {
          this.errorType = dataRgpd.status?.name;
          this.messageError = dataRgpd.error;
          this.dateError = dataRgpd.creationDate;
          this.signers = dataRgpd.signers 
        }
      })
    }
  }

  getDdStatus() {
    if(this.downloadUuid != undefined){
      this.ddsService.getDigitalDocumentStatus(this.downloadUuid).subscribe({
        next: dataDd => {
          this.errorType = dataDd.status?.name;
          this.messageError = dataDd.error;
          this.dateError = dataDd.creationDate;
          this.signers = dataDd.signers 
        }
      })
    }
  }
}
