import { Component, OnInit } from '@angular/core';
import { SpecialityProcedurePublicOutputDTO } from '@app/core/models/front/public';
import { CatalogueSearchService } from '@app/core/services/share-search/catalogue-search.service';


@Component({
  selector: 'app-procedures-search',
  templateUrl: './procedures-search.component.html',
  styleUrls: ['./procedures-search.component.scss']
})
export class ProceduresSearchComponent implements OnInit{
  specialitiesStats?: SpecialityProcedurePublicOutputDTO[];
 
  constructor(
    private readonly shareSearch: CatalogueSearchService,
    
  ) {}
 
  ngOnInit() {
    this.shareSearch.currentProcedures.subscribe(proceduresBySpecialty => {
      if(proceduresBySpecialty) {
        this.specialitiesStats = proceduresBySpecialty;      
      }
    })
  }
 
}
