import { Component, OnInit } from '@angular/core';
import { Specialty } from '@app/core/models/input/specialty.model';
import { StatisticsService } from '@app/core/services/statistics/statistics.service';
import { faFileCirclePlus } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-list-specialties',
  templateUrl: './list-specialties.component.html',
  styleUrls: ['./list-specialties.component.scss'],
})
export class ListSpecialtiesComponent implements OnInit {
  specialties?: Specialty[];
  showModal = false;
  selectedSpecialtyId?: number;

  faFileCirclePlus = faFileCirclePlus;

  constructor(private readonly statisticsService: StatisticsService) {}

  ngOnInit(): void {
    this.getSpecialties();
  }

  getSpecialties() {
    this.statisticsService.getSpecialties().subscribe({
      next: respuesta => {
        this.specialties = respuesta;
      },
    });
  }

  onSpecialtyClick(specialtyId: number) {
    this.selectedSpecialtyId = specialtyId;
    this.showModal = true;
  }

  onCloseModal() {
    this.showModal = false;
  }
}
